import React, { useEffect, useState, useRef } from "react";
import "./css/editProfile123.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import photo from "../../assets/images/icons/profile.png";
import { FiUsers } from "react-icons/fi";
import { CgOrganisation } from "react-icons/cg";
import { MdOutlineVerifiedUser } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { loadUser } from '../../actions/userActions';
import { logEvent } from "../../utils/Analytics";
const EditProfile = () => {

  const dispatch = useDispatch();
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo(0, 0);
  }, []);

  const { user } = useSelector((state) => state.user);
  const [users, setUser] = useState({
    name: "",
    email: "",
    phone: "",
    city: "",
  });

  const [avatar, setAvatar] = useState("");
  const [avatarPrev, setAvatarPrev] = useState();
  const inputRef = useRef(null);

  const handleUserChange = (e) => {
    setUser({
      ...users,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    setUser(user && user);
    setAvatarPrev((user && user.avatar != undefined) || "" ? user.avatar.url : photo);
  }, [user]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    const Reader = new FileReader();
    Reader.readAsDataURL(file);

    Reader.onload = () => {
      if (Reader.readyState === 2) {
        setAvatarPrev(Reader.result);

        setAvatar(Reader.result);
      }
    };
  };

  const handleUpate = async (e) => {
    e.preventDefault();
    if (!users.name) {
      toast.error("Name is required!!");
    } else if (!users.email) {
      toast.error("Email is required!!");
    } else if (!users.phone) {
      toast.error("Phone is required!!");
    } else if (users?.phone.length < 7 || users?.phone.length > 16) {
      toast.error("Number must be between 7 & 16 digits");
    } 
    else if (!users.city) {
      toast.error("City is required!!");
    } else {
      try {
        const { data } = await axios.post("/api/user/updateuser", {
          name: users.name,
          email: users.email,
          phone: users.phone,
          city: users.city,
          avatar,
        });
        toast.success("User Updated Successfully!", {
          style: {
            padding: "18px",
          },
        });
        handleAddEvent("done")

        dispatch(loadUser());
      } catch (error) {
        toast.error(error.response.data, {
          style: {
            padding: "18px",
          },
        });
      }
    }
  };
  const handleAddEvent = (str) => {
    logEvent("Button", `Clicked ${str} button`);
};

  return (<>
    <div style={{ backgroundColor: "white", height: "100%", width: "100%", paddingTop: "50px", paddingBottom: "50px" }}>
      <div className="profile-section123">
        {/* dp here */}
        <div className="d-flex align-items-center justify-content-center">
          <a href="/myProfile" onClick={() => handleAddEvent("Employer profile")}>
            <div className="logo">
              <FiUsers />
            </div>
          </a>
          <div style={{ backgroundColor: "#00a5ec", width: "120px", height: "4px" }}></div>
          <a href="/myProfile/company" onClick={() => handleAddEvent("Company profile")}>
            <div className="logo">
              <CgOrganisation />
            </div>
          </a>
        </div>

        <div className="d-flex align-items-center justify-content-center gap-5 mt-3">
          <span>Personal Details</span>
          <span>Organization Details</span>
        </div>

        {/* Personal Details Section */}

        {<></>}
        <h2 className="my-5" style={{ fontSize: "30px" }}>
          Personal Details
        </h2>

        <form className="w-100 d-flex flex-column" onSubmit={handleUpate}>
          <div className="sub-section123">
            <div className="details w-100">
              <div className="title">Full Name</div>
              <input
                required
                style={{ backgroundColor: "white", width: "100%", border: "1px solid #ddd" }}
                type="text"
                value={users?.name}
                name="name"
                onChange={handleUserChange}
                onMouseOver="this.style.border='1px solid #00a5ec'"
                onMouseOut="this.style.border='1px solid #ddd'"
              />
            </div>

            <div className="details w-100">
              <div className="title">Email</div>
              <input
                style={{ width: "100%", border: "1px solid #ddd" }}
                type="email"
                placeholder="Email"
                value={users?.email}
                name="email"
                onChange={handleUserChange}
                readOnly
                onMouseOver="this.style.border='1px solid #00a5ec'"
                onMouseOut="this.style.border='1px solid #ddd'"
              />
            </div>

            <div className="details w-100">
              <div className="title">City</div>
              <input
                required
                style={{ backgroundColor: "white", width: "100%", border: "1px solid #ddd" }}
                type="text"
                placeholder="City"
                value={users?.city}
                name="city"
                onChange={handleUserChange}
                onMouseOver="this.style.border='1px solid #00a5ec'"
                onMouseOut="this.style.border='1px solid #ddd'"
              />
            </div>

            <div className="details w-100">
              <div className="title">Mobile Number</div>
              <div className="d-flex align-items-center justify-content-center gap-2">
                <input style={{ border: "1px solid #ddd" }} type="text" placeholder="" className="w-25" value="+91" readOnly />
                <input
                  required
                  minLength={10}
                  maxLength={10}
                  style={{ backgroundColor: "white", border: "1px solid #ddd" }}
                  type="number"
                  className="w-50"
                  value={users?.phone}
                  name="phone"
                  onChange={handleUserChange}
                  onMouseOver="this.style.border='1px solid #00a5ec'"
                  onMouseOut="this.style.border='1px solid #ddd'"
                />
                <span className="w-25 d-flex align-items-center gap-1" style={{ color: "#2fb212" }}>
                  <MdOutlineVerifiedUser /> Verified
                </span>
              </div>
            </div>
          </div>
          <button type="submit" className="submit-btn123">
            Done
          </button>
        </form>

        {/* Organisation Details Section .....*/}
      </div>
    </div>
    <ToastContainer
      position="top-center"
      autoClose={10000}
      />
 </>);
};

export default EditProfile;
