import React, { useState, useEffect } from "react";
import axios from "axios";
import "./AppliedJobReview.scss";
import vector1 from "../../assets/images/Vector.png";
import { Navigate, useLocation } from "react-router-dom";
import { RxDotFilled } from "react-icons/rx";
import { MONTH_DETAILS } from "../data";
import { HiOutlinePencil } from "react-icons/hi";
import MonthCalculator from "./MonthCalculator";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import SimpleDialogNew from "../EmployerNew/Employertemplates/ChatDialogNew";
import { logEvent } from "../../utils/Analytics";

const AppliedJobReview = () => {
    const role = localStorage.getItem("userType");
    const [state2, setState2] = useState(null); // Assuming state2 is your state variable
    const [openPopup, setOpenPopup] = useState(false);
    const { appliedJobId } = useParams();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data } = await axios.post("/api/user/reviewdetails", {
                    Id: appliedJobId,
                });
                setState2(data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, [appliedJobId]);

    // useEffect(() => {
    //   console.log(state2)
    //  }, [state2]);

    const openPreviewPageInNewTab = (state) => {
        const data = state;
        window.open(`/preview/${data.profile.replace(/[/?$%@&]/g, "-").replace(/ /g, "-")}-${data.opportunityType}-at-${data.organisationName.replace(/ /g, "-")}_${data._id}`.toLowerCase());
    };

    const calculateTotalExperience = (internships, jobs) => {
        let totalMonths = 0;

        // Function to calculate the difference in months between two dates
        function monthDiff(d1, d2) {
            let months;
            months = (d2.getFullYear() - d1.getFullYear()) * 12;
            months -= d1.getMonth();
            months += d2.getMonth();
            return months <= 0 ? 0 : months;
        }

        // Calculate total months for internships
        internships.forEach((internship) => {
            const startDate = new Date(internship.startDate);
            const endDate = new Date(internship.endDate);
            totalMonths += monthDiff(startDate, endDate);
        });

        // Calculate total months for jobs
        jobs.forEach((job) => {
            const startDate = new Date(job.startDate);
            const endDate = new Date(job.endDate);
            totalMonths += monthDiff(startDate, endDate);
        });

        // Calculate years and remaining months
        const years = Math.floor(totalMonths / 12);
        const months = totalMonths % 12;

        // Construct the result string
        let result = "(";
        if (years === 1) {
            result += years + " YEAR";
        } else if (years > 1) {
            result += years + " YEARS";
        }

        if (months === 1) {
            result += " " + months + " MONTH";
        } else if (months > 1) {
            result += " " + months + " MONTHS";
        }
        result += ")";

        return result;
    };

    const navigate = useNavigate();

    const calculateDaysDifference = (startDate) => {
        const start = new Date(startDate);
        const end = new Date();

        // Calculate the difference in milliseconds
        const differenceInMilliseconds = end - start;

        // Convert the difference to days
        const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);

        // Set the state with the calculated difference
        return differenceInDays;
    };

    const handleChatClick = async (userId, employerId, appliedJobId, mainId, navigate) => {
        try {
            const response = await axios.post(
                "/api/employer/sendMessagebyclickingchaticon",
                {
                    sender: employerId,
                    receiver: [[mainId, userId]],
                    appliedJobId: appliedJobId,
                    // message: '' // You can customize the message content
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        token: localStorage.getItem("token"),
                    },
                }
            );

            // console.log(response.data.data,"wff")
            if (response.status === 200) {
                const roomId = response.data.data;
                navigate("/messageEmployer", { state: roomId });
            }
        } catch (error) {
            console.error("Error creating chat room:", error);
            // Handle error (e.g., show a notification to the user)
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleAddEvent = (str) => {
        logEvent("Button", `Clicked ${str} button`);
    };

    return (
        <>
            <div className="reviewpage container-fluid px-5 py-5">
                {state2 ? (
                    <>
                        <div className="r1 container">
                            <div className="section1 mt-4 mb-3">
                                <h3 className="title123 pt-2">
                                    {role && role == "User" ? (
                                        <>
                                            {state2.job.profile}
                                            <span className="icon mx-4">
                                                <i style={{ cursor: "pointer" }} class="fa fa-external-link" aria-hidden="true" onClick={() => openPreviewPageInNewTab(state2.job)}></i>
                                            </span>
                                        </>
                                    ) : (
                                        <>{state2.user.name}</>
                                    )}

                                    <span className="float-right">
                                        <button className=" btntouch22" style={{ color: "#1177d6" }}>
                                            {state2 && state2.status === "Application_Sent" ? <>Applied</> : <>{state2.status === "NotIntrested" ? <>Not Intrested</> : <>{state2.status === "Assignment" ? <>Assignment Sent</> : <>{state2.status === "Interviews" ? <>Interview Scheduled</> : <>{state2.status}</>}</>}</>}</>}
                                        </button>
                                        {role && role !== "User" ? (
                                            <button
                                                className="shortcut-newappliedjob"
                                                onClick={() => {
                                                    setOpenPopup(!openPopup), handleAddEvent("Send message");
                                                }}
                                            >
                                                Send message
                                            </button>
                                        ) : null}
                                    </span>
                                </h3>
                                {role && role == "User" ? <h4 className="companyname22 mt-4 pt-3">{state2.job.organisationName}</h4> : <h4 className="companyname22 mt-4 pt-3">{state2.user.email}</h4>}
                                <button className=" btntouch223" style={{ color: "#1177d6" }} onClick={() => handleAddEvent("Status changed ")}>
                                    {state2 && state2.status === "Application_Sent" ? <>Applied</> : <>{state2.status === "NotIntrested" ? <>Not Intrested</> : <>{state2.status === "Assignment" ? <>Assignment Sent</> : <>{state2.status === "Interviews" ? <>Interview Scheduled</> : <>{state2.status}</>}</>}</>}</>}
                                </button>
                                {role && role !== "User" ? (
                                    <button
                                        className="shortcut-newappliedjob1"
                                        onClick={() => {
                                            setOpenPopup(!openPopup), handleAddEvent("Send message");
                                        }}
                                    >
                                        Send message
                                    </button>
                                ) : null}
                            </div>
                            <div className="section3 my-4 mt-5">
                                <div className="heading  heeding0001">
                                    <h3 className="title pt-2" style={{ fontSize: "25px", letterSpacing: "0.5px" }}>
                                        Application
                                    </h3>

                                    <h3 className="pcTittitle pt-2 ml-auto float-right" style={{ letterSpacing: "0.5px" }}>
                                        <span style={{ fontSize: "14px", marginTop: "-5px" }} className="aplied12 ">
                                            {" "}
                                            {"Applied "}
                                            <span>{calculateDaysDifference(state2.appliedDate).toFixed(0) == 0 ? "Today" : <>{calculateDaysDifference(state2.appliedDate).toFixed(0)} days ago</>}</span>{" "}
                                        </span>
                                    </h3>
                                </div>

                                <div className=" py-3 px-3 mx-4 ">
                                    <h3 className=" my-3">Cover letter</h3>
                                    <p className=" mt-4 mb-2" style={{ fontWeight: "500" }}>
                                        Why should you be hired for this role?
                                    </p>
                                    <p className=" mt-2 mb-3">{state2.coverLetter}</p>
                                    <h3 className=" mb-3 mt-5">Availability</h3>
                                    <p className=" my-3">
                                        {state2?.availability == true ? (
                                            <>
                                                {" "}
                                                Yes, I am available for {state2?.job?.duration} {state2?.job?.durationType} starting immediately for a {state2?.job?.isPartTimeAllowed === "yes" || state2?.job?.isPartTimeAllowed === "Yes" || state2?.job?.isPartTimeAllowed === "YES" ? <>part-time</> : <>full-time</>} {state2?.job?.opportunityType.toLowerCase()}
                                            </>
                                        ) : (
                                            <> No, {state2?.specifyAvailability}</>
                                        )}
                                    </p>

                                    {state2.assessment && state2.assessment.length > 0 ? (
                                        <>
                                            <h3 className=" mb-4 mt-5">Assessment</h3>

                                            {state2.assessment.map((data, index) => (
                                                <>
                                                    <p className=" mt-4 mb-2" style={{ fontWeight: "500" }}>
                                                        Q{index + 1}. {data.Question}
                                                    </p>
                                                    <p className=" mb-3 mt-2">{data.Answer}</p>
                                                </>
                                            ))}
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            </div>

                            {state2.resume.PersonalDetails ? (
                                <>
                                    <div className="section3 my-4 mt-5 " style={{ letterSpacing: "0.7px" }}>
                                        <div className="heading" style={{ display: "flex", flexWrap: "wrap" }}>
                                            <h3 className="title pt-2" style={{ fontSize: "25px", letterSpacing: "0.5px", display: "flex", justifyContent: "space-between" }}>
                                                Resume
                                            </h3>
                                            <h3 className="title pt-2 ml-auto float-right" style={{ fontSize: "25px", letterSpacing: "0.5px", cursor: "pointer", display: "flex", justifyContent: "space-between" }} onClick={() => navigate("/addResume")}>
                                                {role && role == "User" ? (
                                                    <>
                                                        <span style={{ fontSize: "15px" }} className="aplied212 float-right">
                                                            <HiOutlinePencil style={{ fontSize: "18px", marginTop: "-2px" }} /> Edit
                                                        </span>
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                            </h3>
                                        </div>
                                        <div className=" py-5 px-3">
                                            {/*<div class="container">
                <h1>{state2.resume.PersonalDetails.firstName} {state2.resume.PersonalDetails.lastName}</h1>
                <div style={{ color: 'gray' }}>
                  <p>{state2.resume.PersonalDetails.email}</p>
                  <p style={{ marginTop: '-10px' }}>+ 91 {state2.resume.PersonalDetails.phone}</p>
                  <p style={{ marginTop: '-10px' }}>{state2.resume.PersonalDetails.address}</p>
                </div>
          </div>
        <hr class="dropdown-divider my-5 " />*/}

                                            {state2.resume.Education.length != 0 ? (
                                                <>
                                                    <div class="container">
                                                        <div class="row">
                                                            <div class="col-3" style={{ minWidth: "150px" }}>
                                                                <p style={{ color: "#6D6D6D", fontSize: "13px", letterSpacing: "0.5px", fontWeight: "500" }}>EDUCATION</p>
                                                            </div>
                                                            <div class="col-9">
                                                                {state2.resume.Education.map((data) => {
                                                                    return (
                                                                        <>
                                                                            {" "}
                                                                            <div style={{ marginBottom: "30px", color: "#4A4E50" }}>
                                                                                <p style={{ fontSize: "15px", color: "#404040" }}>
                                                                                    <b>{data.degree}</b>
                                                                                </p>
                                                                                <div style={{ color: "#6E6E6E", fontSize: "15px", marginTop: "-8px" }}>
                                                                                    <p>{data.school}</p>
                                                                                    {data.specialization ? <p style={{ marginTop: "-8px" }}>({data.specialization})</p> : ""}
                                                                                    <p style={{ marginTop: "-8px" }}>
                                                                                        {data.startDate.slice(0, 4)} - {data.pursuing ? "Present" : data.endDate.slice(0, 4)}
                                                                                    </p>
                                                                                    <p style={{ marginTop: "-8px" }}>
                                                                                        {data.performanceScale}: {data.performance}
                                                                                        {data.performanceScale === "Percentage" && "%"}
                                                                                    </p>
                                                                                </div>
                                                                            </div>{" "}
                                                                        </>
                                                                    );
                                                                })}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr class="dropdown-divider mb-5 mt-3" />
                                                </>
                                            ) : (
                                                <></>
                                            )}

                                            {state2.resume.Job.length != 0 ? (
                                                <>
                                                    <div class="container">
                                                        <div class="row">
                                                            <div class="col-3" style={{ minWidth: "150px" }}>
                                                                <p style={{ color: "#6D6D6D", fontSize: "13px", letterSpacing: "0.5px", fontWeight: "500", lineHeight: "16px" }}>
                                                                    WORK
                                                                    <br /> EXPERIENCE
                                                                    <br />
                                                                    {calculateTotalExperience(state2.resume.Job, state2.resume.Internship)}
                                                                </p>
                                                            </div>
                                                            <div class="col-9">
                                                                {state2.resume.Job.map((data) => {
                                                                    return (
                                                                        <>
                                                                            {" "}
                                                                            <div style={{ marginBottom: "30px" }}>
                                                                                <p style={{ fontSize: "15px", color: "#404040" }}>
                                                                                    <b>{data.profile} </b>
                                                                                </p>
                                                                                <div style={{ color: "#6E6E6E", fontSize: "15px", marginTop: "-8px" }}>
                                                                                    <p style={{ marginTop: "-8px" }}>
                                                                                        {data.organization}, {data.location}
                                                                                    </p>
                                                                                    <p style={{ marginTop: "-8px" }}>
                                                                                        Job <RxDotFilled style={{ color: "#464545" }} />
                                                                                        {MONTH_DETAILS[parseInt(data.startDate.slice(5, 7), 10)] + " " + data.startDate.slice(0, 4)} - {MONTH_DETAILS[parseInt(data.endDate.slice(5, 7), 10)] + " " + data.endDate.slice(0, 4)} {<MonthCalculator startDate={data.startDate} endDate={data.endDate} />}
                                                                                    </p>
                                                                                    <p style={{ marginTop: "-8px" }}>{data.description}</p>
                                                                                </div>
                                                                            </div>{" "}
                                                                        </>
                                                                    );
                                                                })}
                                                                {state2.resume.Internship.map((data) => {
                                                                    return (
                                                                        <>
                                                                            <div style={{ marginBottom: "30px", color: "#2C2C2C" }}>
                                                                                <p style={{ fontSize: "15px", color: "#404040" }}>
                                                                                    <b>{data.profile} </b>
                                                                                </p>
                                                                                <div style={{ color: "#6E6E6E", fontSize: "15px", marginTop: "-8px" }}>
                                                                                    <p style={{ marginTop: "-8px" }}>
                                                                                        {data.organization}, {data.location}
                                                                                    </p>
                                                                                    <p style={{ marginTop: "-8px" }}>
                                                                                        Internship <RxDotFilled style={{ color: "#464545" }} /> {MONTH_DETAILS[parseInt(data.startDate.slice(5, 7), 10)] + " " + data.startDate.slice(0, 4)} - {MONTH_DETAILS[parseInt(data.endDate.slice(5, 7), 10)] + " " + data.endDate.slice(0, 4)} {<MonthCalculator startDate={data.startDate} endDate={data.endDate} />}
                                                                                    </p>
                                                                                    <p style={{ marginTop: "-8px" }}>{data.description}</p>
                                                                                </div>
                                                                            </div>{" "}
                                                                        </>
                                                                    );
                                                                })}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr class="dropdown-divider mb-5 mt-3" />
                                                </>
                                            ) : (
                                                <></>
                                            )}

                                            {state2.resume.Training.length != 0 ? (
                                                <>
                                                    <div class="container">
                                                        <div class="row">
                                                            <div class="col-3" style={{ minWidth: "150px" }}>
                                                                <p style={{ color: "#6D6D6D", fontSize: "13px", letterSpacing: "0.5px", fontWeight: "500", lineHeight: "16px" }}>
                                                                    TRAININGS/
                                                                    <br />
                                                                    COURSES
                                                                </p>
                                                            </div>
                                                            <div class="col-9">
                                                                {state2.resume.Training.map((data) => {
                                                                    return (
                                                                        <>
                                                                            {" "}
                                                                            <div style={{ marginBottom: "30px" }}>
                                                                                <p style={{ fontSize: "15px", color: "#404040" }}>
                                                                                    <b>{data.program}</b>
                                                                                </p>
                                                                                <div style={{ color: "#6E6E6E", fontSize: "15px", marginTop: "-8px" }}>
                                                                                    <p style={{ marginTop: "-8px" }}>
                                                                                        {data.organization}, {data.location}
                                                                                    </p>
                                                                                    <p style={{ marginTop: "-8px" }}>
                                                                                        {MONTH_DETAILS[parseInt(data.startDate.slice(5, 7), 10)] + " " + data.startDate.slice(0, 4)} - {MONTH_DETAILS[parseInt(data.endDate.slice(5, 7), 10)] + " " + data.endDate.slice(0, 4)}
                                                                                    </p>
                                                                                    <p style={{ marginTop: "-8px" }}>{data.description}</p>
                                                                                </div>
                                                                            </div>{" "}
                                                                        </>
                                                                    );
                                                                })}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr class="dropdown-divider mb-5 mt-3" />
                                                </>
                                            ) : (
                                                <></>
                                            )}

                                            {state2.resume.Academics.length != 0 ? (
                                                <>
                                                    <div class="container">
                                                        <div class="row">
                                                            <div class="col-3" style={{ minWidth: "150px" }}>
                                                                <p style={{ color: "#6D6D6D", fontSize: "13px", letterSpacing: "0.5px", fontWeight: "500" }}>
                                                                    ACADEMICS/
                                                                    <br /> PERSONAL
                                                                    <br /> PROJECTS
                                                                </p>
                                                            </div>
                                                            <div class="col-9">
                                                                {state2.resume.Academics.map((data) => {
                                                                    return (
                                                                        <>
                                                                            {" "}
                                                                            <div style={{ marginBottom: "30px", color: "#2C2C2C" }}>
                                                                                <p style={{ fontSize: "15px", color: "#404040" }}>
                                                                                    <b>{data.title}</b>
                                                                                </p>
                                                                                <div style={{ color: "#6E6E6E", fontSize: "15px", marginTop: "-8px" }}>
                                                                                    <p style={{ marginTop: "-8px" }}>
                                                                                        {MONTH_DETAILS[parseInt(data.startDate.slice(5, 7), 10)] + " " + data.startDate.slice(0, 4)} - {MONTH_DETAILS[parseInt(data.endDate.slice(5, 7), 10)] + " " + data.endDate.slice(0, 4)}
                                                                                    </p>
                                                                                    <a
                                                                                        target="_gray"
                                                                                        href={data.link}
                                                                                        style={{
                                                                                            color: "#0885B7",
                                                                                            display: "inline-block",
                                                                                            maxWidth: "100%",
                                                                                            overflow: "hidden",
                                                                                            textOverflow: "ellipsis",
                                                                                            textDecoration: "none",
                                                                                            whiteSpace: "nowrap",
                                                                                        }}
                                                                                    >
                                                                                        {data.link}
                                                                                    </a>

                                                                                    <p style={{ marginTop: "10px" }}>{data.description}</p>
                                                                                </div>
                                                                            </div>{" "}
                                                                        </>
                                                                    );
                                                                })}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr class="dropdown-divider mb-5 mt-3" />
                                                </>
                                            ) : (
                                                <></>
                                            )}

                                            {state2.resume.Skills.length != 0 ? (
                                                <>
                                                    <div class="container">
                                                        <div class="row">
                                                            <div class="col-3" style={{ minWidth: "200px" }}>
                                                                <p style={{ color: "#6D6D6D", fontSize: "13px", letterSpacing: "0.5px", fontWeight: "500" }}>SKILLS</p>
                                                            </div>
                                                            <div class="col-6">
                                                                <div className="row">
                                                                    {state2.resume.Skills.map((data) => {
                                                                        return (
                                                                            <>
                                                                                <div className="col-6" style={{ minWidth: "200px" }}>
                                                                                    <div style={{ marginBottom: "20px", fontSize: "17px" }}>
                                                                                        <p style={{ fontSize: "15px", color: "#404040" }}>
                                                                                            <b>{data.name}</b>
                                                                                        </p>
                                                                                        <div style={{ color: "black", marginTop: "-8px" }}>
                                                                                            <div style={{ color: "#6E6E6E", fontSize: "15px", marginTop: "-8px" }}>
                                                                                                <p style={{ marginTop: "10px" }}>{data.level ? data.level : "Beginner"}</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        );
                                                                    })}
                                                                </div>
                                                            </div>
                                                            <div class="col-3"></div>
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (
                                                <></>
                                            )}
                                            <hr class="dropdown-divider mb-5 mt-3" />

                                            {state2.resume.PersonalDetails ? (
                                                <>
                                                    <div class="container">
                                                        <div class="row">
                                                            <div class="col-3" style={{ minWidth: "150px" }}>
                                                                <p style={{ color: "#6D6D6D", fontSize: "13px", letterSpacing: "0.5px", fontWeight: "500" }}>CONTACT DETAILS</p>
                                                            </div>
                                                            {/* <div class="col-9"> */}
                                                            <div class="col-md-9 col-12 d-flex justify-content-between align-items-start">
                                                                <div>
                                                                    <div style={{ color: "#6E6E6E", fontSize: "15px", marginTop: "-8px" }}>
                                                                        <p style={{ marginTop: "10px" }}>
                                                                            <span style={{ color: "#2C2C2C", fontWeight: "bold", color: "#404040" }}>Email: </span>
                                                                            {state2.resume.PersonalDetails.email}
                                                                        </p>
                                                                        <p style={{ marginTop: "10px" }}>
                                                                            <span style={{ color: "#2C2C2C", fontWeight: "bold", color: "#404040" }}>Phone: </span>
                                                                            +91 {state2.resume.PersonalDetails.phone}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    {role && role !== "User" ? (
                                                                        <button
                                                                            className="shortcut-newappliedjob3"
                                                                            onClick={() => {
                                                                                setOpenPopup(!openPopup), handleAddEvent("Send message");
                                                                            }}
                                                                        >
                                                                            Send message
                                                                        </button>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                            {role && role !== "User" ? (
                                                                <button
                                                                    className="shortcut-newappliedjob4"
                                                                    onClick={() => {
                                                                        setOpenPopup(!openPopup), handleAddEvent("Send message");
                                                                    }}
                                                                >
                                                                    Send message
                                                                </button>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (
                                                <></>
                                            )}
                                        </div>
                                    </div>
                                    {/* test <button className="shortcut-newappliedjob4" onClick={() => handleChatClick(state2?.user?._id, state2?.employer, state2?.job?._id, state2?._id , navigate)}> */}
                                </>
                            ) : (
                                <></>
                            )}
                        </div>
                    </>
                ) : (
                    <>
                        <div style={{ height: "100vh" }}></div>
                    </>
                )}
            </div>

            <SimpleDialogNew
                candidates={state2?.user?._id} //userid
                appliedJobId={state2?.job?._id} //jobid
                employerId={state2?.employer}
                mainId={state2?._id}
                open={openPopup}
                onClose={() => setOpenPopup(false)}
            />
        </>
    );
};

export default AppliedJobReview;
