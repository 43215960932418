import React, { useEffect, useState } from "react";
import "./css/Login.scss";
import GoogleIcon from "../../assets/images/icons/google.png";
import Spinner from "../Layout/Spinner";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loginUser, loginEmployer } from "../../actions/userActions";
import Header2 from "../Layout/Header2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
// import OAuth2Login from "react-simple-oauth2-login";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import { useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { logEvent } from "../../utils/Analytics";

function Login() {
    const { state } = useLocation();

    useEffect(() => {
        if (state && state.role == "Employer") {
            setLoginType("Employer");
        }
    }, []);

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo(0, 0);
    }, []);

    const [loginType, setLoginType] = useState("User");
    const [users, setUser] = useState({
        email: "",
        password: "",
    });
    const [employers, setEmployers] = useState({
        email: "",
        password: "",
    });

    const navigate = useNavigate();
    const { isAuthenticated, loading, user } = useSelector((state) => state.user);
    const dispatch = useDispatch();

    const handleUserChange = (e) => {
        setUser({
            ...users,
            [e.target.name]: e.target.value,
        });
    };

    // const login = async (event) => {
    //   event.preventDefault();
    //   dispatch(loginUser(users, loginType));
    //   localStorage.setItem('userType', loginType);

    // };
    const location = useLocation();
    const from = location.state?.from || "/";
    const login = async (event) => {
        event.preventDefault();
        handleAddEvent("login");
        if (loginType === "User") {
            // Assuming loginUser is an action creator
            await dispatch(loginUser(users, loginType));
            localStorage.setItem("userType", loginType);
            setUser({ ...users, password: "" }); // Corrected from setUser to setUsers
        } else if (loginType === "Employer") {
            // Assuming loginEmployer is an action creator
            await dispatch(loginEmployer(users, loginType));
            localStorage.setItem("userType", loginType);
            setUser({ ...users, password: "" }); // Corrected from setUser to setUsers
        }
    };
    useEffect(() => {
        if (user && user._id) {
            localStorage.setItem("id", user._id);
        }
    }, [user]);

    useEffect(() => {
        if (localStorage.getItem("token")) {
            if (user?.role === "User") {
                // navigate('/');
                navigate(from, { replace: true });
            } else if (user?.role === "Employer") {
                // navigate('/employerApplication');
                navigate("/employerApplication/Internship");
            }
        }
    }, [isAuthenticated, user, navigate, from]);

    const handleSuccess = (token) => {
        console.log("Login successful:", token);
    };

    const handleFailure = (error) => {
        console.error("Login failed:", error);
        navigate("/dashboard");
    };

    // const handleGoogleLoginSuccess = async (credentialResponse) => {
    //   try {
    //     const decoded = jwtDecode(credentialResponse.credential);

    //     console.log("Decoded token:", decoded);

    //     const response = await axios.post("/api/user/googleLogin", {
    //       tokenId: credentialResponse.credential,
    //     });

    //     console.log("Server response:", response.data);

    //     const { token, user, role } = response.data;

    //     if (token) {
    //       if (role === 'User') {
    //         dispatch(loginUser({...user }, 'User'));
    //         localStorage.setItem('userType', 'User');
    //       } else if (role === 'Employer') {
    //         dispatch(loginEmployer({...user}, 'Employer'));
    //         localStorage.setItem('userType', 'Employer');
    //       }
    //       navigate("/");
    //     } else {
    //       console.log("Login failed:", response.data.message);
    //     }
    //   } catch (error) {
    //     console.error("Error during Google login:", error);
    //   }
    // };

    // const handleGoogleLoginSuccess = async (credentialResponse) => {
    //   try {
    //     const decoded = jwtDecode(credentialResponse.credential);

    //     console.log("Decoded token:", decoded);

    //     const response = await axios.post("/api/user/googleLogin", {
    //       tokenId: credentialResponse.credential,
    //     });

    //     console.log("Server response:", response.data);

    //     const { token, user } = response.data;

    //     if (token) {
    //       localStorage.setItem('token', token);

    //       if (user.role === 'User') {
    //         dispatch(loginUser(user, 'User'));
    //         localStorage.setItem('userType', 'User');
    //       } else if (user.role === 'Employer') {
    //         dispatch(loginEmployer(user, 'Employer'));
    //         localStorage.setItem('userType', 'Employer');
    //       }
    //       navigate("/");
    //     } else {
    //       console.log("Login failed:", response.data.message);
    //     }
    //   } catch (error) {
    //     console.error("Error during Google login:", error);
    //   }
    // };

    const handleGoogleLoginSuccess = async (credentialResponse) => {
        try {
            const decoded = jwtDecode(credentialResponse.credential);

            console.log("Decoded token:", decoded);

            const response = await axios.post("/api/user/googleLogin", {
                tokenId: credentialResponse.credential,
            });

            console.log("Server response:", response.data);

            const { token, user } = response.data;

            if (token) {
                localStorage.setItem("token", token);

                if (user.role === "User") {
                    try {
                        const data = await fetch("/api/user/me", {
                            method: "GET",
                            headers: {
                                "Content-Type": "application/json",
                                token: localStorage.getItem("token"),
                            },
                        });
                        const json = await data.json();
                        const logData = json.user;
                        dispatch({ type: "LOAD_USER_SUCCESS", payload: logData });
                        console.log("Loaded user data:", logData);
                    } catch (error) {
                        console.error("Error loading user data:", error);
                    }

                    localStorage.setItem("userType", "User");
                }
                // else if (user.role === 'Employer') {
                //   localStorage.setItem('userType', 'Employer');
                // }
                navigate("/");
            } else {
                console.log("Login failed:", response.data.message);
            }
        } catch (error) {
            toast.error(error.response ? error.response.data.message : error.message);

            console.error("Error during Google login:", error);
        }
    };

    const handleGoogleLoginSuccessEmployer = async (credentialResponse) => {
        try {
            const decoded = jwtDecode(credentialResponse.credential);

            console.log("Decoded token:", decoded);

            const response = await axios.post("/api/employer/googleLoginEmployer", {
                tokenId: credentialResponse.credential,
            });

            console.log("Server response:", response.data);

            const { token, user } = response.data;

            if (token) {
                localStorage.setItem("token", token);

                if (user.role === "Employer") {
                    try {
                        const data = await fetch("/api/user/me", {
                            method: "GET",
                            headers: {
                                "Content-Type": "application/json",
                                token: localStorage.getItem("token"),
                            },
                        });
                        const json = await data.json();
                        const logData = json.user;
                        dispatch({ type: "LOAD_USER_SUCCESS", payload: logData });
                        console.log("Loaded user data:", logData);
                    } catch (error) {
                        console.error("Error loading user data:", error);
                    }

                    localStorage.setItem("userType", "Employer");
                }
                navigate("/");
            } else {
                console.log("Login failed:", response.data.message);
            }
        } catch (error) {
            toast.error(error.response ? error.response.data.message : error.message);

            console.error("Error during Google login:", error);
        }
    };

    const handleGoogleLoginFailure = (error) => {
        console.error("Google login failed:", error);
    };

    const handleAddEvent = (str) => {
        logEvent("Button", `Clicked ${str} button`);
    };
    return (
        <>
            <div className="login-component">
                <form className="form-box">
                    <div className="form">
                        <div className="login-type">
                            <strong
                                className={`${loginType === "User" ? "active" : ""}`}
                                onClick={() => {
                                    setLoginType("User");handleAddEvent("Job Seeker");
                                }}
                            >
                                Job Seeker
                            </strong>
                            <strong
                                className={`${loginType === "Employer" ? "active" : ""}`}
                                onClick={() => {
                                    setLoginType("Employer"); handleAddEvent("Employer");
                                }}
                            >
                                Employer
                            </strong>
                        </div>
                        {loginType === "User" && (
                            <>
                                {/*  <div class="input-group mb-4 mt-4">
                  <input
                    type="text"
                    class="form-control py-3"
                    placeholder="Name"
                    value={user.name}
                    onChange={handleUserChange}
                    id="name"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text" >
                      <i className='fa fa-user'></i>
                    </span>
                  </div>
                </div> */}

                                <TextField
                                    InputLabelProps={{ shrink: true }}
                                    type="email"
                                    label="Email"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={users.email}
                                    onChange={handleUserChange}
                                    name="email"
                                    id="email"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <i className="fa fa-envelope mx-3" style={{ fontSize: "20px", marginTop: "0px" }}></i>
                                            </InputAdornment>
                                        ),
                                        style: { outline: "none" },
                                    }}
                                />

                                <TextField
                                    InputLabelProps={{ shrink: true }}
                                    type="password"
                                    label="Password"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={users.password}
                                    onChange={handleUserChange}
                                    name="password"
                                    id="password"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <i
                                                    className="fa fa-lock  "
                                                    style={{
                                                        fontSize: "28px",
                                                        marginTop: "0px",
                                                        marginLeft: "12px",
                                                        marginRight: "12px",
                                                    }}
                                                ></i>
                                            </InputAdornment>
                                        ),
                                    }}
                                />

                                <div style={{ float: "right" }}>
                                    <a href="/recoveryemail" onClick={() => handleAddEvent("Forgot password")}>
                                        <small style={{ fontSize: "13px", color: "gray" }}>Forgot Password?</small>
                                    </a>
                                </div>
                                <button
                                    onClick={login}
                                    className="btn"
                                    style={{ paddingTop: "16px", paddingBottom: "16px" }}
                                    //style={{ width: "100%", marginTop: "3rem", background: "#4B92C8", color: "white" }}
                                >
                                    {loading ? <Spinner /> : <span style={{ fontWeight: "800" }}>Log In</span>}
                                </button>
                                <p className="text-center pt-4" style={{ fontSize: "13px", marginTop: "12px" }}>
                                    Don't have an Account ?{" "}
                                    {/* <a href="/studentSignup" style={{ fontSize: '13px' ,fontWeight:'600'}}>
                    Sign up
                  </a> */}
                                    <Link to="/studentSignup" onClick={() => handleAddEvent("Sign up ")} state={{ from: location.state?.from || location.pathname }} style={{ fontSize: "13px", fontWeight: "600" }}>
                                        Sign up
                                    </Link>
                                </p>
                                {/* <div className="row my-2">
                    <div className="col-5 my-4">
                      <hr style={{height: "0.1px"}}></hr>
                    </div>
                    <div className="col-2 my-4 text-center">
                      <p style={{ fontWeight: "500", color: "#7A7474" }}>or</p>
                    </div>
                    <div className="col-5 my-4">
                      <hr style={{height: "0.1px"}}></hr>
                    </div>
                  </div> */}
                                {/* <button
                  style={{
                    border: "1px solid #000000",
                    background: "white",
                    width: "100%",
                    padding: "5px",
                    fontSize: "1.3rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "1rem",
                  }}
                  type="submit"
                >
                  <img src={GoogleIcon} alt="Signing with Google" />
                  Continue with Google
                </button> */}

                                {/* <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                > */}
                                {/* <GoogleOAuthProvider clientId="293591098142-rpt1jovrri326db8s45ug0rcap869g82.apps.googleusercontent.com">
                    <GoogleLogin
                      onSuccess={credentialResponse => {
                        var decoded = jwtDecode(credentialResponse.credential);

                        console.log(decoded);
                        navigate("/");

                      }}
                      onError={() => {
                        console.log('Login Failed');
                      }}
                    />
                  </GoogleOAuthProvider> */}

                                {/* <GoogleOAuthProvider clientId="100664819392-m58df630lnjhu1u5lnl8sgn72n330u0u.apps.googleusercontent.com">
                    <GoogleLogin
                      onSuccess={handleGoogleLoginSuccess}
                      onError={handleGoogleLoginFailure}
                    />
                  </GoogleOAuthProvider>
                </div> */}
                            </>
                        )}

                        {loginType === "Employer" && (
                            <>
                                {/* <div className="input-box">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    name="email"
                    value={user.email}
                    onChange={handleUserChange}
                    id="email"
                  />
                </div>
                <div className="input-box">
                  <label htmlFor="password">Password</label>
                  <Link to="/forgot" className="forgot">
                    Forgot Password?
                  </Link>
                  <input
                    type="password"
                    name="password"
                    value={user.password}
                    onChange={handleUserChange}
                    id="password"
                  />
                </div> */}
                                <TextField
                                    InputLabelProps={{ shrink: true }}
                                    type="email"
                                    label="Email"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={users.email}
                                    onChange={handleUserChange}
                                    name="email"
                                    id="email"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <i className="fa fa-envelope mx-3" style={{ fontSize: "20px", marginTop: "0px" }}></i>
                                            </InputAdornment>
                                        ),
                                        style: { outline: "none" },
                                    }}
                                />

                                <TextField
                                    InputLabelProps={{ shrink: true }}
                                    type="password"
                                    label="Password"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={users.password}
                                    onChange={handleUserChange}
                                    name="password"
                                    id="password"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <i
                                                    className="fa fa-lock  "
                                                    style={{
                                                        fontSize: "28px",
                                                        marginTop: "0px",
                                                        marginLeft: "12px",
                                                        marginRight: "12px",
                                                    }}
                                                ></i>
                                            </InputAdornment>
                                        ),
                                    }}
                                />

                                <div style={{ float: "right" }}>
                                    <a href="/recoveryemail">
                                        <small style={{ fontSize: "13px", color: "gray" }}>Forgot Password?</small>
                                    </a>
                                </div>
                                <button
                                    onClick={login}
                                    className="btn"
                                    style={{ paddingTop: "16px", paddingBottom: "16px" }}
                                    // style={{ width: "100%", marginTop: "3rem", background: "#4B92C8", color: "white" }}
                                >
                                    {loading ? <Spinner /> : <span style={{ fontWeight: "800" }}>Log In</span>}
                                </button>
                                <p className="text-center pt-4" style={{ fontSize: "13px", marginTop: "12px" }}>
                                    Don't have an Account ?{" "}
                                    <a href="/employeeSignup" style={{ fontSize: "13px", fontWeight: "600" }}>
                                        Sign up
                                    </a>
                                    {/* <div className="row my-2">
                    <div className="col-5 my-4">
                      <hr style={{height: "0.1px"}}></hr>
                    </div>
                    <div className="col-2 my-4 text-center">
                      <p style={{ fontWeight: "500", color: "#7A7474" }}>or</p>
                    </div>
                    <div className="col-5 my-4">
                      <hr style={{height: "0.1px"}}></hr>
                    </div>
                  </div> */}
                                    {/* <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <GoogleOAuthProvider clientId="100664819392-m58df630lnjhu1u5lnl8sgn72n330u0u.apps.googleusercontent.com">
                      <GoogleLogin
                        onSuccess={handleGoogleLoginSuccessEmployer}
                        onError={handleGoogleLoginFailure}
                      />
                    </GoogleOAuthProvider>
                  </div> */}
                                </p>
                            </>
                        )}
                    </div>
                </form>
            </div>
            <ToastContainer position="top-center" autoClose={10000} />
        </>
    );
}

export default Login;
