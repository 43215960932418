import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DeleteMessageModal({open,setOpen,setDeleteConfirm}) {
    
  const handleClose = (e) => {
    if(e === "Yes"){
        setDeleteConfirm(true);
    }
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle style={{fontSize:"18px"}}>{"Delete Message"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description" style={{fontSize:"16px"}}>
            Are you sure you want to delete this message?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button style={{fontSize:"16px"}} onClick={() => handleClose("No")}>Cancel</Button>
          <Button style={{fontSize:"16px"}} onClick={() => handleClose("Yes")}>Continue </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
