import React, { useEffect, useState, useRef } from "react";
import Avichal from "../../assets/images/avichal.png";
import "./css/Profile.scss";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loadUser } from '../../actions/userActions';
import axios from "axios";
import { useLocation } from "react-router-dom";
import Header2 from "../Layout/Header2";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Spinner from "../Layout/Spinner";
import photo from "../../assets/images/icons/profile.png";
import "./css/style.css";
import { Avatar } from "@mui/material";
import { logEvent } from "../../utils/Analytics";
function Profile() {

  const dispatch = useDispatch();

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo(0, 0)
  },[]);

  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [users, setUser] = useState({
    name: "",
    email: "",
    phone: "",
    city: "",
    
  });

  const [avatar, setAvatar] = useState("");
  const [avatarPrev, setAvatarPrev] = useState();
  const inputRef = useRef(null);

  const handleClick = () => {
    // 👇️ open file input box on click of another element
    inputRef.current.click();
  };

  const handleUserChange = (e) => {
    setUser({
      ...users,
      [e.target.name]: e.target.value,
    });
  };


  useEffect(() => {
  
    setUser(user && user);
    setAvatarPrev(
      (user && user.avatar != undefined) || "" ? user.avatar.url : photo
    );
  }, [user]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    const Reader = new FileReader();
    Reader.readAsDataURL(file);

    Reader.onload = () => {
      if (Reader.readyState === 2) {
        setAvatarPrev(Reader.result);

        setAvatar(Reader.result);
      }
    };
  };

  const handleUpate = async () => {
    setLoading(true);
    if (!users.name) {
      setLoading(false);
      toast.error("Name is required!!");
    } else if (!users.email) {
      setLoading(false);
      toast.error("Email is required!!");
    } else if (!users.phone) {
      setLoading(false);
      toast.error("Phone is required!!");
    } else if (!users.city) {
      setLoading(false);
      toast.error("City is required!!");
    }
    else if (users.availability == "Select availability") {
      setLoading(false);
      toast.error("Select availability!!");
    }
    else {
      try {
        const { data } = await axios.post("/api/user/updateuser", {
          name: users.name,
          email: users.email,
          phone: users.phone,
          city: users.city,
          availability: users.availability,
          avatar,
        });
        setLoading(false);
        toast.success("User Updated Successfully!", {
          style: {
            padding: "18px",
          },
        });
        handleAddEvent("Update profile")
        dispatch(loadUser());
      } catch (error) {
        setLoading(false);
        toast.error(error.response.data, {
          style: {
            padding: "18px",
          },
        });
      }
    }
  };

  const handleAddEvent = (str) => {
    logEvent("Button", `Clicked ${str} button`);
};

  return (
    <>
      <div className="student-profile-box ">
        <div className="container profile mt-5">
          <div className="image-box ">
            {/* <img
              src={photo}
              alt="upload image"
              className="img-display-before"
              style={{ width: "80px", height: "80px", marginLeft: "4px" }}
            /> */}

          {/*  <div className="mx-auto mt-4" style={{ width: '71px'}}>
            <Avatar
              src={avatarPrev}
              alt="User Avatar"
              //sx={{ height: "10vmax", width: "10vmax" }}
              style={{width: "70px", height: "70px", borderRadius: "50%"}}
           
       
           

            <div
            onClick={handleClick}
              style={{
             
              marginTop: "-15px",
              marginLeft: "40px",
                backgroundColor: "white",
                borderRadius: "20px",
                position: "absolute",
              }}
            >
              <div
                className="btn btn-lights"
                style={{ cursor: "pointer", height: "24px", width: "24px" }}
              
              >
                <i
                  class="fa fa-pencil-square-o "
                  aria-hidden="true"
                  style={{
                    color: "#0462CF",
                    fontSize: "15px",
                    marginTop: "-1px",
                    marginLeft: "-3px",
                  }}
                ></i>
              </div>
            </div> 

            </div>
               
            <br></br>
            <input type="file" accept="image/*" onChange={handleImageChange} ref={inputRef} style={{display: 'none'}}/>
            />*/}
            <p className="font-weight-500 text-center mb-3" style={{fontSize: "18px"}}>
              Edit Profile
            </p>
          </div>

          <div className="info-box ">
            <form>
              <div class="form-group">
                <label for="exampleInputEmail1">Name</label>
                <input
                  type="text"
                  class="form-control mt-1"
                  style={{ fontSize: "15px", paddingTop: "18px", paddingBottom: "18px" }}
                  placeholder="John Deo"
                  value={users && users.name}
                  onChange={handleUserChange}
                  id="name"
                  name="name"
                />
              </div>
              {/*
              <div class="form-group ">
                <label for="exampleInputEmail1 mt-2">Email</label>
                <input
                  type="email"
                  class="form-control py-3 mt-1"
                  style={{ fontSize: "15px" }}
                  placeholder="Johndeo@example.com"
                  value={users && users.email}
                  onChange={handleUserChange}
                  id="email"
                  name="email"
                  readOnly
                />
              </div>
              */}
              <div class="form-group ">
                <label for="exampleInputEmail1 mt-2">Phone</label>
                <input
                  type="text"
                  class="form-control mt-1"
                  style={{ fontSize: "15px", paddingTop: "18px", paddingBottom: "18px" }}
                  placeholder="Enter Phone No."
                  onChange={handleUserChange}
                  id="phone"
                  name="phone"
                  value={users && users.phone}
                />
              </div>
              <div class="form-group ">
                <label for="exampleInputEmail1 mt-2">City</label>
                <input
                  type="text"
                  class="form-control mt-1"
                  style={{ fontSize: "15px", paddingTop: "18px", paddingBottom: "18px" }}
                  placeholder="Enter City Name"
                  onChange={handleUserChange}
                  id="city"
                  name="city"
                  value={users && users.city}
                />
              </div>
              
          
                <label for="exampleInputEmail1 mt-2">Availability</label>
                <select aria-label="Default select example"
                  class="form-select mt-1"
                  style={{ fontSize: "15px", paddingTop: "8px", paddingBottom: "6px" }}
                  onChange={handleUserChange}
                  id="availability"
                  name="availability"
                  value={users && users.availability}
                >
                  <option selected>Select availability</option>
                  <option value="Immediate">Immediate</option>
                  <option value="Within 1 Week">Within 1 Week</option>
                  <option value="Within 2 Weeks">Within 2 Weeks</option>
                  <option value="Within 3 Weeks">Within 3 Weeks</option>
                  <option value="Within 4 Weeks">Within 4 Weeks</option>
                  <option value="Within 5 Weeks">Within 5 Weeks</option>
                  <option value="Within 6 Weeks">Within 6 Weeks</option>
                  <option value="Within 7 Weeks">Within 7 Weeks</option>
                  <option value="Within 8 Weeks">Within 8 Weeks</option>
                </select>
            </form>
            <div class="d-flex gap-4">
             
              <button onClick={handleUpate} className="resume-upload  my-5">
                {loading ? <Spinner /> : <span>Update</span>}
              </button>

              {user && user.role != "User" ? (
                <></>
              ) : (
                <>
                  <a style={{ textDecoration: "none" }} href="/addResume" onClick={() => handleAddEvent("Update resume")}>
                    {" "}
                    <button className="resume-upload  my-5 ">
                      Update Resume
                    </button>
                  </a>
                </>
              )}
            </div>
            {/* <div className="details">
                        <div className="info">
                            <strong>Email : </strong>
                            <span>avichalkaushik0007@gmail.com</span>
                        </div>
                        <span className='edit-btn'><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg></span>
                    </div>

                    <div className="details">
                        <div className="info">
                            <strong>Name : </strong>
                            <span>Avichal Kaushik</span>
                        </div>
                        <span className='edit-btn'><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg></span>
                    </div>

                    <div className="details">
                        <div className="info">
                            <strong>Phone Number : </strong>
                            <span>+91 8273843718</span>
                        </div>
                        <span className='edit-btn'><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg></span>
                    </div>

                    <div className="details">
                        <div className="info">
                            <strong>Location : </strong>
                            <span>Meerut, India</span>
                        </div>
                        <span className='edit-btn'><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg></span>
                    </div>

          <div className="resume-box"> 
            <h3 className="title">Resume</h3>
           <a style={{textDecoration: "none"}} href="/addResume"> <button  className="resume-upload btn ">
              Update Resume
            </button></a>
          </div>
           */}
          </div>
        </div>
      </div>
      <ToastContainer
      position="top-center"
      autoClose={10000}
      />
    </>
  );
}

export default Profile;
