import React, {useEffect, useState, useRef } from 'react';
import Slider from 'react-slick';
import './LogoSlider.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

const LogoSlider = ({ data }) => {

  // const [currentCardIndex, setCurrentCardIndex] = useState(0);
  const sliderRef = useRef(null); // Create a reference to the slider

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        // Pause the slider when the tab is inactive
        sliderRef.current.slickPause();
      } else {
        // Resume the slider when the tab is active
        sliderRef.current.slickPlay();
      }
    };

    // Add event listener for visibility change
    document.addEventListener("visibilitychange", handleVisibilityChange);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  const [settings, setSettings] = useState({
    dots: false,
    infinite: true,
    slidesToShow: 7,
    slidesToScroll: 1,
    autoplay: true,
    speed: 30000,
    autoplaySpeed: 10,
    cssEase: "linear",
    rtl: false,               // Ensures left-to-right movement
pauseOnHover: false,
pauseOnFocus: false
    // speed: 500,
    // slidesToScroll: 1,
    // infinite: false,
    // slidesToShow: 7, // Default value, can be overridden 
    // beforeChange: (oldIndex, newIndex) => handleBeforeChange(newIndex),
    // afterChange: index => handleAfterChange(index),
  });

  useEffect(() => {
    // Function to update slidesToShow based on window size
    const updateSlidesToShow = () => {
      const pageSize = window.innerWidth;
      let slidesToShow,slidesToScroll;
      if (pageSize >= 1300) {
        slidesToShow = 8; // Show 7 slides for large screens
        slidesToScroll=8;
      } else if (pageSize >= 1100) {
        slidesToShow = 6; // Show 5 slides for medium screens
        slidesToScroll=6;
      } else if (pageSize >= 992) {
        slidesToShow = 5; // Show 5 slides for medium screens
        slidesToScroll=5;
      } else if (pageSize >= 850) {
        slidesToShow = 4; // Show 5 slides for medium screens
        slidesToScroll=4;
      } else if (pageSize >= 668) {
        slidesToShow = 3; // Show 3 slides for small screens
        slidesToScroll=3;
      } else {
        slidesToShow = 2; // Show 1 slide for extra small screens
        slidesToScroll=2;
      }
      // Update the state with the new value of slidesToShow
      setSettings(prevSettings => ({
        ...prevSettings,
        slidesToShow,
        slidesToScroll
      }));
    };

    // Call the function initially to set the correct value
    updateSlidesToShow();

    // Listen to the window resize event to update the slidesToShow value dynamically
    window.addEventListener('resize', updateSlidesToShow);

    // Clean up the event listener
    return () => {
      window.removeEventListener('resize', updateSlidesToShow);
    };
  }, []); // Empty dependency array ensures that this effect runs only once on mount



  // const handlePrevButtonClick = () => {
  //   const newIndex = Math.max(currentCardIndex - 1, 0);
  //   setCurrentCardIndex(newIndex);
  //   slider?.current?.slickPrev();
  // };

  // const handleNextButtonClick = () => {
  //   const newIndex = Math.min(currentCardIndex + 1, data.length - 1);
  //   setCurrentCardIndex(newIndex);
  //   slider?.current?.slickNext();
  // };

  // const handleBeforeChange = (newIndex) => {
  //   setCurrentCardIndex(newIndex);
  // };

  // const handleAfterChange = (index) => {
  //   setCurrentCardIndex(index);
  //   updateThumbPosition(index);
  // };

  // const updateThumbPosition = () => {
  //   const totalItems = data.length;
  //   const visibleItems = 7;
  //   const maxThumbLeft = 63;
  //   const thumbLeft = Math.min(
  //     (currentCardIndex / (totalItems - visibleItems)) * maxThumbLeft,
  //     maxThumbLeft
  //   );
  //   thumbRef.current.style.left = `${thumbLeft}%`;
  // };
  // const handlePrevButtonClick = () => {
  //   slider.current.slickPrev();
  // };

  // const handleNextButtonClick = () => {
  //   slider.current.slickNext();
  // };

  // const updateThumbPosition = (index) => {
  //   const totalItems = data.length;
  //   const visibleItems = settings.slidesToShow;
  //   const maxThumbLeft = 63;
  //   const thumbLeft = Math.min(
  //     (index / (totalItems - visibleItems)) * maxThumbLeft,
  //     maxThumbLeft
  //   );
  //   thumbRef.current.style.left = `${thumbLeft}%`;
  // };

  // useEffect(() => {
  //   updateThumbPosition();
  // }, [currentCardIndex]);


  // const slider = useRef(null);
  // const thumbRef = useRef(null);
  return (
    <div className="internships-jobs-section" style={{ background: '#F0FBFF', padding: '20px 0' }}>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div className="scroll" style={{maxWidth: "85vw"}}>
          <Slider {...settings} ref={sliderRef} >
            {data?.map((item, index) => (
              <div className="mr-4 my-5 slider-image" key={index} x>
                {/* <img src={item} alt="logo" style={{ height: "100px", width: "90%" }} /> */}
                {/* <img
                  src={item}
                  alt="logo"
                  style={{
                    display:"flex",
                    // height: "100px",
                    height: (item === "https://res.cloudinary.com/dlngy1xko/image/upload/v1709035740/blackcoffer/uj2ddcsj6jctc0rfvqou.png" ? "56px":
                    item === "https://res.cloudinary.com/dlngy1xko/image/upload/v1710414956/blackcoffer/lqzztkoskgfo6wkxs6pg.jpg"?"67px":
                    item === "https://res.cloudinary.com/dlngy1xko/image/upload/v1710238400/blackcoffer/c2bospuchzrbuxz0h03c.png"? "55px":
                    "75px"),
                    // width: item === "https://res.cloudinary.com/dlngy1xko/image/upload/v1710238400/blackcoffer/c2bospuchzrbuxz0h03c.png" ? "75%" : "90%"
                    width: item === "https://res.cloudinary.com/dlngy1xko/image/upload/v1710238400/blackcoffer/c2bospuchzrbuxz0h03c.png" ? "32%": 
                item === "https://res.cloudinary.com/dlngy1xko/image/upload/v1710414956/blackcoffer/lqzztkoskgfo6wkxs6pg.jpg" ? "56%" :
                item === "https://res.cloudinary.com/dlngy1xko/image/upload/v1709035740/blackcoffer/uj2ddcsj6jctc0rfvqou.png" 
                ? "52%" :
                "125px" ,
                marginLeft:
      item === "https://res.cloudinary.com/dlngy1xko/image/upload/v1710238400/blackcoffer/c2bospuchzrbuxz0h03c.png"
        ? "60px"
        : item === "https://res.cloudinary.com/dlngy1xko/image/upload/v1710414956/blackcoffer/lqzztkoskgfo6wkxs6pg.jpg"
        ? "26px"
        : item === "https://res.cloudinary.com/dlngy1xko/image/upload/v1709035740/blackcoffer/uj2ddcsj6jctc0rfvqou.png"
        ? "30px"
        : "0px"
                  }}
                /> */}
               
<img
  src={item}
  alt="logo"
  style={{
    display: "flex",
    justifyItems: "center",
    flexDirection: "column",
    height: "100px", // Set a fixed height for uniformity
    width: "100px", // Set a fixed width for uniformity
    objectFit: "contain", // Maintain aspect ratio while fitting within the container
    margin: "0 1rem", // Center the image horizontally
    transition: "transform 0.3s", // Add a transition effect
  }}
  onMouseEnter={(e) => e.currentTarget.style.transform = "scale(1.1)"} // Scale up on hover
  onMouseLeave={(e) => e.currentTarget.style.transform = "scale(1)"} // Scale back on mouse leave
/>

              </div>
            ))}
          </Slider>
        </div>

        
      </div>
    </div>
  );
}


export default LogoSlider;
