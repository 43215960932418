import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import ReplyIcon from "@mui/icons-material/Reply";
import DeleteIcon from "@mui/icons-material/Delete";

const MessageOptionModal = ({ 
    senderId, 
    conversationId, 
    messageId, 
    handleRemoveMessage, 
    allData, 
    handleEditMessage,
    setEditFlag,
    setchatMessage,
    setEditMessageId,
    setEditConversationId,
    setReplyMessage,
    setReplyFlag,
    setDeleteFlag,
    wholeData,
    setDeleteMessage,
    receiverId,
    user
}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const isObjectId = (id) => /^[0-9a-fA-F]{24}$/.test(id);

    const memoizedConversationId = React.useMemo(() => {
        if (conversationId && isObjectId(conversationId)) {
            return conversationId;
        }

        if (wholeData && wholeData.roomId === conversationId) {
            return wholeData._id;
        }

        return null;
    }, [conversationId, allData]);
    
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
    };

    // Check if message can be edited/deleted (within 1 hour)
    const isWithinDeletionTime = React.useMemo(() => {
        if (!allData?.timestamp) return false;
        
        const messageTime = new Date(allData.timestamp);
        const currentTime = new Date();
        const timeDifference = currentTime - messageTime;
        
        const oneHourInMilliseconds = 60 * 60 * 1000;
        
        return timeDifference <= oneHourInMilliseconds;
    }, [allData?.timestamp]);

    // Determine available options based on whether user is sender or receiver
    const optionsWithIcons = React.useMemo(() => {
        const isMessageSender = senderId === user._id;
        
        if (isMessageSender) {
            return [
                { 
                    label: "Edit", 
                    icon: <EditIcon fontSize="small" />,
                    disabled: !isWithinDeletionTime
                },
                { 
                    label: "Reply", 
                    icon: <ReplyIcon fontSize="small" />,
                    disabled: false
                },
                { 
                    label: "Delete", 
                    icon: <DeleteIcon fontSize="small" />,
                    disabled: !isWithinDeletionTime
                }
            ];
        } else {
            // Only show Reply option for receiver's messages
            return [
                { 
                    label: "Reply", 
                    icon: <ReplyIcon fontSize="small" />,
                    disabled: false
                }
            ];
        }
    }, [senderId, user._id, isWithinDeletionTime]);

    return (
        <div>
            <IconButton 
                aria-label="more" 
                id="long-button" 
                aria-controls={open ? "long-menu" : undefined} 
                aria-expanded={open ? "true" : undefined} 
                aria-haspopup="true" 
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="long-menu"
                MenuListProps={{
                    "aria-labelledby": "long-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                slotProps={{
                    paper: {
                        style: {
                            maxHeight: 48 * 4.5,
                            width: "20ch",
                        },
                    },
                }}
            >
                {optionsWithIcons.map(({ label, icon, disabled }) => (
                    <MenuItem
                        key={label}
                        onClick={() => {
                            handleClose();
                            if (!disabled) {
                                if (label === "Edit") {
                                    setEditFlag(true);
                                    setchatMessage(allData?.content);
                                    setEditMessageId(messageId);
                                    setEditConversationId(memoizedConversationId);
                                } else if (label === "Reply") {
                                    setchatMessage('');
                                    setReplyFlag(true);

                                    setReplyMessage({ 
                                        conversationId: memoizedConversationId,   
                                        parentMessageId: allData._id,
                                        senderId,
                                        receiverId,
                                    }); 
                                } else if (label === "Delete") {
                                    setDeleteFlag(true)
                                    setDeleteMessage({  
                                        conversationId: memoizedConversationId,  
                                        messageId: messageId  
                                    });
                                }
                            }
                        }}
                        disabled={disabled}
                        style={{ 
                            fontSize: "14px", 
                            display: "flex", 
                            alignItems: "center", 
                            gap: "8px",
                            cursor: disabled ? 'not-allowed' : 'pointer'
                        }}
                    >
                        {icon}
                        <span>{label}</span>
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
};

export default React.memo(MessageOptionModal);