import React from "react";
import "./Pagination.css"; // Ensure you have this CSS file for styling
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";

const PaginationWithLastButton = ({ currentPage, totalPages, onPageChange }) => {

  const getPaginationItems = () => {
    let pages = [];
    let startPage, endPage;

    if (totalPages <= 5) {
      // If total pages are 5 or less, show all pages
      startPage = 1;
      endPage = totalPages;
    } else {
      // Always show first page, last page, and up to 3 pages around the current page
      if (currentPage <= 3) {
        startPage = 1;
        endPage = 4;
      } else if (currentPage >= totalPages - 2) {
        startPage = totalPages - 3;
        endPage = totalPages - 1;
      } else {
        startPage = currentPage - 1;
        endPage = currentPage + 1;
      }
    }

    // First page
    pages.push(
      <a
        key={1}
        className={`page-item ${1 === currentPage ? "active" : "not-active"}`}
        onClick={() => onPageChange(1)}
        style={{ color: 1 === currentPage ? "white" : "#0e709e" }}
      >
        1
      </a>
    );

    // Add dots after first page if necessary
    if (startPage > 2) {
      pages.push(<span key="start-dots" className="dots">...</span>);
    }

    // Add pages
    for (let i = startPage; i <= endPage; i++) {
      if (i !== 1 && i !== totalPages) {
        pages.push(
          <a
            key={i}
            className={`page-item ${i === currentPage ? "active" : "not-active"}`}
            onClick={() => onPageChange(i)}
            style={{ color: i === currentPage ? "white" : "#0e709e" }}
          >
            {i}
          </a>
        );
      }
    }

    // Add dots before last page if necessary
    if (endPage < totalPages - 1) {
      pages.push(<span key="end-dots" className="dots">...</span>);
    }

    // Last page
    if (totalPages > 1) {
      pages.push(
        <a
          key={totalPages}
          className={`page-item ${totalPages === currentPage ? "active" : "not-active"}`}
          onClick={() => onPageChange(totalPages)}
          style={{ color: totalPages === currentPage ? "white" : "#0e709e" }}
        >
          {totalPages}
        </a>
      );
    }

    return pages;
  };

  return (
    <div className="pagination">
      <a
        className="button-prev"
        onClick={() => {
          if (currentPage === 1) {
            return;
          }
          onPageChange(currentPage - 1);
        }}
        style={{
          cursor: currentPage === 1 ? "not-allowed" : "pointer",
          color: currentPage === 1 ? "#a8a7a7" : "#0e709e",
        }}
      >
        <MdKeyboardArrowLeft style={{ fontSize: "20px" }} />
        <p style={{ marginTop: "10px", marginLeft: "5px" }}> Previous</p>
      </a>
      {getPaginationItems()}
      <a
        className="button-next"
        onClick={() => {
          if (currentPage === totalPages) {
            return;
          }
          onPageChange(currentPage + 1);
        }}
        style={{
          cursor: currentPage === totalPages ? "not-allowed" : "pointer",
          color: currentPage === totalPages ? "#a8a7a7" : "#0e709e",
        }}
      >
        <p style={{ marginTop: "10px", marginRight: "5px" }}> Next</p>
        <MdKeyboardArrowRight style={{ fontSize: "20px" }} />
      </a>
    </div>
  );
};

export default PaginationWithLastButton;