import React, { useRef, useCallback, useMemo } from "react";
import "./css/MessageStudent.scss";
import io from "socket.io-client";
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import { MdOutlineArrowBack } from "react-icons/md";
import { FiSearch } from "react-icons/fi";
import { MdOutlineMarkEmailUnread, MdMailOutline } from "react-icons/md";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import sendAssignmentLogo from "../../assets/images/sendassignment.png";
import reportLogo from "../../assets/images/report.png";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RiChatOffLine } from "react-icons/ri";
import { index } from "d3";
import Dropdown from "./Dropdown";
import { read } from "xlsx";
import { PiChecks } from "react-icons/pi";
import { MdKeyboardBackspace } from "react-icons/md";
import { LuSearch } from "react-icons/lu";
import { RxCross1 } from "react-icons/rx";
import { replyArrow, cross } from "../../assets";
import { IoLocation } from "react-icons/io5";
import { Tooltip } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MessageOptionsModal from "../Dropdown/MessageOptionsModal";
import DeleteMessageModal from "../Dropdown/DeleteMessageModal";
// const socket = io.connect("http://localhost:8000");
const socket = io.connect("https://hirekingdom.com");
// const socket = io.connect('http://34.27.222.64:8000/');

function MessageStudent(props) {
    const [viewMessage, setViewMesage] = useState(false);

    const { state } = useLocation();

    const wrapLinks = (content) => {
        if (!content) return "";
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        return content.replace(urlRegex, '<a href="$1" target="_blank">$1</a>');
    };

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo(0, 0);
    }, []);
    const [CompanyphotoUrl, setCompanyphotourl] = useState("");

    const [allRooms, setAllRooms] = useState([]);
    const [chatMessage, setchatMessage] = useState("");
    const [history, setHistory] = useState([]);
    const { user } = useSelector((state) => state.user);
    const [companyName, setCompanyName] = useState("");
    const [roomId, setRoomId] = useState("");
    const [hasJoinedRoom, setHasJoinedRoom] = useState(false);
    const [chatActive, setChatActive] = useState(0);
    const [unread, setUnread] = useState(false);
    const navigate = useNavigate();
    const [activeChat, setActiveChat] = useState(0);

    const [conversationId, setConversationId] = useState(null);
    const [selectedRoom, setSelectedRoom] = useState(null);

    const [editMessageId, setEditMessageId] = useState({});
    const [editConversationId, setEditConversationId] = useState({});
    const [replyMessage, setReplyMessage] = useState({});
    const [deleteMessage, setDeleteMessage] = useState({});
    const [receriverData, setReceiverData] = useState({});
    const [receriverDataNew, setReceiverDataNew] = useState({});
    const [replyFlag, setReplyFlag] = useState(false);
    const [editFlag, setEditFlag] = useState(false);
    const [deleteFlag, setDeleteFlag] = useState(false);
    const [deleteConfirm, setDeleteConfirm] = useState(false);
    const [chatType, setChatType] = useState();
    const [company, setcompany] = useState();
    const [CandidateName, setCandidateName] = useState("");
    const [CompanyCandidatename, setCompanyCandidatename] = useState("");
    const [open, setOpen] = React.useState(false);
    const [CandidatephotoUrl, setCandidatephotoUrl] = useState("");
    const [employerLocation, setEmployerLocation] = useState();

    const [inputDataSendAssigment, setInputDataSendAssigment] = useState({
        description: "",
        date: "",
    });
    const [loader, setLoader] = useState(true);
    const [search, setSearch] = useState("");
    const [activeTab, setActiveTab] = useState("all");

    const ReadMess = async () => {
        setUnread(false);
        setActiveTab("all");
        const id = user && user._id;
        const { data } = await axios.post(
            `/chats/all-conversations/${id}`,
            {
                unreadMessages: false,
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    token: localStorage.getItem("token"),
                },
            }
        );

        if (data && data.length > 0) {
            setAllRooms(data);
            joinRoom(data[0].roomId, data[0].participants[0].organisationName);
        }
    };

    const unRead = async () => {
        setUnread(true);
        setActiveTab("unread");
        const id = user && user._id;
        const { data } = await axios.post(
            `/chats/all-conversations/${id}`,
            {
                unreadMessages: true,
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    token: localStorage.getItem("token"),
                },
            }
        );

        setAllRooms(data);
        if (data && data.length > 0) {
            joinRoom(data[0].roomId, data[0].participants[0].organisationName);
        }
    };

    const getActiveButtonStyle = () => {
        if (activeTab === "all") {
            return {
                "--active-width": "119.417px",
                "--transform-x": "-1px",
            };
        } else {
            return {
                "--active-width": "103.0833px",
                "--transform-x": "112.417px",
            };
        }
    };

    useEffect(() => {
        socket.on("test_message", (data) => {});
        if (allRooms && allRooms.length > 0 && chatActive == 0) {
            setChatActive(allRooms[0].roomId);
        }
    });

    const handleInputSendAssigment = (e) => {
        setInputDataSendAssigment({
            ...inputDataSendAssigment,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmitSendAssigment = () => {
        // Put your code here...
        console.log(inputDataSendAssigment);
        setInputDataSendAssigment({
            ...inputDataSendAssigment,
            description: "",
            date: "",
        });
    };

    const [inputDataSendReport, setInputDataSendReport] = useState({
        reason: "Provided fake documents",
        description: "",
        otherReason: "",
    });

    const GetRoomData = async () => {
        console.log("GetRoomData called");
        // setLoader(true);
        try {
            const id = user && user._id;
            const { data } = await axios.post(
                `/chats/all-conversations/${id}`,
                {
                    // unreadMessages: unread,
                    jobId: selectedItem && selectedItem._id ? selectedItem._id : "",
                    search: "",
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        token: localStorage.getItem("token"),
                    },
                }
            );

            setAllRooms(data);
            setLoader(false);
        } catch (err) {
            console.error("Error fetching data:" + err);
            setLoader(false);
        }
    };

    useEffect(() => {
        GetRoomData();
    }, [socket, history, user]);
    const [selectedItem, setSelectedItem] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            setLoader(true);
            const id = user._id;
            const { data } = await axios.post(
                `/chats/all-conversations/${id}`,
                {
                    jobId: selectedItem && selectedItem._id ? selectedItem._id : "",
                    search: "",
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        token: localStorage.getItem("token"),
                    },
                }
            );
            if (state) {
                const room = data.find((item) => item.roomId === state);
                if (room) {
                    joinRoom(room.roomId, room.participants[1], room.participants[0]);
                } else {
                    console.log("Room not found");
                }
            } else {
                joinRoom(data[0].roomId, data[0].participants[1], data[0].participants[0]);
            }
            setAllRooms(data);
            setLoader(false);
        };

        fetchData();
    }, [selectedItem]);
    const [once, setOnce] = useState(true);
    const [temp, setTemp] = useState("");
    useEffect(() => {
        const receriverName = allRooms.find((data) => data._id === conversationId || data.roomId === conversationId)?.participants[0];
        setReceiverData(receriverName);
    }, [conversationId]);

    const initialRoomId = useMemo(() => {
        return allRooms && allRooms.length > 0 ? allRooms[0]._id : null;
    }, [allRooms]);
    useEffect(() => {
        const receriverNameForPhone = allRooms.find((data) => data._id === conversationId || data.roomId === conversationId)?.participants[0];
        setReceiverData(receriverNameForPhone);
        setReceiverDataNew(receriverNameForPhone);
    }, [conversationId]);

    // Persistent conversation ID setter
    const safeSetConversationId = useCallback((id) => {
        setConversationId((prevId) => {
            // Only update if the new ID is different and valid
            return id || prevId;
        });
    }, []);
    useEffect(() => {
        if (once) {
            const roomIds = allRooms && allRooms.map((data) => data.roomId);
            roomIds.forEach((roomIds) => {
                //joinRoom(roomIds.roomId,roomIds.participants[0].organisationName);
                socket.emit("join_room", roomIds.roomId);
                socket.on("room_data", (data) => {
                    setTemp(data);
                });
            });
            setOnce(false);
        }
    }, [allRooms]);

    const [historyChange, setHistoryChange] = useState(false);
    useEffect(() => {
        if (allRooms && allRooms.length > 0 && !hasJoinedRoom) {
            if (state) {
                const room = allRooms.find((item) => item.jobId._id === state.jobId._id && item.appliedJobId._id === state.appliedJobId._id);

                if (room) {
                    joinRoom(room.roomId, room.participants[0], room.participants[1]);
                } else {
                    console.log("Room not found");
                }
            } else {
                joinRoom(allRooms[0].roomId, allRooms[0].participants[0], allRooms[0].participants[1]);
            }
            setHasJoinedRoom(true); // Set the flag to true after joining room
        } else if (allRooms && allRooms.length > 0 && historyChange) {
            let room = allRooms.find((item) => item.roomId === chatActive);
            joinRoom(chatActive, room.participants[0], room.participants[1]);
            /* socket.emit('join_room', chatActive);
      socket.on('room_data', (data) => {
        setHistory(data);
      });*/

            setHistoryChange(false);
        }
    }, [allRooms, chatActive, hasJoinedRoom]);

    useEffect(() => {
        // An array of your room IDs
        const roomIds = allRooms && allRooms.map((data) => data.roomId);
        // Function to set up event listener for a specific room
        const setupRoomListener = (roomId2) => {
            socket.on(`receive_message_${roomId2}`, (data) => {
                console.log(`Received message for room ${roomId2}:`, data);
                GetRoomData();
                if (roomId2 == chatActive) {
                    setHistoryChange(true);
                }
            });
        };

        // Set up event listeners for each room
        roomIds.forEach((roomIds) => {
            setupRoomListener(roomIds);
        });
        // Cleanup the event listeners on component unmount
        return () => {
            roomIds.forEach((roomIds) => {
                socket.off(`receive_message_${roomIds}`);
            });
        };
    });

    const joinRoom = useCallback(
        (roomId1, company, cand, conversationid) => {
            console.log("join rooom called");
            const finalConversationId = conversationid || selectedRoom || initialRoomId;
            const chatTypeForPotentialMsg = allRooms.find((data) => data.roomId === roomId1);
            // Set selected room and conversation ID
            setSelectedRoom(roomId1);
            safeSetConversationId(finalConversationId);
            setChatType(chatTypeForPotentialMsg.appliedJobId ? (chatTypeForPotentialMsg.appliedJobId.status === "Assignment" ? "Assignment Received" : chatTypeForPotentialMsg.appliedJobId.status === "Application_Sent" ? "Application Sent" : chatTypeForPotentialMsg.appliedJobId.status === "NotIntrested" ? "Not Interested" : chatTypeForPotentialMsg.appliedJobId.status) : "General");
            setActiveChat(roomId1);
            setCompanyName(company?.organisationName);
            setCompanyphotourl(company?.avatar?.url || company?.organisationAvatar?.url);
            setcompany(company);
            setCandidateName(cand?.name);
            setCandidatephotoUrl(cand?.resume?.PersonalDetails?.photo?.url);
            setCompanyCandidatename(company?.name);
            setEmployerLocation(company?.city);
            setRoomId(roomId1);
            socket.emit("join_room", roomId1);
            focusInputBox();
            socket.on("room_data", (data) => {
                setHistory(data);
                setChatActive(roomId1);
                if (data.messages.length > 0 && data.messages[data.messages.length - 1].status == "unread" && data.messages[data.messages.length - 1].receiver == user._id) {
                    axios.post(
                        "/chats/read-messages",
                        { roomId1 },
                        {
                            headers: {
                                "Content-Type": "application/json",
                                token: localStorage.getItem("token"),
                            },
                        }
                    );
                }
            });

            GetRoomData();
        },
        [socket, user?._id, selectedRoom, initialRoomId, safeSetConversationId]
    );
    useEffect(() => {
        // Only set if no conversation ID is set and rooms exist
        if (!conversationId && initialRoomId) {
            safeSetConversationId(initialRoomId);
        }
    }, [initialRoomId, conversationId, safeSetConversationId]);

    // Messaging send effect to preserve conversation ID
    useEffect(() => {
        // If conversation ID becomes undefined after message send
        // Restore it from the selected room or initial room
        if (!conversationId) {
            const restoreId = selectedRoom || initialRoomId;
            safeSetConversationId(restoreId);
        }
    }, [conversationId, selectedRoom, initialRoomId, safeSetConversationId]);
    const sendMessage = async (data, jobId) => {
        if (chatMessage.trim() === "") return;

        try {
            const finalConversationId = conversationId || selectedRoom || initialRoomId;

            // Create message structure matching server's format
            const messageData = {
                jobId,
                participants: [user._id, data],
                content: chatMessage,
                conversationId: finalConversationId,
                replyTo: null,
            };

            // Clear input immediately
            const messageToSend = chatMessage;
            setchatMessage("");

            const response = await axios.post("/chats/send-messages", messageData, {
                headers: {
                    "Content-Type": "application/json",
                    token: localStorage.getItem("token"),
                },
            });

            if (response.data.success) {
                const newMessage = {
                    ...response.data.message,
                    replyTo: null,
                };

                setHistory((prev) => ({
                    ...prev,
                    messages: [...prev.messages, newMessage],
                }));

                // Emit socket event
                socket.emit("new_message", {
                    roomId,
                    message: messageToSend,
                });
            }

            return response.data;
        } catch (error) {
            console.error("Error sending message:", error);
            throw error;
        }
    };

    const handleRemoveMessage = async (conversationId, messageId) => {
        console.log("called");
        try {
            const token = localStorage.getItem("token");

            if (!token) {
                throw new Error("User is not authenticated.");
            }

            // Ensure we have a valid conversation ID
            const finalConversationId = conversationId || selectedRoom || initialRoomId;

            const response = await axios.post(
                `/chats/delete-messages/${finalConversationId}/${messageId}`,
                {},
                {
                    headers: {
                        "Content-Type": "application/json",
                        token,
                    },
                }
            );

            if (response.data.success) {
                // Optimistically update local state
                setHistory((prevHistory) => ({
                    ...prevHistory,
                    messages: prevHistory.messages.filter((msg) => msg._id !== messageId),
                }));
                setDeleteFlag(false);
                socket.emit("new_message", {
                    roomId,
                    type: "delete",
                    messageId,
                });

                return response.data;
            } else {
                console.error("Failed to remove message:", response.data.message);
                toast.error("Failed to remove message: " + response.data.message);
                setDeleteFlag(false);

                throw new Error(response.data.message);
            }
        } catch (error) {
            console.error("Error removing message:", error.response?.data?.message || error.message || error);
            toast.error("Error removing message");
            setDeleteFlag(false);

            throw error;
        }
    };

    const handleEditMessage = async (editedMsg, conversationId, messageId) => {
        if (!editedMsg) {
            console.error("Content is empty. Message cannot be updated.");
            toast.error("Message content cannot be empty");
            return;
        }

        // Ensure we have a valid conversation ID
        const finalConversationId = conversationId || selectedRoom || initialRoomId;

        try {
            const token = localStorage.getItem("token");
            if (!token) {
                throw new Error("User is not authenticated.");
            }

            // Prepare content
            const content = editedMsg;
            setchatMessage(content);
            // focusInputBox();

            // Make the API call to edit the message
            const response = await axios.post(
                `/chats/edit-messages/${finalConversationId}/${messageId}`,
                { content },
                {
                    headers: {
                        "Content-Type": "application/json",
                        token,
                    },
                }
            );

            if (response.data.success) {
                // Emit socket event for edit
                socket.emit("new_message", {
                    roomId,
                    message: editedMsg,
                    type: "edit",
                    messageId,
                });

                setEditFlag(false);
                setchatMessage("");
                setEditMessageId(null);
            } else {
                console.error("Failed to edit message:", response.data.message);
                toast.error(response.data.message);
                setEditFlag(false);
                throw new Error(response.data.message);
            }
        } catch (error) {
            console.error("Error editing message:", error.response?.data?.message || error.message || error);
            toast.error("Failed to edit message");
            setEditFlag(false);
            throw error;
        }
    };

    const handleReplyMessage = async (msg, conversationId, parentMessageId, senderId, receiverId) => {
        if (!msg) {
            console.error("Reply message content is empty.");
            return;
        }

        try {
            const token = localStorage.getItem("token");
            if (!token) throw new Error("User not authenticated");

            const finalConversationId = conversationId || selectedRoom || initialRoomId;
            const data1 = {
                roomId,
                message: chatMessage,
            };
            const response = await axios.post(
                `/chats/reply-messages/${finalConversationId}/${parentMessageId}/${senderId}/${receiverId}`,
                {
                    content: msg,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        token,
                    },
                }
            );

            if (response.data.success) {
                setHistory((prevHistory) => ({
                    ...prevHistory,
                    messages: [...prevHistory.messages, response.data.replyMessage],
                }));
                socket.emit("new_message", data1);

                setReplyFlag(false);
                setchatMessage("");
                GetRoomData();
                return response.data;
            } else {
                console.error("Failed to send reply:", response.data.message);
                throw new Error(response.data.message);
            }
        } catch (error) {
            console.error("Error sending reply:", error.response?.data?.message || error.message);
            setReplyFlag(false);
            throw error;
        }
    };

    const scroll = useRef();

    const scrolltoBottom = () => {
        scroll.current.scrollIntoView({
            behaviour: "smooth",
            block: "end",
        });
    };

    const scroll2 = useRef();

    const scrolltoTop = () => {
        scroll.current.scrollIntoView({
            behaviour: "smooth",
            block: "start",
        });
    };

    useEffect(() => {
        if (scroll.current) {
            scrolltoBottom();
        }
    }, [history]);

    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const [isButtonClicked, setButtonClicked] = useState(false);
    const dropdownRef = useRef();
    const dropdownRef1 = useRef();
    const [isDropdownOpen1, setDropdownOpen1] = useState(false);
    const [isButtonClicked1, setButtonClicked1] = useState(false);

    const handleDropdownToggle = () => {
        setDropdownOpen(!isDropdownOpen);
        setButtonClicked(!isButtonClicked);
    };

    const handleDropdownToggle1 = () => {
        setDropdownOpen1(!isDropdownOpen1);
        setButtonClicked1(!isButtonClicked1);
    };

    const handleDropdownItemClick1 = (item) => {
        setInputDataSendReport({ ...inputDataSendReport, reason: item.name });
        setDropdownOpen1(false);
        setButtonClicked1(false);
        // Add your custom logic for item click here, if needed
        // For example, you can handle the selected item
    };

    const handleDropdownItemClick = (item) => {
        setInputDataSendReport({ ...inputDataSendReport, reason: item.name });
        setDropdownOpen(false);
        setButtonClicked(false);
        // Add your custom logic for item click here, if needed
        // For example, you can handle the selected item
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setDropdownOpen(false);
            setButtonClicked(false);
        }
        if (dropdownRef1.current && !dropdownRef1.current.contains(event.target)) {
            setDropdownOpen1(false);
            setButtonClicked1(false);
        }
    };

    useEffect(() => {
        window.addEventListener("click", handleClickOutside);
        return () => {
            window.removeEventListener("click", handleClickOutside);
        };
    }, []);

    const [lastTimestamp, setLastTimestamp] = useState(null);
    const [lastDisplayedDate, setLastDisplayedDate] = useState(null);

    useEffect(() => {
        if (history.messages && history.messages.length > 0) {
            const lastMessageTimestamp = history.messages[history.messages.length - 1].timestamp;
            if (lastTimestamp !== lastMessageTimestamp) {
                setLastTimestamp(lastMessageTimestamp);
            }
        }
    }, [history.messages, lastTimestamp]);

    useEffect(() => {
        if (lastDisplayedDate) {
            console.log("Last Displayed Date:", lastDisplayedDate); // You can remove this line later
        }
    }, [lastDisplayedDate]);

    function isToday(date) {
        const today = new Date();
        return date.getDate() === today.getDate() && date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear();
    }

    const getTime = (data) => {
        const timestamp = new Date(data);
        const formattedTime = new Intl.DateTimeFormat("en-IN", {
            timeZone: "Asia/Kolkata",
            hour12: true,
            hour: "2-digit",
            minute: "2-digit",
        }).format(timestamp);
        return formattedTime;
    };

    /*  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Enter') {
        const myButton = document.querySelector('.btnsend');
        if (myButton) {
          myButton.click();
        } else {
          console.error('Button not found');
        }
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, []);*/

    const unreadMsg = allRooms.filter((item) => item.status === "unread" && item.sender !== user._id).length;

    useEffect(() => {
        socket.on("new_message", (data) => {});
    });

    const readMesCount = (data) => {
        const unreadCount = data.filter((message) => message.status === "unread" && message.receiver === user._id).length;
        return unreadCount;
    };

    // MULTILINE MESSAGE OPTION
    const textareaRef = useRef(null);
    const parentDivRef = useRef(null);
    const [textareaRows, setTextareaRows] = useState(1);
    // const maxRows = 6;
    const maxRows = 6;
    const defaultTextareaHeight = 42;

    // const handleKeyDown = (e) => {
    //   if ((e.altKey || e.shiftKey) && e.key === 'Enter') {
    //     e.preventDefault(); // Prevent default behavior of newline insertion
    //     setchatMessage(prevMessage => prevMessage + '\n');
    //   }
    // };
    useEffect(() => {
        socket.on(`receive_message_${roomId}`, ({ message, type, messageId }) => {
            if (type === "edit") {
                // Update the edited message in history
                setHistory((prevHistory) => ({
                    ...prevHistory,
                    messages: prevHistory.messages.map((msg) => (msg._id === messageId ? { ...msg, content: message, edited: true } : msg)),
                }));
            } else if (type === "delete") {
                // Remove deleted message from history
                setHistory((prevHistory) => ({
                    ...prevHistory,
                    messages: prevHistory.messages.filter((msg) => msg._id !== messageId),
                }));
            } else {
                // Handle normal new messages
                setHistory((prevHistory) => ({
                    ...prevHistory,
                    messages: [...prevHistory.messages, message],
                }));
            }
        });

        return () => {
            socket.off(`receive_message_${roomId}`);
        };
    }, [roomId]);
    const focusInputBox = () => {
        textareaRef.current?.focus();
    };
    useEffect(() => {
        focusInputBox();
    }, [editFlag, replyFlag]);

    useEffect(() => {
        if (deleteConfirm) {
            handleRemoveMessage(deleteMessage.conversationId, deleteMessage.messageId);
            setDeleteFlag(false);
            setDeleteConfirm(false);
        }
    }, [deleteConfirm]);

    useEffect(() => {
        if (deleteFlag) {
            setOpen(true);
            setDeleteFlag(false);
        }
    }, [deleteFlag]);

    const handleKeyDown = (e) => {
        if ((e.altKey || e.shiftKey) && e.key === "Enter") {
            e.preventDefault(); // Prevent default behavior

            // Insert a newline at the current cursor position
            const { selectionStart, selectionEnd } = e.target;
            setchatMessage((prevMessage) => prevMessage.slice(0, selectionStart) + "\n" + prevMessage.slice(selectionEnd));

            // Move the cursor position after the newline
            setTimeout(() => {
                e.target.selectionStart = e.target.selectionEnd = selectionStart + 1;
            }, 0);
        } else if (e.key === "Enter") {
            e.preventDefault(); // Prevent default form submission behavior

            if (editFlag) {
                // Call handleEditMessage when editing
                if (chatMessage.trim() !== "") {
                    handleEditMessage(chatMessage, editConversationId, editMessageId);
                    setEditFlag(false); // Reset the edit flag after editing
                    setchatMessage("");
                    return; // Clear the textarea after editing
                }
            }
            if (replyFlag) {
                // if(chatMessage.trim() === ""){
                handleReplyMessage(chatMessage, replyMessage?.conversationId, replyMessage?.parentMessageId, replyMessage?.senderId, replyMessage?.receiverId);
                setReplyFlag(false);
                setchatMessage("");
                return; // Clear the textarea after editing
                // }
            } else {
                // Send a new message
                if (chatMessage.trim() !== "") {
                    const recipientId = history.participants[0] === user._id ? history.participants[1] : history.participants[0];
                    sendMessage(recipientId, history.jobId);
                    setchatMessage(""); // Clear the textarea after sending
                    return; // Clear the textarea after editing
                }
            }
        }
    };
    const handleChange = (e) => {
        // Only update if the length is within the limit
        if (e.target.value.length <= 10000) {
            setchatMessage(e.target.value);
        }
    };
    // useEffect(() => {
    //   const textarea = textareaRef.current;
    //   if (textarea) {
    //     const newRows = Math.min(textarea.value.split('\n').length, maxRows);
    //     setTextareaRows(newRows);
    //     // textarea.style.height = `${newRows * 20}px`;
    //     // textarea.style.height = `${Math.max(textareaRows * 20, defaultTextareaHeight)}px`;
    //     const newHeight = chatMessage ? Math.max(textareaRows * 20, defaultTextareaHeight) : defaultTextareaHeight;
    //     textarea.style.height = `${newHeight}px`;
    //   }
    // }, [chatMessage]);
    useEffect(() => {
        const textarea = textareaRef.current;
        if (textarea) {
            const cursorPosition = textarea.selectionStart;
            const textBeforeCursor = textarea.value.substring(0, cursorPosition);
            const currentLineLength = textBeforeCursor.split("\n").pop().length;

            const hasLineBreak = textarea.value.includes("\n");
            const textWidth = getTextWidth(textBeforeCursor.split("\n").pop(), textarea);
            const isAtLineEnd = textWidth >= textarea.clientWidth - 20;

            if (hasLineBreak || isAtLineEnd) {
                textarea.style.height = "auto";
                const scrollHeight = textarea.scrollHeight;
                const lineHeight = 20;

                const visibleLines = Math.ceil(scrollHeight / lineHeight);
                const newRows = Math.min(visibleLines, 9); // Increased maxRows to 9 (180px / 20px line height)
                setTextareaRows(newRows);

                const newHeight = chatMessage ? Math.max(scrollHeight, defaultTextareaHeight) : defaultTextareaHeight;

                textarea.style.height = `${Math.min(newHeight, 180)}px`; // Changed to 180px max
                textarea.style.overflowY = newHeight > 180 ? "auto" : "hidden"; // Adjusted overflow threshold
            } else {
                textarea.style.height = `${defaultTextareaHeight}px`;
            }
        }
    }, [chatMessage]);

    useEffect(() => {
        const textarea = textareaRef.current;
        const parentDiv = parentDivRef.current;
        if (textarea && parentDiv) {
            const cursorPosition = textarea.selectionStart;
            const textBeforeCursor = textarea.value.substring(0, cursorPosition);
            const currentLineLength = textBeforeCursor.split("\n").pop().length;

            const hasLineBreak = textarea.value.includes("\n");
            const textWidth = getTextWidth(textBeforeCursor.split("\n").pop(), textarea);
            const isAtLineEnd = textWidth >= textarea.clientWidth - 20;

            if (hasLineBreak || isAtLineEnd) {
                const scrollHeight = textarea.scrollHeight;
                const lineHeight = 20;

                const visibleLines = Math.ceil(scrollHeight / lineHeight);
                const newRows = Math.min(visibleLines, 9); // Increased maxRows to 9
                setTextareaRows(newRows);

                const newHeight = Math.max(scrollHeight, defaultTextareaHeight);
                textarea.style.height = `${Math.min(newHeight + 1, 180)}px`; // Changed to 180px max
                textarea.style.overflowY = newHeight > 180 ? "auto" : "hidden"; // Adjusted overflow threshold
            }
        }
    }, [chatMessage, textareaRows]);

    // Helper function remains the same
    function getTextWidth(text, textarea) {
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        const computedStyle = window.getComputedStyle(textarea);

        context.font = `${computedStyle.fontSize} ${computedStyle.fontFamily}`;
        return context.measureText(text).width;
    }

    useEffect(() => {
        // Scroll textarea to bottom when chatMessage changes
        if (textareaRef.current) {
            textareaRef.current.scrollTop = textareaRef.current.scrollHeight;
        }
    }, [chatMessage]);
    const [searchTerm, setSearchTerm] = useState("");

    const handleSearch = (e) => {
        setSearchTerm(e.target.value.trim().toLowerCase());
    };
    return (
        <>
            <div className="ChatPcView">
                <div
                    className="message-student  pb-0 "
                    style={{
                        backgroundColor: "white",
                        borderRadius: "5px",
                        // maxWidth: "1200px",
                        // margin: "0 auto",
                    }}
                >
                    <div className="chat-box1 shadow">
                        {loader ? (
                            <>
                                <div className="recent-chats" style={{ width: "100%" }}>
                                    <div className="chats text-center" style={{ width: "100%" }}>
                                        <div
                                            class="spinner-border "
                                            role="status"
                                            style={{
                                                width: "27px",
                                                height: "27px",
                                                marginTop: "20px",
                                                color: "#5db2f7",
                                            }}
                                        ></div>
                                        <p
                                            style={{
                                                fontSize: "18px",
                                                fontWeight: "400",
                                                color: "gray",
                                            }}
                                            className=" text-center"
                                        >
                                            Loading...
                                        </p>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                {allRooms && allRooms.length > 0 ? (
                                    <>
                                        <div className="recent-chats">
                                            <div
                                                style={{
                                                    // padding: "10px 0 0 0",
                                                    // boxShadow: "0 2px 6px rgba(0, 0, 0, 0.12)",
                                                    display: "flex",
                                                    borderTopLeftRadius: "6px",
                                                    // borderBottomLeftRadius: "6px", // This is commented out
                                                    flexDirection: "column",
                                                    alignItems: "center",
                                                    borderRight: "1px solid #f0eeee",
                                                    borderBottom: "1px solid rgb(224, 224, 224)",
                                                }}
                                            >
                                                <div style={{ width: "100%" }}>
                                                    <div style={{ margin: "0px auto" }}>
                                                        <div
                                                            style={{
                                                                fontSize: "35px",
                                                                fontWeight: "500",
                                                                margin: "0px 2rem",
                                                                marginTop: "4px",
                                                            }}
                                                        >
                                                            Messages
                                                        </div>

                                                        <div
                                                            className="sliderSearch"
                                                            style={{
                                                                display: "flex",
                                                                border: "1px solid #ddd",
                                                                marginTop: "20px",
                                                                width: "90%",
                                                                margin: "0 16px",
                                                                borderRadius: "3px",
                                                                padding: "7px 11px",
                                                                alignItems: "center",
                                                                position: "relative",
                                                                cursor: "text",
                                                                backgroundColor: "#fff",
                                                            }}
                                                        >
                                                            <LuSearch
                                                                style={{
                                                                    fontSize: "24px",
                                                                    color: "#484848",
                                                                    fontWeight: "400",
                                                                }}
                                                            />
                                                            <input
                                                                type="text"
                                                                placeholder="Search "
                                                                value={search}
                                                                onChange={(e) => {
                                                                    handleSearch(e);
                                                                    setSearch(e.target.value);
                                                                }}
                                                                style={{
                                                                    fontSize: "14px",
                                                                    lineHeight: "1.14285714",
                                                                    fontWeight: "400",
                                                                    border: "0",
                                                                    outline: "0",
                                                                    flex: "1",
                                                                    padding: "0 8px",
                                                                    height: "24px",
                                                                    color: "#484848",
                                                                }}
                                                            />

                                                            {search && (
                                                                <RxCross1
                                                                    height={24}
                                                                    width={24}
                                                                    fill="#484848"
                                                                    fontSize={20}
                                                                    cursor={"pointer"}
                                                                    onClick={() => {
                                                                        setSearch("");
                                                                        handleSearch({ target: { value: "" } });
                                                                    }}
                                                                />
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="mb-4"></div>
                                            </div>
                                            <div className="chats" ref={scroll2}>
                                                {allRooms
                                                    .filter((item) => item.participants[0]?.name?.toLowerCase().includes(search.toLowerCase()))
                                                    .map((item, index) => (
                                                        <div key={index} className="wholeBox">
                                                            <div onClick={() => joinRoom(item.roomId, item.participants[0], item.participants[1], item._id)} key={index} href="#" className={activeChat === item.roomId ? "chat-active" : "chat"}>
                                                                <div style={{ display: "flex" }}>
                                                                    <div className="round">
                                                                        <div className="content">{item?.participants[0]?.name.charAt(0)}</div>
                                                                    </div>
                                                                    <div style={{ width: "100%" }}>
                                                                        <div style={{ display: "flex" }}>
                                                                            <span
                                                                                style={{
                                                                                    fontWeight: "600",
                                                                                    fontSize: "16px",
                                                                                    lineHeight: "1.25",
                                                                                    textOverflow: "ellipsis",
                                                                                    whiteSpace: "wrap",
                                                                                    overflow: "hidden",
                                                                                    color: "#333",
                                                                                }}
                                                                            >
                                                                                {item.participants[0].name}
                                                                            </span>

                                                                            <div
                                                                                style={{
                                                                                    paddingLeft: "24px",
                                                                                    marginLeft: "auto",
                                                                                    display: "flex",
                                                                                    flexShrink: "0",
                                                                                }}
                                                                            >
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: "12px",
                                                                                        lineHeight: "1.33333333",
                                                                                        fontWeight: "500",
                                                                                        textAlign: "right",
                                                                                        color: "#8a8a8a",
                                                                                    }}
                                                                                >
                                                                                    {item.messages && item.messages.length > 0 ? (
                                                                                        <>
                                                                                            {(() => {
                                                                                                const lastMessageDate = new Date(item.messages[item.messages.length - 1].timestamp);
                                                                                                const today = new Date();
                                                                                                const yesterday = new Date();
                                                                                                yesterday.setDate(today.getDate() - 1);

                                                                                                if (lastMessageDate.getDate() === today.getDate() && lastMessageDate.getMonth() === today.getMonth() && lastMessageDate.getFullYear() === today.getFullYear()) {
                                                                                                    return "Today";
                                                                                                } else if (lastMessageDate.getDate() === yesterday.getDate() && lastMessageDate.getMonth() === yesterday.getMonth() && lastMessageDate.getFullYear() === yesterday.getFullYear()) {
                                                                                                    return "Yesterday";
                                                                                                } else {
                                                                                                    return lastMessageDate.getDate().toString().padStart(2, "0") + "/" + (lastMessageDate.getMonth() + 1).toString().padStart(2, "0") + "/" + lastMessageDate.getFullYear();
                                                                                                }
                                                                                            })()}
                                                                                        </>
                                                                                    ) : null}
                                                                                </span>
                                                                            </div>
                                                                        </div>

                                                                        {/* <span
                                                                                    style={{
                                                                                        fontSize: "14px",
                                                                                        lineHeight: "1.57142857",
                                                                                        fontWeight: "400",
                                                                                        textOverflow: "ellipsis",
                                                                                        whiteSpace: "nowrap",
                                                                                        overflow: "hidden",
                                                                                        color: "#8A8A8A",
                                                                                        padding: "2px 0 0",
                                                                                    }}
                                                                                >
                                                                                    {item.jobId && item.jobId.profile ? (item.jobId.profile.length > 37 ? `${item.jobId.profile.substring(0, 37)}...` : item.jobId.profile) : ""}
                                                                                </span> */}

                                                                        <span
                                                                            style={{
                                                                                fontSize: "14px",
                                                                                lineHeight: "1.57142857",

                                                                                color: "#666",
                                                                                display: "flex",
                                                                            }}
                                                                        >
                                                                            {item.messages && item.messages.length > 0 ? (
                                                                                <>
                                                                                    {item.messages[item.messages.length - 1].status === "unread" && item.messages[item.messages.length - 1].receiver === user._id ? (
                                                                                        <>
                                                                                            <p style={{ fontWeight: "400" }}>
                                                                                                {item.messages[item.messages.length - 1]?.sender === user._id ? "You: " : item?.participants[0]?.name + ": "}
                                                                                                {(() => {
                                                                                                    const senderName = item.messages[item.messages.length - 1]?.sender === user._id ? "You: " : item?.participants[0]?.name + ": ";
                                                                                                    const messageContent = item.messages[item.messages.length - 1]?.content.replace(/@\[(.*?)\]\(.*?\)/g, "$1") || "";

                                                                                                    const totalLength = senderName.length + messageContent.length;

                                                                                                    if (totalLength > 38) {
                                                                                                        // Calculate how many characters to show from the message
                                                                                                        const availableChars = 38 - senderName.length;
                                                                                                        return messageContent.slice(0, availableChars) + "...";
                                                                                                    }
                                                                                                    return messageContent.slice(0, 42);
                                                                                                })()}
                                                                                            </p>
                                                                                            {readMesCount(item.messages) === 0 ? null : (
                                                                                                <div
                                                                                                    style={{
                                                                                                        marginLeft: "auto",
                                                                                                        borderRadius: "50px",
                                                                                                        backgroundColor: "#0C5CB7",
                                                                                                        color: "white",
                                                                                                        height: "17px",
                                                                                                        width: "17px",
                                                                                                        fontSize: "10px",
                                                                                                        textAlign: "center",
                                                                                                    }}
                                                                                                >
                                                                                                    {readMesCount(item.messages)}
                                                                                                </div>
                                                                                            )}
                                                                                        </>
                                                                                    ) : (
                                                                                        <>
                                                                                            <p style={{ fontWeight: "400" }}>
                                                                                                {item.messages[item.messages.length - 1]?.sender === user._id ? "You: " : item?.participants[0]?.name + ": "}
                                                                                                {(() => {
                                                                                                    const senderName = item.messages[item.messages.length - 1]?.sender === user._id ? "You: " : item?.participants[0]?.name + ": ";
                                                                                                    const messageContent = item.messages[item.messages.length - 1]?.content.replace(/@\[(.*?)\]\(.*?\)/g, "$1") || "";

                                                                                                    const totalLength = senderName.length + messageContent.length;

                                                                                                    if (totalLength > 38) {
                                                                                                        const availableChars = 38 - senderName.length;
                                                                                                        return messageContent.slice(0, availableChars) + "...";
                                                                                                    }
                                                                                                    return messageContent.slice(0, 42);
                                                                                                })()}
                                                                                            </p>
                                                                                        </>
                                                                                    )}
                                                                                </>
                                                                            ) : (
                                                                                <p style={{ fontWeight: "400" }}>...</p>
                                                                            )}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                {/* </div> */}

                                                                <button
                                                                    className="btn status"
                                                                    style={{
                                                                        background: "#eafcff",
                                                                        color: "#333",
                                                                        fontSize: "9px",
                                                                        lineHeight: "1.33333333",
                                                                        fontWeight: "500",
                                                                        borderRadius: "4px",
                                                                        padding: "3px 6px",
                                                                        marginRight: "8px",
                                                                    }}
                                                                >
                                                                    {item.appliedJobId ? <>{item.appliedJobId.status === "Assignment" ? "Assigment Received" : item.appliedJobId.status === "Application_Sent" ? "Application Sent" : item.appliedJobId.status === "NotIntrested" ? "Not Interested" : item.appliedJobId.status}</> : <>General</>}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    ))}
                                            </div>
                                        </div>

                                        {CompanyCandidatename !== "" && (
                                            <div
                                                className="chatroom"
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: "space-between",
                                                    cursor: "default",
                                                }}
                                            >
                                                <div className="sender-area">
                                                    <div
                                                        className="profile "
                                                        style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "space-between",
                                                            width: "100%",
                                                            borderTopRightRadius: "5px",
                                                            cursor: "default",
                                                        }}
                                                    >
                                                        <div>
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                }}
                                                            >
                                                                {CompanyphotoUrl ? (
                                                                    <img
                                                                        src={CompanyphotoUrl}
                                                                        alt="Profile"
                                                                        style={{
                                                                            width: "50px",
                                                                            height: "50px",
                                                                            objectFit: "cover",
                                                                            borderRadius: "50%",
                                                                            // border: "2px solid gray",
                                                                            marginRight: "1rem",
                                                                        }}
                                                                    />
                                                                ) : (
                                                                    <div>
                                                                        <div
                                                                            style={{
                                                                                width: "50px",
                                                                                height: "50px",
                                                                                objectFit: "cover",
                                                                                borderRadius: "50%",
                                                                                border: "2px solid gray",
                                                                                marginRight: "1rem",
                                                                                display: "flex",
                                                                                justifyContent: "center",
                                                                                alignItems: "center",
                                                                                fontSize: "large",
                                                                                fontWeight: "600",
                                                                            }}
                                                                        >
                                                                            {CompanyCandidatename?.charAt(0)}
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                <div>
                                                                    <strong
                                                                        style={{
                                                                            fontWeight: "600",
                                                                            fontSize: "16px",
                                                                            lineHeight: "1.25",
                                                                            textOverflow: "ellipsis",
                                                                            whiteSpace: "nowrap",
                                                                            overflow: "hidden",
                                                                            cursor: "default",
                                                                        }}
                                                                    >
                                                                        {CompanyCandidatename}
                                                                    </strong>

                                                                    <span
                                                                        style={{
                                                                            textOverflow: "ellipsis",
                                                                            whiteSpace: "nowrap",
                                                                            overflow: "hidden",
                                                                            lineHeight: "1.14285714",
                                                                            color: "#484848",
                                                                            fontSize: "14px",
                                                                            fontWeight: "400",
                                                                            display: "block",
                                                                            // marginTop: "7px",
                                                                            cursor: "default",
                                                                        }}
                                                                    >
                                                                        {allRooms.find((item) => item.roomId === chatActive) && allRooms.find((item) => item.roomId === chatActive).jobId && allRooms.find((item) => item.roomId === chatActive).jobId.profile ? allRooms.find((item) => item.roomId === chatActive).jobId.profile : ""} {companyName && <>| Chatting with - {companyName}</>}
                                                                    </span>
                                                                    {company?.city && (
                                                                        <span
                                                                            style={{
                                                                                textOverflow: "ellipsis",
                                                                                whiteSpace: "nowrap",
                                                                                overflow: "hidden",
                                                                                lineHeight: "1.14285714",
                                                                                color: "#484848",
                                                                                fontSize: "14px",
                                                                                fontWeight: "400",
                                                                                display: "flex",
                                                                                alignItems: "flex-start",
                                                                                cursor: "default",
                                                                            }}
                                                                        >
                                                                            <IoLocation />
                                                                            {company?.city}
                                                                        </span>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {allRooms?.find((item) => item.roomId === chatActive).appliedJobId ? (
                                                            <>
                                                                <div style={{ cursor: "pointer" }}>
                                                                    <Dropdown props={allRooms?.find((item) => item.roomId === chatActive)} />
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="message-section my-0 py-0">
                                                    <div className="conversations" style={{ display: "flex", flexDirection: "column" }}>
                                                        <>
                                                            {history.messages && history.messages.length > 0 ? (
                                                                <div ref={scroll}>
                                                                    {history?.messages?.map((items, index) => {
                                                                        // focusInputBox();
                                                                        let currentDate = new Date(items.timestamp);

                                                                        // Track the last displayed date
                                                                        // let displayDate = false;

                                                                        // if (!window.lastDisplayedDate || currentDate.toDateString() !== window.lastDisplayedDate.toDateString()) {
                                                                        //     displayDate = true;
                                                                        //     window.lastDisplayedDate = currentDate;
                                                                        // }

                                                                        const prevMessage = index > 0 ? history?.messages[index - 1] : null;

                                                                        // Calculate time difference (in seconds) if there is a previous message
                                                                        const timeDifferenceInSeconds = prevMessage ? (new Date(items.timestamp) - new Date(prevMessage.timestamp)) / 1000 : Number.MAX_VALUE;

                                                                        // Determine whether to show profile, name, and timestamp
                                                                        const showNameAndImage =
                                                                            index === 0 || // Always show for the first message in the sliced array
                                                                            (prevMessage && items.sender !== prevMessage?.sender) || // Show if sender changes
                                                                            (prevMessage && timeDifferenceInSeconds > 60);

                                                                        return (
                                                                            <div key={index}>
                                                                                {/* Display timestamp only if the date has changed */}
                                                                                {index > 0 ? (
                                                                                    new Date(history.messages[index - 1].timestamp).toLocaleDateString() !== new Date(items.timestamp).toLocaleDateString() && (
                                                                                        <div
                                                                                            className="timestamp text-center"
                                                                                            style={{
                                                                                                fontSize: "14px",
                                                                                                padding: "10px 0",
                                                                                                textAlign: "center",
                                                                                                clear: "both",
                                                                                                display: "block",
                                                                                            }}
                                                                                        >
                                                                                            {isToday(new Date(items.timestamp)) ? (
                                                                                                <p
                                                                                                    style={{
                                                                                                        fontSize: "14px",
                                                                                                        padding: "10px 0",
                                                                                                        textAlign: "center",
                                                                                                        clear: "both",
                                                                                                    }}
                                                                                                >
                                                                                                    Today
                                                                                                </p>
                                                                                            ) : (
                                                                                                <p
                                                                                                    style={{
                                                                                                        fontSize: "14px",
                                                                                                        padding: "10px 0",
                                                                                                        textAlign: "center",
                                                                                                        clear: "both",
                                                                                                    }}
                                                                                                >
                                                                                                    {new Date(items.timestamp).toLocaleDateString("en-GB", {
                                                                                                        day: "2-digit",
                                                                                                        month: "long",
                                                                                                    })}
                                                                                                </p>
                                                                                            )}
                                                                                        </div>
                                                                                    )
                                                                                ) : (
                                                                                    <div
                                                                                        className="timestamp text-center"
                                                                                        style={{
                                                                                            fontSize: "14px",
                                                                                            padding: "10px 0",
                                                                                            textAlign: "center",
                                                                                            clear: "both",
                                                                                            display: "block",
                                                                                        }}
                                                                                    >
                                                                                        {isToday(new Date(items.timestamp)) ? (
                                                                                            "Today"
                                                                                        ) : (
                                                                                            <div
                                                                                                style={{
                                                                                                    fontSize: "14px",
                                                                                                    padding: "10px 0",
                                                                                                    textAlign: "center",
                                                                                                    clear: "both",
                                                                                                }}
                                                                                            >
                                                                                                {new Date(items.timestamp).toLocaleDateString("en-GB", {
                                                                                                    day: "2-digit",
                                                                                                    month: "long",
                                                                                                })}
                                                                                            </div>
                                                                                        )}
                                                                                    </div>
                                                                                )}

                                                                                <div className="hoveredMessage" style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start", flexDirection: "column" }}>
                                                                                    {showNameAndImage && (
                                                                                        <div style={{ display: "flex", alignItems: "center", justifyContent: `${items.sender === user._id ? "flex-end" : "space-between"}`, width: "100%" }}>
                                                                                            <div style={{ padding: "0px 5px", display: "flex", justifyContent: `${items.sender === user._id ? "flex-start" : "flex-end"}`, paddingRight: "40px" }}>
                                                                                                {/* Display your name when you send a message */}
                                                                                                <span style={{ fontSize: "12px" }}>{items.sender !== user._id ? CandidateName + ", " : null}</span>
                                                                                                &nbsp;
                                                                                                {items.sender === user._id ? <div style={{ color: "#888", fontSize: "12px" }}>{getTime(items.timestamp).toUpperCase()}</div> : <div style={{ color: "#888", fontSize: "12px" }}>{getTime(items.timestamp).toUpperCase()}</div>}
                                                                                            </div>
                                                                                        </div>
                                                                                    )}

                                                                                    <div className="messageWrapper" style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "flex-start" }}>
                                                                                        <div className={` ${items.sender === user._id ? "right" : "left"}`} style={{ backgroundColor: "none" }}>
                                                                                            <div style={{ backgroundColor: "none" }}>
                                                                                                {/* <p>{items.content}</p> */}

                                                                                                <div style={{ padding: "0px 5px", overflowWrap: "break-word", wordWrap: "break-word", wordBreak: "break-word", hyphens: "auto", fontSize: "14px", lineHeight: "1.5", display: "flex", justifyContent: "space-between", alignItems: "start" }}>
                                                                                                    {items.replyTo === null ? (
                                                                                                        <>
                                                                                                            <span style={{ width: "92%" }}>
                                                                                                                <span
                                                                                                                    dangerouslySetInnerHTML={{
                                                                                                                        __html: wrapLinks(
                                                                                                                            (items.content || "")
                                                                                                                                .trim()
                                                                                                                                .replace(/@\[(.*?)\]\(.*?\)/g, "$1")
                                                                                                                                .replace(/\n/g, " <br />")
                                                                                                                        ),
                                                                                                                    }}
                                                                                                                ></span>

                                                                                                                <span style={{ marginLeft: "5px", fontSize: "10px" }}>{items.edited && "(Edited)"}</span>
                                                                                                            </span>
                                                                                                        </>
                                                                                                    ) : (
                                                                                                        <div className="replyMsgBox">
                                                                                                            <div className="innerReplyMsgBox">
                                                                                                                <img src={replyArrow} alt="" className="replyIcon" />
                                                                                                                {(() => {
                                                                                                                    // Find the replied message
                                                                                                                    const conversation = history?.messages; // Assuming `history` contains the current conversation
                                                                                                                    const replyMsg = items?.replyTo;

                                                                                                                    const repliedMessage = conversation?.find((msg) => msg._id === replyMsg);

                                                                                                                    if (!repliedMessage) {
                                                                                                                        return <span style={{ padding: "0px 5px", fontSize: "14px" }}>Message not found</span>;
                                                                                                                    }

                                                                                                                    // Determine the name to display
                                                                                                                    const nameToDisplay = repliedMessage.sender === user?._id ? user?.name : receriverData?.name;

                                                                                                                    return (
                                                                                                                        <>
                                                                                                                            <div
                                                                                                                                style={{
                                                                                                                                    padding: "0px 5px",
                                                                                                                                    overflowWrap: "break-word",
                                                                                                                                    wordWrap: "break-word",
                                                                                                                                    wordBreak: "break-word",
                                                                                                                                    hyphens: "auto",
                                                                                                                                    fontSize: "14px",
                                                                                                                                    lineHeight: "1.5",
                                                                                                                                    display: "flex",
                                                                                                                                    justifyContent: "space-between",
                                                                                                                                    alignItems: "center",
                                                                                                                                }}
                                                                                                                            >
                                                                                                                                {/* Display the replied message */}
                                                                                                                                <span
                                                                                                                                    style={{ fontSize: "14px" }}
                                                                                                                                    dangerouslySetInnerHTML={{
                                                                                                                                        __html: wrapLinks(
                                                                                                                                            (repliedMessage?.content || "")
                                                                                                                                                .trim()
                                                                                                                                                .replace(/@\[(.*?)\]\(.*?\)/g, "$1")
                                                                                                                                                .replace(/\n/g, " <br />")
                                                                                                                                        ),
                                                                                                                                    }}
                                                                                                                                ></span>
                                                                                                                            </div>
                                                                                                                            {/* Display sender/receiver name and timestamp */}
                                                                                                                            <span style={{ fontSize: "14px", padding: "0px 5px" }}>
                                                                                                                                {nameToDisplay},{" "}
                                                                                                                                {new Date(repliedMessage.timestamp).toLocaleString("en-US", {
                                                                                                                                    weekday: "short",
                                                                                                                                    hour: "2-digit",
                                                                                                                                    minute: "2-digit",
                                                                                                                                })}
                                                                                                                            </span>
                                                                                                                        </>
                                                                                                                    );
                                                                                                                })()}
                                                                                                            </div>
                                                                                                            <span style={{ width: "92%" }}>
                                                                                                                <span
                                                                                                                    dangerouslySetInnerHTML={{
                                                                                                                        __html: wrapLinks(
                                                                                                                            (items.content || "")
                                                                                                                                .trim()
                                                                                                                                .replace(/@\[(.*?)\]\(.*?\)/g, "$1")
                                                                                                                                .replace(/\n/g, " <br />")
                                                                                                                        ),
                                                                                                                    }}
                                                                                                                ></span>
                                                                                                                <span style={{ marginLeft: "5px", fontSize: "10px" }}>{items.edited && "(Edited)"}</span>
                                                                                                            </span>
                                                                                                        </div>
                                                                                                    )}
                                                                                                </div>
                                                                                            </div>
                                                                                            <div style={{ textAlign: "end" }}>{items.sender === user._id ? <PiChecks style={{ fontSize: "16px" }} /> : ""}</div>
                                                                                        </div>

                                                                                        <div className="hiddenMessageMenu">
                                                                                            <MessageOptionsModal senderId={items.sender} receiverId={items.receiver} conversationId={conversationId} messageId={items._id} handleRemoveMessage={handleRemoveMessage} allData={items} wholeData={history} handleEditMessage={handleEditMessage} setEditFlag={setEditFlag} setchatMessage={setchatMessage} setEditMessageId={setEditMessageId} setEditConversationId={setEditConversationId} setDeleteMessage={setDeleteMessage} setReplyMessage={setReplyMessage} setDeleteFlag={setDeleteFlag} setReplyFlag={setReplyFlag} user={user} />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </div>
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </>
                                                    </div>

                                                    <div className="send-message-box" ref={parentDivRef}>
                                                        <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", borderRadius: "3px", maxHeight: "250px", overflowY: "auto", gap: "1rem" }}>
                                                            {/* display the replied msg box   */}
                                                            <div className="replyMsgBox" style={{ display: replyFlag ? "block" : "none" }}>
                                                                {replyMessage.conversationId && replyMessage.parentMessageId ? (
                                                                    <div className="innerReplyMsgBox">
                                                                        <img src={replyArrow} alt="" className="replyIcon" />
                                                                        <div
                                                                            className="cross"
                                                                            onClick={() => {
                                                                                setReplyFlag(false);
                                                                                setchatMessage("");
                                                                                setReplyFlag(false);
                                                                            }}
                                                                        >
                                                                            <img src={cross} alt="" className="crossIcon" />
                                                                        </div>
                                                                        {(() => {
                                                                            // Find the replied message
                                                                            const conversation = history?.messages;
                                                                            const repliedMessage = conversation?.find((msg) => msg._id === replyMessage.parentMessageId);

                                                                            if (!repliedMessage) {
                                                                                return <span style={{ padding: "0px 5px", fontSize: "14px" }}>Message not found</span>;
                                                                            }

                                                                            return (
                                                                                <>
                                                                                    <div
                                                                                        style={{
                                                                                            padding: "0px 5px",
                                                                                            overflowWrap: "break-word",
                                                                                            wordWrap: "break-word",
                                                                                            wordBreak: "break-word",
                                                                                            hyphens: "auto",
                                                                                            fontSize: "14px",
                                                                                            lineHeight: "1.5",
                                                                                            display: "flex",
                                                                                            justifyContent: "space-between",
                                                                                            alignItems: "center",
                                                                                        }}
                                                                                    >
                                                                                        {/* Display the replied message */}
                                                                                        <span
                                                                                            style={{ fontSize: "14px", width: "90%" }}
                                                                                            dangerouslySetInnerHTML={{
                                                                                                __html: wrapLinks(
                                                                                                    (repliedMessage.content || "")
                                                                                                        .trim()
                                                                                                        .replace(/@\[(.*?)\]\(.*?\)/g, "$1")
                                                                                                        .replace(/\n/g, " <br />")
                                                                                                ),
                                                                                            }}
                                                                                        ></span>
                                                                                    </div>
                                                                                    {/* Display sender name and timestamp */}
                                                                                    <span style={{ fontSize: "14px", padding: "0px 5px" }}>
                                                                                        {repliedMessage.sender === user?._id ? user?.name : receriverData?.name},{" "}
                                                                                        {new Date(repliedMessage.timestamp).toLocaleString("en-US", {
                                                                                            weekday: "short",
                                                                                            hour: "2-digit",
                                                                                            minute: "2-digit",
                                                                                        })}
                                                                                    </span>
                                                                                </>
                                                                            );
                                                                        })()}
                                                                    </div>
                                                                ) : (
                                                                    <span style={{ padding: "0px 5px", fontSize: "14px" }}>No reply message selected</span>
                                                                )}
                                                            </div>

                                                            <div ref={scroll} style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", gap: "1rem" }}>
                                                                <textarea
                                                                    ref={textareaRef}
                                                                    value={chatMessage}
                                                                    onChange={handleChange}
                                                                    onKeyDown={handleKeyDown}
                                                                    maxLength={10000}
                                                                    placeholder="Write a message...."
                                                                    style={{
                                                                        fontSize: "15px",
                                                                        lineHeight: "22px",
                                                                        height: "45px",
                                                                        padding: "8px 31px 5px 11px",
                                                                        width: "100%",
                                                                        outline: "none",
                                                                        borderRadius: "5px",
                                                                        border: "solid 1px #d6d6d6",
                                                                        resize: "none",
                                                                        overflowY: "auto",
                                                                        maxHeight: "250px",
                                                                    }}
                                                                />
                                                                <button
                                                                    type="button"
                                                                    onClick={() => {
                                                                        if (editFlag) {
                                                                            // Editing an existing message
                                                                            handleEditMessage(chatMessage, editConversationId, editMessageId);
                                                                            setEditFlag(false);
                                                                            setchatMessage("");
                                                                        } else if (replyFlag) {
                                                                            handleReplyMessage(chatMessage, replyMessage?.conversationId, replyMessage?.parentMessageId, replyMessage?.senderId, replyMessage?.receiverId);
                                                                            setReplyFlag(false);
                                                                            setchatMessage("");
                                                                        } else {
                                                                            // Sending a new message
                                                                            const recipientId = history?.participants[0] === user?._id ? history?.participants[1] : history?.participants[0];

                                                                            sendMessage(recipientId, history?.jobId);
                                                                            setchatMessage("");
                                                                        }
                                                                    }}
                                                                    className="btn btnsend"
                                                                    style={{
                                                                        backgroundColor: chatMessage?.length > 0 ? "#5db2f7" : "#bbb",
                                                                    }}
                                                                    disabled={chatMessage?.length === 0}
                                                                >
                                                                    {editFlag ? "Send" : replyFlag ? "Reply" : "Send"}{" "}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <div className="recent-chats" style={{ width: "35%" }}>
                                            <div className="chats text-center" style={{ width: "100%" }}>
                                                <RiChatOffLine className="mx-auto mt-5" style={{ fontSize: "50px", color: "#5db2f7" }} />
                                                <p
                                                    style={{
                                                        fontSize: "20px",
                                                        fontWeight: "400",
                                                        color: "gray",
                                                    }}
                                                    className="mt-2 text-center"
                                                >
                                                    No chat available!!
                                                </p>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
            <DeleteMessageModal open={open} setOpen={setOpen} setDeleteConfirm={setDeleteConfirm} />

            {/************************MOBILE VIEW*****************************/}

            <div className="ChatMobView">
                <div
                    className="message-student  pb-0"
                    style={{
                        backgroundColor: "#F8F8F8",
                        borderRadius: "5px",
                        padding: "40px 10px",
                        maxWidth: "1200px",
                        margin: "0 auto",
                    }}
                >
                    <div className="chat-box1">
                        {loader ? (
                            <>
                                <div className="recent-chats" style={{ width: "100%" }}>
                                    <div className="chats text-center" style={{ width: "100%" }}>
                                        <div
                                            className="spinner-border "
                                            role="status"
                                            style={{
                                                width: "27px",
                                                height: "27px",
                                                marginTop: "20px",
                                                color: "#5db2f7",
                                            }}
                                        ></div>
                                        <p
                                            style={{
                                                fontSize: "18px",
                                                fontWeight: "400",
                                                color: "gray",
                                            }}
                                            className=" text-center"
                                        >
                                            Loading...
                                        </p>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                {viewMessage ? (
                                    <>
                                        {CompanyCandidatename !== "" && (
                                            <div
                                                className="chatroom"
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    // justifyContent: "space-between",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        backgroundColor: "white",
                                                        marginBottom: "-10px",
                                                        color: "#0077e4",
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={() => setViewMesage(false)}
                                                >
                                                    <p className="mt-1  ml-3" style={{ fontSize: "14px" }}>
                                                        {" "}
                                                        <MdKeyboardBackspace className="ml-1 mb-1" style={{ fontSize: "15px" }} /> Back
                                                    </p>
                                                </div>
                                                <div className="sender-area">
                                                    <div
                                                        className="profile "
                                                        style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "space-between",
                                                            width: "100%",
                                                            borderTopRightRadius: "5px",
                                                        }}
                                                    >
                                                        <div>
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                }}
                                                            >
                                                                {CompanyphotoUrl ? (
                                                                    <img
                                                                        src={CompanyphotoUrl}
                                                                        alt="Profile"
                                                                        style={{
                                                                            width: "50px",
                                                                            height: "50px",
                                                                            objectFit: "cover",
                                                                            borderRadius: "50%",
                                                                            // border: "2px solid gray",
                                                                            marginRight: "1rem",
                                                                        }}
                                                                        // className="phototodisplayindesktop"
                                                                        // style={{
                                                                        //   boxShadow: "0 0 5px rgba(0,0,0,0.2)",
                                                                        //   border: "4px solid white",
                                                                        // }}
                                                                    />
                                                                ) : (
                                                                    <div>
                                                                        <div
                                                                            style={{
                                                                                width: "50px",
                                                                                height: "50px",
                                                                                objectFit: "cover",
                                                                                borderRadius: "50%",
                                                                                border: "2px solid gray",
                                                                                marginRight: "1rem",
                                                                                display: "flex",
                                                                                justifyContent: "center",
                                                                                alignItems: "center",
                                                                                fontSize: "large",
                                                                                fontWeight: "600",
                                                                            }}
                                                                        >
                                                                            {CompanyCandidatename.charAt(0)}
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                <div>
                                                                    <strong
                                                                        style={{
                                                                            fontWeight: "600",
                                                                            fontSize: "16px",
                                                                            lineHeight: "1.25",
                                                                            textOverflow: "ellipsis",
                                                                            whiteSpace: "nowrap",
                                                                            overflow: "hidden",
                                                                        }}
                                                                    >
                                                                        {CompanyCandidatename}
                                                                    </strong>

                                                                    <Tooltip title={`${allRooms.find((item) => item.roomId === chatActive)?.jobId?.profile || ""}${companyName ? ` | Chatting with - ${companyName}` : ""}`} arrow>
                                                                        <span
                                                                            style={{
                                                                                textOverflow: "ellipsis",
                                                                                whiteSpace: "nowrap",
                                                                                overflow: "hidden",
                                                                                lineHeight: "1.14285714",
                                                                                color: "#484848",
                                                                                fontSize: "14px",
                                                                                fontWeight: "400",
                                                                                display: "block",
                                                                                marginTop: "7px",
                                                                            }}
                                                                        >
                                                                            {(() => {
                                                                                const profile = allRooms.find((item) => item.roomId === chatActive)?.jobId?.profile || "";
                                                                                const companyNameDisplay = companyName ? ` | Chatting with - ${companyName}` : "";
                                                                                const maxChars = 28;

                                                                                // Combine profile and companyName
                                                                                const combined = `${profile}${companyNameDisplay}`;

                                                                                // Truncate the combined string to fit within 28 characters
                                                                                return combined.length > maxChars ? combined.slice(0, maxChars - 3) + "..." : combined;
                                                                            })()}
                                                                        </span>
                                                                    </Tooltip>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {allRooms.find((item) => item.roomId === chatActive).appliedJobId ? (
                                                            <>
                                                                <div style={{}}>
                                                                    <Dropdown props={allRooms.find((item) => item.roomId === chatActive)} />
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="message-section my-0 py-0">
                                                    <div className="conversations py-0 my-0">
                                                        {history.messages && history.messages.length > 0 ? (
                                                            <div ref={scroll}>
                                                                <>
                                                                    {history?.messages?.map((items, index) => {
                                                                        focusInputBox();
                                                                        let currentDate = new Date(items.timestamp);

                                                                        // Track the last displayed date
                                                                        let displayDate = false;

                                                                        if (!window.lastDisplayedDate || currentDate.toDateString() !== window.lastDisplayedDate.toDateString()) {
                                                                            displayDate = true;
                                                                            window.lastDisplayedDate = currentDate;
                                                                        }
                                                                        const prevMessage = index > 0 ? history?.messages[index - 1] : null;

                                                                        // Calculate time difference (in seconds) if there is a previous message
                                                                        const timeDifferenceInSeconds = prevMessage ? (new Date(items.timestamp) - new Date(prevMessage.timestamp)) / 1000 : Number.MAX_VALUE;

                                                                        // Determine whether to show profile, name, and timestamp
                                                                        const showNameAndImage =
                                                                            index === 0 || // Always show for the first message in the sliced array
                                                                            (prevMessage && items.sender !== prevMessage?.sender) || // Show if sender changes
                                                                            (prevMessage && timeDifferenceInSeconds > 60);
                                                                        // Calculate time difference between current and previous messages (in minutes)

                                                                        return (
                                                                            <div key={index}>
                                                                                {/* Display timestamp only if the date has changed */}
                                                                                {index > 0 ? (
                                                                                    new Date(history.messages[index - 1].timestamp).toLocaleDateString() !== new Date(items.timestamp).toLocaleDateString() && (
                                                                                        <div
                                                                                            className="timestamp text-center"
                                                                                            style={{
                                                                                                fontSize: "14px",
                                                                                                padding: "10px 0",
                                                                                                textAlign: "center",
                                                                                                clear: "both",
                                                                                                display: "block",
                                                                                            }}
                                                                                        >
                                                                                            {isToday(new Date(items.timestamp)) ? (
                                                                                                <p
                                                                                                    style={{
                                                                                                        fontSize: "14px",
                                                                                                        padding: "10px 0",
                                                                                                        textAlign: "center",
                                                                                                        clear: "both",
                                                                                                    }}
                                                                                                >
                                                                                                    Today
                                                                                                </p>
                                                                                            ) : (
                                                                                                <p
                                                                                                    style={{
                                                                                                        fontSize: "14px",
                                                                                                        padding: "10px 0",
                                                                                                        textAlign: "center",
                                                                                                        clear: "both",
                                                                                                    }}
                                                                                                >
                                                                                                    {new Date(items.timestamp).toLocaleDateString("en-GB", {
                                                                                                        day: "2-digit",
                                                                                                        month: "long",
                                                                                                    })}
                                                                                                </p>
                                                                                            )}
                                                                                        </div>
                                                                                    )
                                                                                ) : (
                                                                                    <div
                                                                                        className="timestamp text-center"
                                                                                        style={{
                                                                                            fontSize: "14px",
                                                                                            padding: "10px 0",
                                                                                            textAlign: "center",
                                                                                            clear: "both",
                                                                                            display: "block",
                                                                                        }}
                                                                                    >
                                                                                        {isToday(new Date(items.timestamp)) ? (
                                                                                            "Today"
                                                                                        ) : (
                                                                                            <div
                                                                                                style={{
                                                                                                    fontSize: "14px",
                                                                                                    padding: "10px 0",
                                                                                                    textAlign: "center",
                                                                                                    clear: "both",
                                                                                                }}
                                                                                            >
                                                                                                {new Date(items.timestamp).toLocaleDateString("en-GB", {
                                                                                                    day: "2-digit",
                                                                                                    month: "long",
                                                                                                })}
                                                                                            </div>
                                                                                        )}
                                                                                    </div>
                                                                                )}

                                                                                <div className="hoveredMessage" style={{ display: "flex" }}>
                                                                                    <div className={` ${items.sender === user._id ? "right" : "left"}`} style={{ backgroundColor: "none" }}>
                                                                                    {showNameAndImage && (

                                                                                        <div style={{ display: "flex", alignItems: "center", justifyContent: `${items.sender === user._id ? "flex-end" : "space-between"}` }}>
                                                                                            <div style={{ padding: "0px 5px", display: "flex" }}>
                                                                                                <span style={{ fontSize: "11px" }}>{items.sender !== user._id ? CandidateName + ", " : null}</span>
                                                                                                &nbsp;
                                                                                                {items.sender === user._id ? <div style={{ color: "#888", fontSize: "11px" }}>{getTime(items.timestamp).toUpperCase()}</div> : <div style={{ color: "#888", fontSize: "11px" }}>{getTime(items.timestamp).toUpperCase()}</div>}
                                                                                            </div>
                                                                                            </div>
                                                                                        )}
                                                                                        <div style={{ padding: "0px 5px", overflowWrap: "break-word", wordWrap: "break-word", wordBreak: "break-word", hyphens: "auto", fontSize: "14px", lineHeight: "1.5", display: "flex", justifyContent: "space-between", alignItems: "start" }}>
                                                                                            {items.replyTo === null ? (
                                                                                                <span>
                                                                                                    <span
                                                                                                        dangerouslySetInnerHTML={{
                                                                                                            __html: wrapLinks(
                                                                                                                (items.content || "")
                                                                                                                    .trim()
                                                                                                                    .replace(/@\[(.*?)\]\(.*?\)/g, "$1")
                                                                                                                    .replace(/\n/g, " <br />")
                                                                                                            ),
                                                                                                        }}
                                                                                                    ></span>
                                                                                                    <span style={{ marginLeft: "5px", fontSize: "10px" }}>{items.edited && "(Edited)"}</span>
                                                                                                </span>
                                                                                            ) : (
                                                                                                <div className="replyMsgBox">
                                                                                                    <div className="innerReplyMsgBox">
                                                                                                        <img src={replyArrow} alt="" className="replyIcon" />
                                                                                                        {(() => {
                                                                                                            // Find the replied message
                                                                                                            const conversation = history?.messages; // Assuming `history` contains the current conversation
                                                                                                            const replyMsg = items?.replyTo;

                                                                                                            const repliedMessage = conversation?.find((msg) => msg._id === replyMsg);

                                                                                                            if (!repliedMessage) {
                                                                                                                return <span style={{ padding: "0px 5px", fontSize: "14px" }}>Message not found</span>;
                                                                                                            }

                                                                                                            // Determine the name to display
                                                                                                            const receriverNameForPhone = allRooms.find((data) => data.roomId === conversationId)?.participants[1];
                                                                                                            const nameToDisplay = repliedMessage.sender === user?._id ? user?.name : receriverData?.name ? receriverData?.name : receriverNameForPhone?.name;

                                                                                                            return (
                                                                                                                <>
                                                                                                                    <div
                                                                                                                        style={{
                                                                                                                            padding: "0px 5px",
                                                                                                                            overflowWrap: "break-word",
                                                                                                                            wordWrap: "break-word",
                                                                                                                            wordBreak: "break-word",
                                                                                                                            hyphens: "auto",
                                                                                                                            fontSize: "14px",
                                                                                                                            lineHeight: "1.5",
                                                                                                                            display: "flex",
                                                                                                                            justifyContent: "space-between",
                                                                                                                            alignItems: "center",
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        {/* Display the replied message */}
                                                                                                                        <span
                                                                                                                            style={{ fontSize: "14px" }}
                                                                                                                            dangerouslySetInnerHTML={{
                                                                                                                                __html: wrapLinks(
                                                                                                                                    (repliedMessage?.content || "")
                                                                                                                                        .trim()
                                                                                                                                        .replace(/@\[(.*?)\]\(.*?\)/g, "$1")
                                                                                                                                        .replace(/\n/g, " <br />")
                                                                                                                                ),
                                                                                                                            }}
                                                                                                                        ></span>
                                                                                                                    </div>
                                                                                                                    {/* Display sender/receiver name and timestamp */}
                                                                                                                    <span style={{ fontSize: "14px", padding: "0px 5px" }}>
                                                                                                                        {nameToDisplay},{" "}
                                                                                                                        {new Date(repliedMessage.timestamp).toLocaleString("en-US", {
                                                                                                                            weekday: "short",
                                                                                                                            hour: "2-digit",
                                                                                                                            minute: "2-digit",
                                                                                                                        })}
                                                                                                                    </span>
                                                                                                                </>
                                                                                                            );
                                                                                                        })()}
                                                                                                    </div>
                                                                                                    <span>
                                                                                                        <span
                                                                                                            dangerouslySetInnerHTML={{
                                                                                                                __html: wrapLinks(
                                                                                                                    (items.content || "")
                                                                                                                        .trim()
                                                                                                                        .replace(/@\[(.*?)\]\(.*?\)/g, "$1")
                                                                                                                        .replace(/\n/g, " <br />")
                                                                                                                ),
                                                                                                            }}
                                                                                                        ></span>
                                                                                                        <span style={{ marginLeft: "5px", fontSize: "10px" }}>{items.edited && "(Edited)"}</span>
                                                                                                    </span>
                                                                                                </div>
                                                                                            )}
                                                                                        </div>
                                                                                        <div style={{ textAlign: "end" }}>{items.sender === user._id ? <PiChecks style={{ fontSize: "16px" }} /> : ""}</div>
                                                                                    </div>
                                                                                    <MessageOptionsModal senderId={items.sender} receiverId={items.receiver} conversationId={conversationId} messageId={items._id} handleRemoveMessage={handleRemoveMessage} allData={items} wholeData={history} handleEditMessage={handleEditMessage} setEditFlag={setEditFlag} setchatMessage={setchatMessage} setEditMessageId={setEditMessageId} setEditConversationId={setEditConversationId} setDeleteMessage={setDeleteMessage} setReplyMessage={setReplyMessage} setDeleteFlag={setDeleteFlag} setReplyFlag={setReplyFlag} user={user} />
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </>
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </div>

                                                    <div className="send-message-box" style={{ maxHeight: "250px" }} ref={parentDivRef}>
                                                        <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", borderRadius: "3px", paddingRight: "10px", maxHeight: "250px", overflowY: "auto" }}>
                                                            <div className="replyMsgBox" style={{ display: replyFlag ? "block" : "none" }}>
                                                                {replyMessage?.conversationId && replyMessage?.parentMessageId ? (
                                                                    <div className="innerReplyMsgBox">
                                                                        <img src={replyArrow} alt="" className="replyIcon" />
                                                                        <div
                                                                            className="cross"
                                                                            onClick={() => {
                                                                                setReplyFlag(false);
                                                                                setchatMessage("");
                                                                                setReplyFlag(false);
                                                                            }}
                                                                        >
                                                                            <img src={cross} alt="" className="crossIcon" />
                                                                        </div>
                                                                        {(() => {
                                                                            // Find the replied message
                                                                            const conversation = history?.messages; // Assuming `history` contains the current conversation
                                                                            const repliedMessage = conversation?.find((msg) => msg._id === replyMessage.parentMessageId);
                                                                            const receriverNameForPhone = allRooms.find((data) => data.roomId === conversationId)?.participants[1];

                                                                            // const newData = allRooms.find((data) => data?._id )
                                                                            if (!repliedMessage) {
                                                                                return <span style={{ padding: "0px 5px", fontSize: "14px" }}>Message not found</span>;
                                                                            }

                                                                            return (
                                                                                <>
                                                                                    <div
                                                                                        style={{
                                                                                            padding: "0px 5px",
                                                                                            overflowWrap: "break-word",
                                                                                            wordWrap: "break-word",
                                                                                            wordBreak: "break-word",
                                                                                            hyphens: "auto",
                                                                                            fontSize: "14px",
                                                                                            lineHeight: "1.5",
                                                                                            display: "flex",
                                                                                            justifyContent: "space-between",
                                                                                            alignItems: "center",
                                                                                        }}
                                                                                    >
                                                                                        {/* Display the replied message */}
                                                                                        <span
                                                                                            style={{ fontSize: "14px" }}
                                                                                            dangerouslySetInnerHTML={{
                                                                                                __html: wrapLinks(
                                                                                                    (repliedMessage.content || "")
                                                                                                        .trim()
                                                                                                        .replace(/@\[(.*?)\]\(.*?\)/g, "$1")
                                                                                                        .replace(/\n/g, " <br />")
                                                                                                ),
                                                                                            }}
                                                                                        ></span>
                                                                                    </div>
                                                                                    {/* Display sender name and timestamp */}
                                                                                    <span style={{ fontSize: "14px", padding: "0px 5px" }}>
                                                                                        {repliedMessage.sender === user?._id ? user?.name : receriverData?.name ? receriverData?.name : receriverNameForPhone?.name},{" "}
                                                                                        {new Date(repliedMessage.timestamp).toLocaleString("en-US", {
                                                                                            weekday: "short",
                                                                                            hour: "2-digit",
                                                                                            minute: "2-digit",
                                                                                        })}
                                                                                    </span>
                                                                                </>
                                                                            );
                                                                        })()}
                                                                    </div>
                                                                ) : (
                                                                    <span style={{ padding: "0px 5px", fontSize: "14px" }}>No reply message selected</span>
                                                                )}
                                                            </div>
                                                            <div ref={scroll} style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", gap: "1rem" }}>
                                                                <textarea
                                                                    ref={textareaRef}
                                                                    value={chatMessage}
                                                                    onChange={handleChange}
                                                                    onKeyDown={handleKeyDown}
                                                                    maxLength={10000}
                                                                    placeholder="Write a message...."
                                                                    style={{
                                                                        fontSize: "15px",
                                                                        lineHeight: "22px", // Slightly larger than font size for partial visibility
                                                                        height: "43px",
                                                                        padding: "8px 31px 8px 11px",
                                                                        width: "100%",
                                                                        outline: "none",
                                                                        borderRadius: "5px",
                                                                        border: "solid 1px #d6d6d6",
                                                                        resize: "none",
                                                                        overflowY: "auto",
                                                                        maxHeight: "250px", // Enforces max height for scrolling
                                                                    }}
                                                                />
                                                                <button
                                                                    type="button"
                                                                    onClick={() => {
                                                                        if (editFlag) {
                                                                            // Editing an existing message

                                                                            handleEditMessage(chatMessage, editConversationId, editMessageId);
                                                                            setEditFlag(false);
                                                                            setchatMessage("");
                                                                        } else if (replyFlag) {
                                                                            handleReplyMessage(chatMessage, replyMessage?.conversationId, replyMessage?.parentMessageId, replyMessage?.senderId, replyMessage?.receiverId);
                                                                            setReplyFlag(false);
                                                                            setchatMessage("");
                                                                        } else {
                                                                            // Sending a new message
                                                                            const recipientId = history?.participants[0] === user?._id ? history?.participants[1] : history?.participants[0];

                                                                            sendMessage(recipientId, history?.jobId);
                                                                            setchatMessage("");
                                                                        }
                                                                    }}
                                                                    className="btn btnsend"
                                                                    style={{
                                                                        backgroundColor: chatMessage?.length > 0 ? "#5db2f7" : "#bbb",
                                                                    }}
                                                                    disabled={chatMessage?.length === 0}
                                                                >
                                                                    {editFlag ? "Send" : replyFlag ? "Reply" : "Send"}{" "}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                width: "100%",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    padding: "10px 0 0 0",
                                                    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.12)",
                                                    display: "flex",
                                                    borderTopLeftRadius: "6px",
                                                    // borderBottomLeftRadius: "6px", // This is commented out
                                                    flexDirection: "column",
                                                    alignItems: "center",
                                                    borderRight: "1px solid #f0eeee",
                                                    borderBottom: "1px solid rgb(224, 224, 224)",
                                                }}
                                            >
                                                <div style={{ width: "100%" }}>
                                                    <div style={{ margin: "0px auto" }}>
                                                        <div
                                                            style={{
                                                                fontSize: "xx-large",
                                                                fontWeight: "500",
                                                                margin: "1rem 2rem",
                                                            }}
                                                        >
                                                            Messages
                                                        </div>

                                                        <div
                                                            className="sliderSearch"
                                                            style={{
                                                                display: "flex",
                                                                border: "1px solid #ddd",
                                                                marginTop: "20px",
                                                                width: "90%",
                                                                margin: "0 auto 16px",
                                                                borderRadius: "3px",
                                                                padding: "7px 11px",
                                                                alignItems: "center",
                                                                position: "relative",
                                                                cursor: "text",
                                                                backgroundColor: "#fff",
                                                            }}
                                                        >
                                                            <LuSearch
                                                                style={{
                                                                    fontSize: "24px",
                                                                    color: "#484848",
                                                                    fontWeight: "400",
                                                                }}
                                                            />
                                                            <input
                                                                type="text"
                                                                placeholder="Search "
                                                                value={search}
                                                                onChange={(e) => {
                                                                    handleSearch(e);
                                                                    setSearch(e.target.value);
                                                                }}
                                                                style={{
                                                                    fontSize: "14px",
                                                                    lineHeight: "1.14285714",
                                                                    fontWeight: "400",
                                                                    border: "0",
                                                                    outline: "0",
                                                                    flex: "1",
                                                                    padding: "0 8px",
                                                                    height: "24px",
                                                                    color: "#484848",
                                                                }}
                                                            />

                                                            {search && (
                                                                <RxCross1
                                                                    height={24}
                                                                    width={24}
                                                                    fill="#484848"
                                                                    fontSize={20}
                                                                    cursor={"pointer"}
                                                                    onClick={() => {
                                                                        setSearch("");
                                                                        handleSearch({ target: { value: "" } });
                                                                    }}
                                                                />
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="mb-4"></div>
                                            </div>
                                            {allRooms && allRooms.length > 0 ? (
                                                <>
                                                    <div className="recent-chats">
                                                        <div className="chats" ref={scroll2}>
                                                            {allRooms
                                                                .filter((item) => item.participants[0]?.name?.toLowerCase().includes(search.toLowerCase()))
                                                                .map((item, index) => (
                                                                    <div key={index} className="wholeBox">
                                                                        <div
                                                                            onClick={() => {
                                                                                joinRoom(item.roomId, item.participants[0], item.participants[1], item._id);
                                                                                setViewMesage(true);
                                                                            }}
                                                                            key={index}
                                                                            href="#"
                                                                            className="chat"
                                                                        >
                                                                            <div>
                                                                                <div style={{ display: "flex" }}>
                                                                                    <div style={{ width: "100%" }}>
                                                                                        <div style={{ display: "flex" }}>
                                                                                            <span
                                                                                                style={{
                                                                                                    fontWeight: "600",
                                                                                                    fontSize: "16px",
                                                                                                    lineHeight: "1.25",
                                                                                                    textOverflow: "ellipsis",
                                                                                                    whiteSpace: "nowrap",
                                                                                                    overflow: "hidden",
                                                                                                    color: "#333",
                                                                                                }}
                                                                                            >
                                                                                                {item.participants[0].name}
                                                                                            </span>

                                                                                            <div
                                                                                                style={{
                                                                                                    paddingLeft: "24px",
                                                                                                    marginLeft: "auto",
                                                                                                    display: "flex",
                                                                                                    flexShrink: "0",
                                                                                                }}
                                                                                            >
                                                                                                <span
                                                                                                    style={{
                                                                                                        fontSize: "12px",
                                                                                                        lineHeight: "1.33333333",
                                                                                                        fontWeight: "500",
                                                                                                        textAlign: "right",
                                                                                                        color: "#8a8a8a",
                                                                                                    }}
                                                                                                >
                                                                                                    {item.messages && item.messages.length > 0 ? <>{item.messages[item.messages.length - 1].timestamp.slice(8, 10) + "/" + item.messages[item.messages.length - 1].timestamp.slice(5, 7) + "/" + item.messages[item.messages.length - 1].timestamp.slice(0, 4)}</> : <></>}
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>

                                                                                        <span
                                                                                            style={{
                                                                                                fontSize: "14px",
                                                                                                lineHeight: "1.57142857",
                                                                                                fontWeight: "400",
                                                                                                textOverflow: "ellipsis",
                                                                                                whiteSpace: "nowrap",
                                                                                                overflow: "hidden",
                                                                                                color: "#8A8A8A",
                                                                                                padding: "2px 0 0",
                                                                                            }}
                                                                                            // className="message"
                                                                                        >
                                                                                            {item.jobId && item.jobId.profile ? (item.jobId.profile.length > 27 ? `${item.jobId.profile.substring(0, 27)}...` : item.jobId.profile) : ""}
                                                                                        </span>

                                                                                        <span
                                                                                            // className="message"
                                                                                            style={{
                                                                                                fontSize: "14px",
                                                                                                lineHeight: "1.57142857",

                                                                                                color: "#666",
                                                                                                display: "flex",
                                                                                            }}
                                                                                        >
                                                                                            {item.messages && item.messages.length > 0 ? (
                                                                                                <>
                                                                                                    {item.messages[item.messages.length - 1].status === "unread" && item.messages[item.messages.length - 1].receiver === user._id ? (
                                                                                                        <>
                                                                                                            <p style={{ fontWeight: "400" }}>
                                                                                                                {item.messages[item.messages.length - 1]?.sender === user._id ? "You: " : item?.participants[1]?.name + ": "}
                                                                                                                {(() => {
                                                                                                                    const senderName = item.messages[item.messages.length - 1]?.sender === user._id ? "You: " : item?.participants[1]?.name + ": ";
                                                                                                                    const messageContent = item.messages[item.messages.length - 1]?.content.replace(/@\[(.*?)\]\(.*?\)/g, "$1") || "";

                                                                                                                    const totalLength = senderName.length + messageContent.length;

                                                                                                                    if (totalLength > 38) {
                                                                                                                        // Calculate how many characters to show from the message
                                                                                                                        const availableChars = 38 - senderName.length;
                                                                                                                        return messageContent.slice(0, availableChars) + "...";
                                                                                                                    }
                                                                                                                    return messageContent.slice(0, 42);
                                                                                                                })()}
                                                                                                            </p>
                                                                                                            {readMesCount(item.messages) === 0 ? null : (
                                                                                                                <div
                                                                                                                    style={{
                                                                                                                        marginLeft: "auto",
                                                                                                                        borderRadius: "50px",
                                                                                                                        backgroundColor: "#0C5CB7",
                                                                                                                        color: "white",
                                                                                                                        height: "17px",
                                                                                                                        width: "17px",
                                                                                                                        fontSize: "10px",
                                                                                                                        textAlign: "center",
                                                                                                                    }}
                                                                                                                >
                                                                                                                    {readMesCount(item.messages)}
                                                                                                                </div>
                                                                                                            )}
                                                                                                        </>
                                                                                                    ) : (
                                                                                                        <>
                                                                                                            <p style={{ fontWeight: "400" }}>
                                                                                                                {item.messages[item.messages.length - 1]?.sender === user._id ? "You: " : item?.participants[1]?.name + ": "}
                                                                                                                {(() => {
                                                                                                                    const senderName = item.messages[item.messages.length - 1]?.sender === user._id ? "You: " : item?.participants[1]?.name + ": ";
                                                                                                                    const messageContent = item.messages[item.messages.length - 1]?.content.replace(/@\[(.*?)\]\(.*?\)/g, "$1") || "";

                                                                                                                    const totalLength = senderName.length + messageContent.length;

                                                                                                                    if (totalLength > 38) {
                                                                                                                        const availableChars = 38 - senderName.length;
                                                                                                                        return messageContent.slice(0, availableChars) + "...";
                                                                                                                    }
                                                                                                                    return messageContent.slice(0, 42);
                                                                                                                })()}
                                                                                                            </p>
                                                                                                        </>
                                                                                                    )}
                                                                                                </>
                                                                                            ) : (
                                                                                                <p style={{ fontWeight: "400" }}>...</p>
                                                                                            )}
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            {/* <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontWeight: "600",
                                        fontSize: "16px",
                                        lineHeight: "1.25",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        color: "#333",
                                      }}
                                    >
                                      {item.participants[0].organisationName}
                                    </span>

                                    <div
                                      style={{
                                        paddingLeft: "24px",
                                        marginLeft: "auto",
                                        display: "flex",
                                        flexShrink: "0",
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontSize: "12px",
                                          lineHeight: "1.33333333",
                                          fontWeight: "500",
                                          textAlign: "right",
                                          color: "#8a8a8a",
                                        }}
                                      >
                                        {item.messages &&
                                        item.messages.length > 0 ? (
                                          <>
                                            {item.messages[
                                              item.messages.length - 1
                                            ].timestamp.slice(8, 10) +
                                              "/" +
                                              item.messages[
                                                item.messages.length - 1
                                              ].timestamp.slice(5, 7) +
                                              "/" +
                                              item.messages[
                                                item.messages.length - 1
                                              ].timestamp.slice(0, 4)}
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                      </span>
                                    </div>
                                  </div>

                                  <span
                                    style={{
                                      fontSize: "14px",
                                      lineHeight: "1.57142857",
                                      fontWeight: "400",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      color: "#8A8A8A",
                                      padding: "2px 0 0",
                                    }}
                                    // className="message"
                                  >
                                    {item.jobId && item.jobId.profile
                                      ? item.jobId.profile
                                      : ""}
                                  </span>

                                  <span
                                    // className="message"
                                    style={{
                                      fontSize: "14px",
                                      lineHeight: "1.57142857",

                                      color: "#666",
                                      display: "flex",
                                    }}
                                  >
                                    {item.messages &&
                                    item.messages.length > 0 ? (
                                      <>
                                        {item.messages[item.messages.length - 1]
                                          .status == "unread" &&
                                        item.messages[item.messages.length - 1]
                                          .receiver == user._id ? (
                                          <>
                                            <p style={{ fontWeight: "400" }}>
                                              {" "}
                                              {item.messages[
                                                item.messages.length - 1
                                              ]?.content?.slice(0, 42) || ""}
                                            </p>
                                            {readMesCount(item.messages) ==
                                            0 ? (
                                              <></>
                                            ) : (
                                              <>
                                                <div
                                                  style={{
                                                    marginLeft: "auto",
                                                    borderRadius: "50px",
                                                    backgroundColor: "#0C5CB7",
                                                    color: "white",
                                                    height: "17px",
                                                    width: "17px",
                                                    fontSize: "10px",
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  {readMesCount(item.messages)}
                                                </div>
                                              </>
                                            )}
                                          </>
                                        ) : (
                                          <>
                                            <p style={{ fontWeight: "400" }}>
                                              {" "}
                                              {item.messages[
                                                item.messages.length - 1
                                              ]?.content?.slice(0, 42) || ""}
                                            </p>
                                          </>
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        <p style={{ fontWeight: "400" }}>...</p>
                                      </>
                                    )}
                                  </span> */}

                                                                            <button
                                                                                className="btn status"
                                                                                style={{
                                                                                    background: "#eafcff",
                                                                                    color: "#333",
                                                                                    fontSize: "12px",
                                                                                    lineHeight: "1.33333333",
                                                                                    fontWeight: "500",
                                                                                    borderRadius: "4px",
                                                                                    padding: "5px 8px",
                                                                                    marginRight: "8px",
                                                                                }}
                                                                            >
                                                                                {item.appliedJobId ? <>{item.appliedJobId.status === "Assignment" ? "Assigment Received" : item.appliedJobId.status === "Application_Sent" ? "Application Sent" : item.appliedJobId.status === "NotIntrested" ? "Not Interested" : item.appliedJobId.status}</> : <>Genral</>}
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                        </div>
                                                    </div>

                                                    {/*end*/}
                                                </>
                                            ) : (
                                                <>
                                                    <div className="recent-chats" style={{ width: "35%" }}>
                                                        <div className="chats text-center" style={{ width: "100%" }}>
                                                            <RiChatOffLine className="mx-auto mt-5" style={{ fontSize: "50px", color: "#5db2f7" }} />
                                                            <p
                                                                style={{
                                                                    fontSize: "20px",
                                                                    fontWeight: "400",
                                                                    color: "gray",
                                                                }}
                                                                className="mt-2 text-center"
                                                            >
                                                                No chat available!!
                                                            </p>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
            <ToastContainer position="top-center" autoClose={10000} />
        </>
    );
}

export default MessageStudent;
