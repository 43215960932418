import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Categories from "./Categories";
import Companies from "./Companies";
import FirstSection from "./FirstSection";
import LandingPage from "./LandingPage";
import EmployerApplications from "../EmployerNew/employerApplication";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function HomePage() {
    const { user } = useSelector((state) => state.user);
    const navigate = useNavigate();

    useEffect(() => {
        if (localStorage.getItem("token")) {
            if (user?.role === "Admin") {
                navigate("/admin");
            }
        }
    }, [user, navigate]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <div style={{ backgroundColor: "white" }}>
                {user && user.role === "Employer" ? (
                    <EmployerApplications />
                ) : (
                    <>
                        {" "}
                        {<LandingPage />} {<Companies />}{" "}
                    </>
                )}
            </div>
            <ToastContainer position="top-center" autoClose={10000} />
        </>
    );
}

export default HomePage;
