import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { logoutUser } from "../../actions/userActions";
import Sidebar from "react-sidebar";
import Logo from "../../assets/images/logo1.png";
import axios from "axios";
import io from "socket.io-client";
import toast, { Toaster } from "react-hot-toast";
import photo from "../../assets/images/icons/profile.png";
import Avichal from "../../assets/images/avichal.png";
import "./css/Header2.scss";
import Dropdown from "./DropDown";
import DropdownAdmin from "./DropdownAdmin";
import { BsChatLeftText } from "react-icons/bs";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { RxHamburgerMenu } from "react-icons/rx";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import { logEvent } from "../../utils/Analytics";
function Header2() {
    const location = useLocation();
    const [data, setData] = useState("");
    const [register, setRegister] = useState(false);
    const [profile, setProfile] = useState(false);
    const navigate = useNavigate();
    const { user } = useSelector((state) => state.user);

    const dispatch = useDispatch();

    const handleLinkClick = () => {
        window.scrollTo(0, 0); // Scroll to the top of the window
    };

    const Logout = () => {
        dispatch(logoutUser());
        // window.location.href = "http://localhost:3000/"
        navigate("/");
        window.location.reload();
    };

    const LogoutAdmin = () => {
        dispatch(logoutUser());
        navigate("/admin_login");
    };

    const handleRegister = () => {
        setRegister((prevRegister) => !prevRegister);
    };

    const handleProfile = () => {
        setProfile((prevProfile) => !prevProfile);
    };

    const isAuthenticated = useSelector((state) => state.user.isAuthenticated);

    const [unreadMsg, setUnreadMsg] = useState(0);

    const GetRoomData = async () => {
        if (user && user._id) {
            const id = user && user._id;
            try {
                const { data } = await axios.post(`/chats/unread/${id}`, {
                    headers: {
                        "Content-Type": "application/json",
                        token: localStorage.getItem("token"),
                    },
                });
                const unreadCount = data.filter((message) => message.messages.filter((msg) => msg.status === "unread" && msg.receiver === id).length > 0).length;

                setUnreadMsg(unreadCount);
            } catch (err) {
                console.error("Error fetching data:" + err);
            }
        }
    };

    useEffect(() => {
        const interval = setInterval(() => {
            GetRoomData();
        }, 1000); // 1000 milliseconds = 1 second

        // Cleanup function to clear the interval when component unmounts or dependencies change
        return () => clearInterval(interval);
    }, [user]);

    const handleSavedJobClick = () => {
        if (isAuthenticated) {
            console.log("handleSavedJobClick called");

            navigate("/studentSavedJob");
        } else {
            alert("Please log in to view saved jobs.");
        }
    };

    const [sidebarOpen, setSidebarOpen] = useState(false);

    // SIDE BAR

    const [open, setOpen] = React.useState(false);

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };

    const handleAddEvent = (str) => {
      logEvent("Button", `Clicked ${str} button`);
    };
    

    const DrawerList = (
        <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
            <List>
                {user && user.role === "User"
                    ? ["Applications", "Employers", "Internships", "Jobs"].map((text) => (
                          <ListItem key={text} disablePadding>
                              <ListItemButton>
                                  <ListItemText primary={text} sx={{ fontSize: "2rem" }} />
                              </ListItemButton>
                          </ListItem>
                      ))
                    : user && user.role !== "User"
                    ? ["Dashboard", "Job Seekers", "Post Jobs/Internships"].map((text) => (
                          <ListItem key={text} disablePadding>
                              <ListItemButton>
                                  <ListItemText primary={text} sx={{ fontSize: "2rem" }} />
                              </ListItemButton>
                          </ListItem>
                      ))
                    : ["Employers", "Job Seekers", "Internships", "Jobs"].map((text) => (
                          <ListItem key={text} disablePadding>
                              <ListItemButton>
                                  <ListItemText primary={text} style={{ fontSize: "25px" }} />
                              </ListItemButton>
                          </ListItem>
                      ))}
            </List>
            <Divider />
        </Box>
    );

    const navigatePath = (path) => {
        navigate(path);
    };

    return (
        <>
            {<Toaster /> /*  */}

            {/*ADMIN PANEL*/}
            {user && user.role === "Admin" ? (
                <>
                    <div className="Admin">
                        <nav class="navbar navbar-expand-lg navbar-light sticky-top bg-light rounded" style={{ position: "Fixed", width: "100%" }}>
                            <div class="container-fluid">
                                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                                <div className="logo-box  " style={{ marginLeft: "40px" }}>
                                    <Link to="/admin" onClick={handleLinkClick}>
                                        <img src={Logo} alt="logo" style={{ height: "50px" }} />
                                    </Link>
                                </div>
                                <div class="d-flex" style={{ marginRight: "40px" }}>
                                    <div class="collapse navbar-collapse" id="navbarSupportedContent">
                                        <ul class="navbar-nav me-auto mb-2 mb-lg-0 p-4">
                                            <li class="nav-item mr-5">
                                                <Link to="/admin_input_control"  onClick={() => handleAddEvent("Admin Input Control")} className={`nav-link active ${location.pathname === "/admin_input_control" ? "border-2 border-bottom border-primary" : ""}`} aria-current="page" style={{ cursor: "pointer" }}>
                                                    <b>Admin Input Control</b>
                                                </Link>
                                            </li>
                                            <li class="nav-item mr-5">
                                                <Link to="/admin_candidates"  onClick={() => handleAddEvent("Candidates")} className={`nav-link active ${location.pathname === "/admin_candidates" ? "border-2 border-bottom border-primary" : ""}`} aria-current="page" style={{ cursor: "pointer" }}>
                                                    <b>Candidates</b>
                                                </Link>
                                            </li>
                                            <li class="nav-item mr-5">
                                                <Link to="/admin_employers"  onClick={() => handleAddEvent("Employers")} className={`nav-link active ${location.pathname === "/admin_employers" ? "border-2 border-bottom border-primary" : ""}`} aria-current="page" style={{ cursor: "pointer" }}>
                                                    <b>Employers</b>
                                                </Link>
                                            </li>
                                            <li class="nav-item mr-5" style={{ marginRight: "40px" }}>
                                                <Link to="/admin" onClick={() => handleAddEvent("Posted Jobs/Internship")}  className={`nav-link active ${location.pathname === "/admin" ? "border-2 border-bottom border-primary" : ""}`} aria-current="page" style={{ cursor: "pointer" }}>
                                                    <b>Posted Jobs/Internship</b>
                                                </Link>
                                            </li>
                                            <li class="nav-item dropdown ml-5" style={{ color: "black" }}>
                                                {/* dropdown-toggle removed to remove the down arrow because the arrow was not aligned */}
                                                <DropdownAdmin user={user} />
                                            </li>
                                            {/*  <li class="nav-item ml-4">       
                        <button class="LogoutAdmin" onClick={() => LogoutAdmin()}>Logout</button>
                      </li>*/}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </nav>
                    </div>

                    {/*ADMIN MOBILE*/}
                    <div className="AdminMob">
                        <nav class="navbar navbar-expand-lg navbar-light  sticky-top bg-body rounde" style={{ position: "Fixed", width: "100%" }}>
                            <div class="container-fluid">
                                <Button style={{ color: "black" }} onClick={toggleDrawer(true)}>
                                    <RxHamburgerMenu style={{ fontSize: "22px" }} />
                                </Button>

                                <div className="logo-box  ">
                                    <Link to="/admin" onClick={handleLinkClick}>
                                        <img className="imgofNav" src={Logo} alt="logo" />
                                    </Link>
                                </div>

                                <div class="d-flex">
                                    <div class="" id="navbarSupportedContent">
                                        <ul class="navbar-nav me-auto mb-2 mb-lg-0 p-4">
                                            <li class="nav-item" style={{ position: "relative" }}>
                                                <button class="LogoutAdmin" onClick={() => LogoutAdmin()} style={{ fontSize: "13px", borderRadius: "20px", width: "80px" }}>
                                                    Logout
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </nav>
                    </div>
                    <div>
                        <Drawer open={open} onClose={toggleDrawer(false)}>
                            {/*DrawerList*/}
                            <div className="mx-5">
                                <div style={{ justifyItem: "center", alignItems: "center" }}>
                                    <Link to="/admin">
                                        <img src={Logo} alt="logo" style={{ height: "45px", marginTop: "10px", marginLeft: "20%", marginBottom: "20px" }} />
                                    </Link>
                                </div>
                                <p style={{ fontWeight: "500" }}>{user && user.name}</p>
                                <p style={{ fontSize: "13px", marginTop: "-8px" }}>{user && user.email}</p>
                                <hr className="my-4" style={{ height: "0.1px" }} />
                                <div className="mt-3">
                                    <p onClick={() => {navigatePath("/admin"),handleAddEvent("Dashboard")}} className={location.pathname === "/admin" ? "SidebarTitleActive" : "SidebarTitle"}>
                                        Dashboard
                                    </p>
                                    <p onClick={() => {navigatePath("/admin_candidates"),handleAddEvent("Candidates")}} className={location.pathname === "/admin_candidates" ? "SidebarTitleActive" : "SidebarTitle"}>
                                        Candidates
                                    </p>
                                    <p onClick={() => {navigatePath("/admin_employers"),handleAddEvent("Employers")}} className={location.pathname === "/admin_employers" ? "SidebarTitleActive" : "SidebarTitle"}>
                                        Employers
                                    </p>
                                    <p onClick={() => {navigatePath("/admin_input_control"),handleAddEvent("Admin Input Control")}} className={location.pathname === "/admin_input_control" ? "SidebarTitleActive" : "SidebarTitle"}>
                                        Admin Input Control
                                    </p>
                                </div>
                                <hr className="my-4" style={{ height: "0.1px" }} />
                                <div>
                                    <p onClick={() => {navigatePath("/admin_change_password"),handleAddEvent("Change Password")}} className={location.pathname === "/admin_change_password" ? "SidebarTitleActive" : "SidebarTitle"}>
                                        Change Password
                                    </p>
                                    <p onClick={LogoutAdmin} className="SidebarTitle">
                                        Logout
                                    </p>
                                </div>
                            </div>
                        </Drawer>
                    </div>
                </>
            ) : (
                <>
                    <div className="navigation">
                        <nav class="navbar navbar-expand-lg navbar-light sticky-top bg-light rounded" style={{ position: "Fixed", width: "100%" }}>
                            <div class="container-fluid">
                                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon"></span>
                                </button>

                                {user && user.role != "User" ? (
                                    <div className="logo-box  " style={{ marginLeft: "40px" }}>
                                        <Link to="/employerApplication/Internship" onClick={handleLinkClick}>
                                            <img src={Logo} alt="logo" style={{ height: "50px" }} />
                                        </Link>
                                    </div>
                                ) : (
                                    <div className="logo-box  " style={{ marginLeft: "40px" }}>
                                        <Link to="/" onClick={handleLinkClick}>
                                            <img src={Logo} alt="logo" style={{ height: "50px" }} />
                                        </Link>
                                    </div>
                                )}
                                <div class="d-flex" style={{ marginRight: "40px" }}>
                                    <div class="collapse navbar-collapse" id="navbarSupportedContent">
                                        <ul class="navbar-nav me-auto mb-2 mb-lg-0 p-4">
                                            {user && user.role != "User" ? (
                                                <>
                                                    <li class="nav-item mr-5">
                                                        <Link
                                                            onClick={() => handleAddEvent("Dashboard")} to="/employerApplication/Internship"
                                                            className={`nav-link active ${
                                                                // location.pathname === '/employerApplication'
                                                                location.pathname.startsWith("/employerApplication") ? "border-2 border-bottom border-primary" : ""
                                                            }`}
                                                            aria-current="page"
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            <b>Dashboard</b>
                                                        </Link>
                                                    </li>
                                                    <li class="nav-item mr-5">
                                                        <Link onClick={() => handleAddEvent("Job Seekers")} to="/candidate-details" className={`nav-link active ${location.pathname === "/candidate-details" ? "border-2 border-bottom border-primary" : ""}`} aria-current="page" style={{ cursor: "pointer" }}>
                                                            <b>Job Seekers</b>
                                                        </Link>
                                                    </li>
                                                    <li class="nav-item mr-5">
                                                        <Link onClick={() => handleAddEvent("Post Jobs/Internships")} to="/newpost/0" className={`nav-link active ${location.pathname === "/newpost/0" ? "border-2 border-bottom border-primary" : ""}`} aria-current="page" style={{ cursor: "pointer" }}>
                                                            <b>Post Jobs/Internships</b>
                                                        </Link>
                                                    </li>
                                                    <li
                                                        className="mb-3 mr-5"
                                                        style={{
                                                            position: "relative",
                                                        }}
                                                    >
                                                        <Link onClick={() => handleAddEvent("Message")} to="/messageEmployer" style={{ display: "flex", alignItems: "center" }} className="mt-3 mx-4">
                                                            <BsChatLeftText style={{ fontSize: "20px" }} />
                                                        </Link>

                                                        <span
                                                            style={{
                                                                position: "absolute",
                                                                top: "0",
                                                                right: "0",
                                                                borderRadius: "50%",
                                                                width: "20px",
                                                                height: "20px",
                                                                fontWeight: "700",
                                                                background: "#FFA333",
                                                                fontSize: "1rem",
                                                                border: "1px solid #fff",
                                                                textAlign: "center",
                                                                paddingTop: "2px",
                                                                color: "white",
                                                            }}
                                                        >
                                                            {unreadMsg > 9 ? "9+" : unreadMsg}
                                                        </span>
                                                    </li>
                                                </>
                                            ) : (
                                                <>
                                                    {user ? (
                                                        <>
                                                            <li class="nav-item mr-5">
                                                                <Link onClick={() => handleAddEvent("Applications")} to="/applications" className={`nav-link active ${location.pathname === "/applications" ? "border-2 border-bottom border-primary" : ""}`} aria-current="page" style={{ cursor: "pointer" }}>
                                                                    <b>Applications</b>
                                                                </Link>
                                                            </li>
                                                            <li class="nav-item mr-5">
                                                                <Link onClick={() => handleAddEvent("Employers")} to="/employer-details" className={`nav-link active ${location.pathname === "/employer-details" ? "border-2 border-bottom border-primary" : ""}`} aria-current="page" style={{ cursor: "pointer" }}>
                                                                    <b>Employers</b>
                                                                </Link>
                                                            </li>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <li class="nav-item mr-5">
                                                                <Link onClick={() => handleAddEvent("Employers")} to="/employer-details" className={`nav-link active ${location.pathname === "/employer-details" ? "border-2 border-bottom border-primary" : ""}`} aria-current="page" style={{ cursor: "pointer" }}>
                                                                    <b>Employers</b>
                                                                </Link>
                                                            </li>
                                                            <li class="nav-item mr-5">
                                                                <Link onClick={() => handleAddEvent("Job Seekers")} to="/candidate-details" className={`nav-link active ${location.pathname === "/candidate-details" ? "border-2 border-bottom border-primary" : ""}`} aria-current="page" style={{ cursor: "pointer" }}>
                                                                    <b>Job Seekers</b>
                                                                </Link>
                                                            </li>
                                                        </>
                                                    )}
                                                    <li class="nav-item mr-5">
                                                        <Link onClick={() => handleAddEvent("Internships")} to="/matchedInternships/all" className={`nav-link active ${location.pathname === "/matchedInternships/all" ? "border-2 border-bottom border-primary" : ""}`} aria-current="page" style={{ cursor: "pointer" }}>
                                                            <b>Internships</b>
                                                        </Link>
                                                    </li>
                                                    <li class="nav-item mr-5">
                                                        <Link onClick={() => handleAddEvent("Jobs")} to="/matchedJobs/all" className={`nav-link active ${location.pathname === "/matchedJobs/all" ? "border-2 border-bottom border-primary" : ""}`} aria-current="page" style={{ cursor: "pointer" }}>
                                                            <b>Jobs</b>
                                                        </Link>
                                                    </li>
                                                    {user ? (
                                                        <>
                                                            <li
                                                                className="mb-3 mr-5"
                                                                style={{
                                                                    position: "relative",
                                                                }}
                                                            >
                                                                <Link
                                                                    to="/messageStudent"
                                                                    style={{
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                    }}
                                                                    onClick={() => handleAddEvent("Message")}
                                                                    className="mt-3 mx-4"
                                                                >
                                                                    <BsChatLeftText style={{ fontSize: "20px" }} />
                                                                </Link>

                                                                <span
                                                                    style={{
                                                                        position: "absolute",
                                                                        top: "0",
                                                                        right: "0",
                                                                        borderRadius: "50%",
                                                                        width: "20px",
                                                                        height: "20px",
                                                                        fontWeight: "700",
                                                                        background: "#FFA333",
                                                                        fontSize: "1rem",
                                                                        border: "1px solid #fff",
                                                                        textAlign: "center",
                                                                        paddingTop: "2px",
                                                                        color: "white",
                                                                    }}
                                                                >
                                                                    {unreadMsg > 9 ? "9+" : unreadMsg}
                                                                </span>
                                                            </li>
                                                        </>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </>
                                            )}

                                            {user ? (
                                                <>
                                                    <li class="nav-item dropdown mx-4" style={{ color: "black" }}>
                                                        {/* dropdown-toggle removed to remove the down arrow because the arrow was not aligned */}
                                                        <Dropdown user={user} />
                                                    </li>
                                                </>
                                            ) : (
                                                <>
                                                    <li class="nav-item mr-5">
                                                        <a href="/login" onClick={() => handleAddEvent("Login")}>
                                                            <button class="btn-light login-button" >
                                                                <b>Login</b>
                                                            </button>
                                                        </a>
                                                        {/* <Link
                            to="/login"
                            state={{ from: location.state?.from || location.pathname }}
                          >
                          <button class="btn-light login-button">
                          <b>Login</b>
                        </button>
                      </Link> */}
                                                    </li>

                                                    <li class="nav-item dropdown">
                                                        <a class="nav-link dropdown-toggle register-dropdown" href="/" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false" style={{ color: "white", padding: "5px" }}>
                                                            Register
                                                        </a>
                                                        <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                                            <li>
                                                                {/* <a class="dropdown-item signup" href="/studentSignup" style={{paddingTop:'1rem', paddingBottom:'1rem'}}>
                          <b>Job Seeker</b>
                          </a> */}
                                                                <Link onClick={() => handleAddEvent("Job Seeker")} className="dropdown-item signup" to="/studentSignup" state={{ from: location.state?.from || location.pathname }} style={{ paddingTop: "1rem", paddingBottom: "1rem" }}>
                                                                    <b>Job Seeker</b>
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <a onClick={() => handleAddEvent("Employer")} class="dropdown-item signup" href="/employeeSignup" style={{ paddingTop: "1rem", paddingBottom: "1rem" }}>
                                                                    <b>Employer</b>
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                </>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </nav>
                    </div>

                    {/*Mobile Navbar*/}
                    <div className="nav2">
                        <nav class="navbar navbar-expand-lg navbar-light  sticky-top bg-body rounde" style={{ position: "Fixed", width: "100%" }}>
                            <div class="container-fluid">
                                <Button style={{ color: "black" }} onClick={toggleDrawer(true)}>
                                    <RxHamburgerMenu style={{ fontSize: "22px" }} />
                                </Button>

                                {user && user.role != "User" ? (
                                    <div className="logo-box  ">
                                        <Link to="/employerApplication/Internship" onClick={handleLinkClick}>
                                            <img className="imgofNav" src={Logo} alt="logo" />
                                        </Link>
                                    </div>
                                ) : (
                                    <div className="logo-box  ">
                                        <Link to="/" onClick={handleLinkClick}>
                                            <img className="imgofNav" src={Logo} alt="logo" />
                                        </Link>
                                    </div>
                                )}

                                <div class="d-flex">
                                    <div class="" id="navbarSupportedContent">
                                        <ul class="navbar-nav me-auto mb-2 mb-lg-0 p-4">
                                            {user && user.role != "User" ? (
                                                <>
                                                    <li
                                                        className=""
                                                        style={{
                                                            position: "relative",
                                                        }}
                                                    >
                                                        <Link to="/messageEmployer" onClick={() => handleAddEvent("Message")} style={{ display: "flex", alignItems: "center" }} className="mt-3 mx-4">
                                                            <BsChatLeftText style={{ fontSize: "20px" }} />
                                                        </Link>

                                                        <span
                                                            style={{
                                                                position: "absolute",
                                                                top: "0",
                                                                right: "0",
                                                                borderRadius: "50%",
                                                                width: "20px",
                                                                height: "20px",
                                                                fontWeight: "700",
                                                                background: "#FFA333",
                                                                fontSize: "1rem",
                                                                border: "1px solid #fff",
                                                                textAlign: "center",
                                                                paddingTop: "2px",
                                                                color: "white",
                                                            }}
                                                        >
                                                            {unreadMsg > 9 ? "9+" : unreadMsg}
                                                        </span>
                                                    </li>
                                                </>
                                            ) : (
                                                <>
                                                    {user ? (
                                                        <>
                                                            <li
                                                                className=""
                                                                style={{
                                                                    position: "relative",
                                                                }}
                                                            >
                                                                <Link
                                                                    to="/messageStudent"
                                                                    style={{
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                    }}
                                                                    onClick={() => handleAddEvent("Message")} 
                                                                    className="mt-3 mx-4"
                                                                >
                                                                    <BsChatLeftText style={{ fontSize: "20px" }} />
                                                                </Link>

                                                                <span
                                                                    style={{
                                                                        position: "absolute",
                                                                        top: "0",
                                                                        right: "0",
                                                                        borderRadius: "50%",
                                                                        width: "20px",
                                                                        height: "20px",
                                                                        fontWeight: "700",
                                                                        background: "#FFA333",
                                                                        fontSize: "1rem",
                                                                        border: "1px solid #fff",
                                                                        textAlign: "center",
                                                                        paddingTop: "2px",
                                                                        color: "white",
                                                                    }}
                                                                >
                                                                    {unreadMsg > 9 ? "9+" : unreadMsg}
                                                                </span>
                                                            </li>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <li class="nav-item dropdown" style={{ position: "relative" }}>
                                                                <a class="nav-link dropdown-toggle register-dropdown px-1" href="/"  id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false" style={{ color: "white", padding: "5px", fontSize: "12px", borderRadius: "20px", marginTop: "5px", width: "80px" }}>
                                                                    Register
                                                                </a>
                                                                <ul class="dropdown-menu" aria-labelledby="navbarDropdown" style={{ position: "absolute", top: "100%", left: "0" }}>
                                                                    <li>
                                                                        {/* <a class="dropdown-item signup" href="/studentSignup">
                                                    <b>Job Seeker</b>
                                                </a> */}
                                                                        <Link className="dropdown-item signup" onClick={() => handleAddEvent("Job Seeker")} to="/studentSignup" state={{ from: location.state?.from || location.pathname }}>
                                                                            <b>Job Seeker</b>
                                                                        </Link>
                                                                    </li>
                                                                    <li>
                                                                        <a class="dropdown-item signup" href="/employeeSignup" onClick={() => handleAddEvent("Employer")}>
                                                                            <b>Employer</b>
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </li>
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </nav>
                    </div>

                    <div>
                        <Drawer open={open} onClose={toggleDrawer(false)}>
                            {/*DrawerList*/}
                            <div className="mx-5">
                                <div style={{ justifyItem: "center", alignItems: "center" }}>
                                    {user && user.role != "User" ? (
                                        <Link to="/employerApplication/Internship">
                                            <img src={Logo} alt="logo" style={{ height: "45px", marginTop: "10px", marginLeft: "20%", marginBottom: "20px" }} />
                                        </Link>
                                    ) : (
                                        <Link to="/">
                                            <img src={Logo} alt="logo" style={{ height: "45px", marginTop: "10px", marginLeft: "20%", marginBottom: "20px" }} />
                                        </Link>
                                    )}
                                </div>
                                {user && user.role != "User" ? (
                                    <>
                                        {/*Employer*/}

                                        <p style={{ fontWeight: "500" }}>{user && user.name}</p>
                                        <p style={{ fontSize: "13px", marginTop: "-8px" }}>{user && user.email}</p>
                                        <hr className="my-4" style={{ height: "0.1px" }} />
                                        <div className="mt-3">
                                            {/* <p onClick={() => {navigatePath('/employerApplication'),handleAddEvent("")}} className={location.pathname === '/employerApplication' ? "SidebarTitleActive" : "SidebarTitle"}>Dashboard</p> */}
                                            <p onClick={() => {navigatePath("/employerApplication/Internship"),handleAddEvent("Dashboard")}} className={location.pathname.startsWith("/employerApplication") ? "SidebarTitleActive" : "SidebarTitle"}>
                                                Dashboard
                                            </p>
                                            <p onClick={() => {navigatePath("/candidate-details"),handleAddEvent("Job Seekers")}} className={location.pathname === "/candidate-details" ? "SidebarTitleActive" : "SidebarTitle"}>
                                                Job Seekers
                                            </p>
                                            <p onClick={() => {navigatePath("/newpost/0"),handleAddEvent("Post Jobs/Internships")}} className={location.pathname === "/newpost/0" ? "SidebarTitleActive" : "SidebarTitle"} style={{ paddingRight: "30px" }}>
                                                Post Jobs/Internships
                                            </p>
                                            <p onClick={() => {navigatePath("/support"),handleAddEvent("Help & Support")}} className={location.pathname === "/support" ? "SidebarTitleActive" : "SidebarTitle"}>
                                                Help & Support
                                            </p>
                                        </div>
                                        <hr className="my-4" style={{ height: "0.1px" }} />
                                        <div>
                                            <p className={profile ? "SidebarTitleActive" : "SidebarTitle"} onClick={() => handleProfile()}>
                                                Profile {profile ? <IoIosArrowUp className="ml-3" /> : <IoIosArrowDown className="ml-3" />}
                                            </p>
                                            {profile ? (
                                                <>
                                                    <p onClick={() => {navigatePath("/myProfile"),handleAddEvent("Edit Profile")}} className={location.pathname === "/myProfile" ? "SidebarTitleSubActive" : "SidebarTitleSub"}>
                                                        Edit Profile
                                                    </p>
                                                    <p onClick={() => {navigatePath("/change-password"),handleAddEvent("Change Password")}} className={location.pathname === "/change-password" ? "SidebarTitleSubActive" : "SidebarTitleSub"}>
                                                        Change Password
                                                    </p>
                                                    <p onClick={() => {navigatePath("/myProfile/company"),handleAddEvent("Organization Details")}} className={location.pathname === "/myProfile/company" ? "SidebarTitleSubActive" : "SidebarTitleSub"}>
                                                        Organization Details
                                                    </p>
                                                    <p onClick={() => {navigatePath("/employerbuyconnects"),handleAddEvent("Buy Connects")}} className={location.pathname === "/employerbuyconnects" ? "SidebarTitleSubActive" : "SidebarTitleSub"}>
                                                        Buy Connects
                                                    </p>
                                                    <p onClick={() => {navigatePath("/employerconnectsinformation"),handleAddEvent("Connects Summary")}} className={location.pathname === "/employerconnectsinformation" ? "SidebarTitleSubActive" : "SidebarTitleSub"}>
                                                        Connects Summary
                                                    </p>
                                                </>
                                            ) : (
                                                <></>
                                            )}

                                            <p onClick={Logout} className="SidebarTitle">
                                                Logout
                                            </p>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        {user ? (
                                            <>
                                                {/*User*/}
                                                <p style={{ fontWeight: "500" }}>{user && user.name}</p>
                                                <p style={{ fontSize: "13px", marginTop: "-8px" }}>{user && user.email}</p>
                                                <hr className="my-4" style={{ height: "0.1px" }} />
                                                <div className="mt-3">
                                                    <p onClick={() => {navigatePath("/applications"),handleAddEvent("Applications")}} className={location.pathname === "/applications" ? "SidebarTitleActive" : "SidebarTitle"}>
                                                        Applications
                                                    </p>
                                                    <p onClick={() => {navigatePath("/employer-details"),handleAddEvent("Employers")}} className={location.pathname === "/employer-details" ? "SidebarTitleActive" : "SidebarTitle"}>
                                                        Employers
                                                    </p>
                                                    <p onClick={() => {navigatePath("/matchedInternships/all"),handleAddEvent("Internships")}} className={location.pathname === "/matchedInternships/all" ? "SidebarTitleActive" : "SidebarTitle"}>
                                                        Internships
                                                    </p>
                                                    <p onClick={() => {navigatePath("/matchedJobs/all"),handleAddEvent("Jobs")}} className={location.pathname === "/matchedJobs/all" ? "SidebarTitleActive" : "SidebarTitle"}>
                                                        Jobs
                                                    </p>
                                                    {/* <p onClick={() => {navigatePath('/contactus'),handleAddEvent("")}} className={location.pathname === '/contactus' ? "SidebarTitleActive" : "SidebarTitle"}>Contact Us</p> */}
                                                </div>
                                                <hr className="my-4" style={{ height: "0.1px" }} />
                                                <div>
                                                    <p onClick={() => {navigatePath("/StudentSavedjob"),handleAddEvent("Saved Jobs")}} className={location.pathname === "/StudentSavedjob" ? "SidebarTitleActive" : "SidebarTitle"}>
                                                        Saved Jobs
                                                    </p>

                                                    <p className={profile ? "SidebarTitleActive" : "SidebarTitle"} onClick={() => handleProfile()}>
                                                        Profile {profile ? <IoIosArrowUp className="ml-3" /> : <IoIosArrowDown className="ml-3" />}
                                                    </p>
                                                    {profile ? (
                                                        <>
                                                            {/* <p onClick={() => {navigatePath('/editProfileUser'),handleAddEvent("")}} className={location.pathname === '/editProfileUser' ? "SidebarTitleSubActive" : "SidebarTitleSub"}>Edit Profile</p> */}
                                                            <p onClick={() => {navigatePath("/addresume"),handleAddEvent("Edit Resume")}} className={location.pathname === "/addresume" ? "SidebarTitleSubActive" : "SidebarTitleSub"}>
                                                                Edit Resume
                                                            </p>
                                                            <p onClick={() => {navigatePath("/change-email"),handleAddEvent("Change Email")}} className={location.pathname === "/change-email" ? "SidebarTitleSubActive" : "SidebarTitleSub"}>
                                                                Change Email
                                                            </p>
                                                            <p onClick={() => {navigatePath("/change-password"),handleAddEvent("Change Password")}} className={location.pathname === "/change-password" ? "SidebarTitleSubActive" : "SidebarTitleSub"}>
                                                                Change Password
                                                            </p>
                                                        </>
                                                    ) : (
                                                        <></>
                                                    )}

                                                    <p onClick={Logout} className="SidebarTitle">
                                                        Logout
                                                    </p>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                {/*Not LogedIn*/}
                                                <div className="mt-3">
                                                    <p onClick={() => {navigatePath("/employer-details"),handleAddEvent("Employers")}} className={location.pathname === "/employer-details" ? "SidebarTitleActive" : "SidebarTitle"}>
                                                        Employers
                                                    </p>
                                                    <p onClick={() => {navigatePath("/candidate-details"),handleAddEvent("Job Seekers")}} className={location.pathname === "/candidate-details" ? "SidebarTitleActive" : "SidebarTitle"}>
                                                        Job Seekers
                                                    </p>
                                                    <p onClick={() => {navigatePath("/matchedInternships/all"),handleAddEvent("Internships")}} className={location.pathname === "/matchedInternships/all" ? "SidebarTitleActive" : "SidebarTitle"}>
                                                        Internships
                                                    </p>
                                                    <p onClick={() => {navigatePath("/matchedJobs/all"),handleAddEvent("Jobs")}} className={location.pathname === "/matchedJobs/all" ? "SidebarTitleActive" : "SidebarTitle"}>
                                                        Jobs
                                                    </p>
                                                    {/* <p onClick={() => {navigatePath('/login'),handleAddEvent("")}} className={location.pathname === '/contactus' ? "SidebarTitleActive" : "SidebarTitle"}>Contact Us</p> */}
                                                </div>
                                                <hr className="my-4" style={{ height: "0.1px" }} />
                                                <div>
                                                    <p className={register ? "SidebarTitleActive" : "SidebarTitle"} onClick={() => handleRegister()}>
                                                        Register {register ? <IoIosArrowUp className="ml-3" /> : <IoIosArrowDown className="ml-3" />}
                                                    </p>
                                                    {register ? (
                                                        <>
                                                            <p onClick={() => {navigatePath("/employeeSignup"),handleAddEvent("Job Seeker")}} className={location.pathname === "/employeeSignup" ? "SidebarTitleSubActive" : "SidebarTitleSub"}>
                                                                Job Seeker
                                                            </p>
                                                            <p onClick={() => {navigatePath("/studentSignup"),handleAddEvent("Employer")}} className={location.pathname === "/studentSignup" ? "SidebarTitleSubActive" : "SidebarTitleSub"}>
                                                                Employer
                                                            </p>
                                                        </>
                                                    ) : (
                                                        <></>
                                                    )}
                                                    <p onClick={() => {navigatePath("/login"),handleAddEvent("Login")}} className={location.pathname === "/login" ? "SidebarTitleActive" : "SidebarTitle"}>
                                                        Login
                                                    </p>
                                                </div>
                                            </>
                                        )}
                                    </>
                                )}
                            </div>
                        </Drawer>
                    </div>
                </>
            )}
        </>
    );
}

export default Header2;
