import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { logoutUser } from "../../actions/userActions";
import { useNavigate } from "react-router-dom";
import "./css/Dropdown.scss";
import { logEvent } from "../../utils/Analytics";
const Dropdown = ({ user }) => {
    const [isHovered, setIsHovered] = useState(false);
    const [secondToggle, setSecondToggle] = useState(false);
    const [thirdToggle, setThirdToggle] = useState(false);
    const [fourthToggle, setFourthToggle] = useState(false);
    const displaySecondDropdown = () => {
        setSecondToggle(!secondToggle);
    };

    const displayThirdDropdown = () => {
        setThirdToggle(!thirdToggle);
    };
    const displayFourthDropdown = () => {
        setFourthToggle(!fourthToggle);
    };

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const Logout = () => {
        dispatch(logoutUser());
        // window.location.href = "http://localhost:3000/"
        navigate("/");
        window.location.reload();
    };

    const handleAddEvent = (str) => {
        logEvent("Button", `Clicked ${str} button`);
    };

    return (
        <div
            className="dropdown"
            style={{
                display: "flex",
                alignItems: "center",
                position: "absolute",
                paddingTop: "28px",
                paddingBottom: "28px",
                paddingLeft: "20px",
                paddingRight: "20px",
                top: "-20px",
                right: "-40px",
                background: `${isHovered && user.role === "User" ? "#c8eeff" : "transparent"}`,
                fontSize: "14px",
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <div
                style={{
                    textAlign: "center",
                    color: `${isHovered ? "#008BDC" : "#666"}`,
                    border: `1.25px solid ${isHovered ? "#008BDC" : "#666"}`,
                    borderRadius: "50%",
                    height: "24px",
                    width: "24px",
                    fontSize: "12px",
                    display: "inline-block",
                    paddingTop: "3px",
                    marginTop: "-0.5px",
                    marginRight: "5px",
                }}
            >
                {user.name.slice(0, 1)}
            </div>

            {isHovered ? (
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#008BDC" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                    <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                </svg>
            ) : (
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#666" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                </svg>
            )}

            {isHovered && (
                <div
                    style={{
                        position: "absolute",
                        top: "100%",
                        right: "0",
                        zIndex: 1,
                        backgroundColor: "#fff",
                        // paddingRight: '10px',
                        paddingTop: "20px",
                        paddingBottom: "20px",
                        boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
                        borderRadius: "1px",
                        minWidth: "210px",
                    }}
                >
                    {/* Dropdown content goes here */}
                    <div>
                        <h1
                            style={{
                                fontWeight: "600",
                                fontSize: "14px",
                                paddingLeft: "20px",
                            }}
                        >
                            {user.name}
                        </h1>
                        <p
                            style={{
                                color: "#666",
                                fontSize: "14px",
                                fontWeight: "400",
                                paddingLeft: "20px",
                                paddingRight: "10px",
                            }}
                        >
                            {user.email}
                        </p>
                    </div>

                    <div
                        style={{
                            height: "1px",
                            width: "100%",
                            backgroundColor: "#f2f2f2",
                        }}
                    />

                    {user.role === "User" ? (
                        <ul>
                            <li>
                                <a style={{ marginTop: "10px", marginBottom: "10px" }} onClick={() => handleAddEvent("My Applications")} href="/applications">
                                    My Applications
                                </a>
                            </li>

                            {/* <li>
                <a style={{ marginTop: "10px", marginBottom: "10px" }} href="/editProfileUser">
                  Edit Profile
                </a>
              </li> */}

                            <li>
                                <a style={{ marginTop: "10px", marginBottom: "10px" }} onClick={() => handleAddEvent("Edit Resume")} href="/addresume">
                                    Edit Resume
                                </a>
                            </li>

                            <li>
                                <a style={{ marginTop: "10px", marginBottom: "10px" }} onClick={() => handleAddEvent("Saved Jobs")} href="/StudentSavedjob">
                                    Saved Jobs
                                </a>
                            </li>

                            {/* <li>
                <a style={{ marginTop: "10px", marginBottom: "10px" }} href="/contactus">
                  Contact Us
                </a>
              </li> */}
                        </ul>
                    ) : (
                        <ul>
                            <li>
                                <a style={{ marginTop: "10px", marginBottom: "10px" }} onClick={() => handleAddEvent("Help & Support")} href="/support">
                                    Help & Support
                                </a>
                            </li>
                            <li>
                                <a style={{ marginTop: "10px", marginBottom: "10px" }} onClick={() => handleAddEvent("Personal Details")} href="/myProfile">
                                    Personal Details
                                </a>
                            </li>
                            <li>
                                <a style={{ marginTop: "10px", marginBottom: "10px" }} onClick={() => handleAddEvent("Organization Details")} href="/myProfile/company">
                                    Organization Details
                                </a>
                            </li>
                            {/* <li>
                <a style={{ marginTop: "10px", marginBottom: "10px" }} href="/employerbuyconnects">
                  Buy Connects
                </a>
              </li> */}
                        </ul>
                    )}
                    {user.role === "Employer" && (
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                marginTop: "10px",
                                cursor: "pointer",
                                fontSize: "14px",
                                paddingLeft: "20px",
                                paddingRight: "10px",
                            }}
                            className="hover-item"
                            onClick={displayFourthDropdown}
                        >
                            {user.role === "Employer" && "Connects"}
                            {user.role === "Employer" && (
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708" />
                                </svg>
                            )}
                        </div>
                    )}

                    {user.role === "Employer" && fourthToggle ? (
                        <div
                            style={{
                                marginTop: "10px",
                                cursor: "pointer",
                                fontSize: "14px",
                                paddingLeft: "20px",
                            }}
                            onClick={displayFourthDropdown}
                        >
                            {fourthToggle && (
                                <ul
                                    style={{
                                        marginTop: "10px",
                                        cursor: "pointer",
                                    }}
                                >
                                    <li>
                                        <a style={{ marginTop: "10px", marginBottom: "10px" }} onClick={() => handleAddEvent("Buy Connects")} href="/employerbuyconnects">
                                            Buy Connects
                                        </a>
                                    </li>

                                    <li>
                                        <a style={{ marginTop: "10px", marginBottom: "10px" }} onClick={() => handleAddEvent("Connects Summary")} href="/employerconnectsinformation">
                                            Connects Summary
                                        </a>
                                    </li>
                                </ul>
                            )}
                        </div>
                    ) : null}

                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            marginTop: "10px",
                            cursor: "pointer",
                            fontSize: "14px",
                            paddingLeft: "20px",
                            paddingRight: "10px",
                        }}
                        className="hover-item"
                        onClick={displaySecondDropdown}
                    >
                        {/* {user.role === "User" ? "More" : "Manage Account"} */}
                        {user.role === "User" ? "Manage Account" : "Manage Account"}

                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708" />
                        </svg>
                    </div>

                    {/* Second dropdown content goes here */}

                    {/* {secondToggle && (
            <div
              style={{
                marginTop: '10px',
                cursor: 'pointer',
                fontSize: '14px',
                paddingLeft: '20px',
              }}
              onClick={displayThirdDropdown}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <div
                  className="hover-item"
                  style={{
                    fontSize: '14px',
                    marginLeft: '10px',
                  }}
                >
                  Manage Account
                </div>

                {thirdToggle ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-chevron-up"
                    viewBox="0 0 16 16"
                    style={{ marginRight: '10px' }}
                  >
                    <path
                      fill-rule="evenodd"
                      d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708z"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-chevron-down"
                    viewBox="0 0 16 16"
                    style={{ marginRight: '10px' }}
                  >
                    <path
                      fill-rule="evenodd"
                      d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"
                    />
                  </svg>
                )}
              </div>

              {thirdToggle && (
                <ul
                  style={{
                    marginTop: '10px',
                    cursor: 'pointer',
                  }}
                >
                  <li>
                    <a
                      style={{ marginTop: '10px', marginBottom: '10px' }}
                      href="/addresume"
                    >
                      Your Resume
                    </a>
                  </li>

                  <li>
                    <a
                      style={{ marginTop: '10px', marginBottom: '10px' }}
                      href="/myProfile"
                    >
                      Edit Profile
                    </a>
                  </li>

                  <li>
                    <a
                      style={{ marginTop: '10px', marginBottom: '10px' }}
                      href="/change-email"
                    >
                      Change Email
                    </a>
                  </li>

                  <li>
                    <a
                      style={{ marginTop: '10px', marginBottom: '10px' }}
                      href="/change-password"
                    >
                      Change Password
                    </a>
                  </li>
                </ul>
              )}
            </div>
          )} */}

                    {user.role === "User"
                        ? secondToggle && (
                              <ul
                                  style={{
                                      marginTop: "10px",
                                      cursor: "pointer",
                                      marginLeft: "15px",
                                  }}
                              >
                                  <li>
                                      <a style={{ marginTop: "10px", marginBottom: "10px" }} onClick={() => handleAddEvent("Change Email")} href="/change-email">
                                          Change Email
                                      </a>
                                  </li>

                                  <li>
                                      <a style={{ marginTop: "10px", marginBottom: "10px" }} onClick={() => handleAddEvent("Change Password")} href="/change-password">
                                          Change Password
                                      </a>
                                  </li>
                              </ul>
                          )
                        : secondToggle && (
                              <ul
                                  style={{
                                      marginTop: "10px",
                                      cursor: "pointer",
                                      marginLeft: "15px",
                                  }}
                              >
                                  <li>
                                      <a style={{ marginTop: "10px", marginBottom: "10px" }} onClick={() => handleAddEvent("Edit Profile")} href="/myProfile">
                                          Edit Profile
                                      </a>
                                  </li>

                                  <li>
                                      <a style={{ marginTop: "10px", marginBottom: "10px" }} onClick={() => handleAddEvent("Change Password")} href="/change-password">
                                          Change Password
                                      </a>
                                  </li>
                              </ul>
                          )}

                    {/* {user.role === "User"
            ? secondToggle && (
                <div
                  style={{
                    marginTop: "10px",
                    cursor: "pointer",
                    fontSize: "14px",
                    paddingLeft: "20px",
                  }}
                  onClick={displayThirdDropdown}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      className="hover-item"
                      style={{
                        fontSize: "14px",
                        marginLeft: "10px",
                      }}
                    >
                      Manage Account
                    </div>

                    {thirdToggle ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-chevron-up"
                        viewBox="0 0 16 16"
                        style={{ marginRight: "10px" }}
                      >
                        <path
                          fillRule="evenodd"
                          d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708z"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-chevron-down"
                        viewBox="0 0 16 16"
                        style={{ marginRight: "10px" }}
                      >
                        <path
                          fillRule="evenodd"
                          d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"
                        />
                      </svg>
                    )}
                  </div>

                  {thirdToggle && (
                    <ul
                      style={{
                        marginTop: "10px",
                        cursor: "pointer",
                      }}
                    >
                      <li>
                        <a style={{ marginTop: "10px", marginBottom: "10px" }} href="/change-email">
                          Change Email
                        </a>
                      </li>

                      <li>
                        <a style={{ marginTop: "10px", marginBottom: "10px" }} href="/change-password">
                          Change Password
                        </a>
                      </li>
                    </ul>
                  )}
                </div>
              )
            : secondToggle && (
                <ul
                  style={{
                    marginTop: "10px",
                    cursor: "pointer",
                    marginLeft: "15px",
                  }}
                >
                  <li>
                    <a style={{ marginTop: "10px", marginBottom: "10px" }} href="/myProfile">
                      Edit Profile
                    </a>
                  </li>

                  <li>
                    <a style={{ marginTop: "10px", marginBottom: "10px" }} href="/change-password">
                      Change Password
                    </a>
                  </li>
                </ul>
              )} */}

                    <div
                        style={{
                            fontSize: "14px",
                            marginTop: "10px",
                            marginLeft: "20px",
                            cursor: "pointer",
                        }}
                        onClick={Logout}
                        className="hover-item"
                    >
                        Logout
                    </div>
                </div>
            )}
        </div>
    );
};

export default Dropdown;
