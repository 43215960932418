import React, { useState, useEffect } from 'react';
import BlackCoffer from '../../assets/images/icons/blackcoffer-removebg-preview 1.png';
import './css/Footer.scss';
import Heart from '../../assets/images/icons/Hirekingdome/heart-removebg-preview (1) 1.png';
import Search from '../../assets/images/icons/Hirekingdome/search-removebg-preview (2) 1.png';
import Thumb from '../../assets/images/icons/Hirekingdome/thumb-removebg-preview 1.png';
import View from '../../assets/images/icons/Hirekingdome/view-removebg-preview 1.png';
import { toast } from 'react-hot-toast';
import axios from 'axios';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import AdminSignup from '../Admin/AdminSignup';
function Footer() {
  const pathname = useLocation();

  // if (pathname.pathname.includes("/employerappreceived")) {
  //   return <></>;
  // }
  const { user } = useSelector((state) => state.user);
  const userType = localStorage.getItem("userType");

  const handleSavedJobClick = () => {
    if (userType && userType == 'User') {
      navigate('/studentSavedJob');
    } else {
      navigate('/login');
    }
  };


  
 

  const handlemyFeedClick = async() => {
    if (userType && userType == 'User') {
      if(user && user.resume){
        const { data } = await axios.get("/api/user/getResume", {
          headers: {
            "Content-Type": "application/json",
            token: localStorage.getItem("token"),
          },
        });
        localStorage.setItem("title", data.resume.PersonalDetails.profile);
        navigate('/matchedJobs');
      }
      else{
        alert("Resume not Created");
        navigate('/addResume');
      }
      
    } else {
     navigate('/login');
    }
  };

  // Internship Location Wise

  const internshipLocationWise = async (loc) => {
      localStorage.setItem("location", loc);
  };

  const remoteInternship = async () => {
      localStorage.setItem("location", 'Remote'); 
  };



  // Job Location Wise

  const jobLocationWise = async (loc01) => {
    localStorage.setItem("location", loc01);
  }

  const remoteJob = async () => {
    localStorage.setItem("location", 'Remote');
  };


  

  // Internship Category Wise

  const internshipCategoryWise = async (cat) => {
    localStorage.setItem("title", cat);
  };

  // Job Category Wise

  const jobCategoryWise = async (cat01) => {
    localStorage.setItem("title", cat01);
  };

  const navigate = useNavigate();
  const location = useLocation();


  /******************************Filter Option End**********************************/





  return (
    <>
      {!pathname.pathname.includes('employerappreceived') ? (
        <div className="app-footer" style={{ maxWidth: '100vw', backgroundColor: 'white' }}>
          <div
            className="footer container"
            style={{
              display: 'grid',
              overflow: 'hidden',
              maxWidth: '1224px',
              margin: 'auto',
              padding: '16px',
            }}
          >
            <div
              className="row"
              style={{
                maxWidth: '1224px',
                gap: '48px',
                paddingTop: "40px",
              }}
            >
              <div className="col-2 footerTab mx-auto">
                <h4 className="mx-auto mb-3 ">Internship Listings by Location</h4>
                <ul className="list-unstyled links" >
                  <li className="mx-auto my-2">
                    <a
                    href="/matchedInternships/location_Delhi" 
                    >
                      Internships in Delhi
                    </a>
                  </li>

                  <li className="mx-auto my-2">
                    <a
                      href="/matchedInternships/location_Bangalore" 
                    >
                      Internships in Bangalore
                    </a>
                  </li>
                  <li className="mx-auto my-2">
                    <a
                      href="/matchedInternships/location_Hyderabad" 
                    >
                      Internships in Hyderabad
                    </a>
                  </li>
                  <li className="mx-auto my-2">
                    <a
                    href="/matchedInternships/location_Pune" 
                    >
                      Internships in Pune
                    </a>
                  </li>
                  <li className="mx-auto my-2">
                    <a
                   href="/matchedInternships/location_Chennai" 
                    >
                      Internships in Chennai
                    </a>
                  </li>
                  <li className="mx-auto my-2">
                    <a
                    href="/matchedInternships/location_Mumbai" 
                    >
                      Internships in Mumbai
                    </a>
                  </li>
                  <li className="mx-auto my-2">
                    <a
                      href="/matchedInternships/location_Kolkata" 
                    >
                      Internships in Kolkata
                    </a>
                  </li>
                  <li className="mx-auto my-2">
                    <a
                      href="/matchedInternships/location_Ahmedabad" 
                    >
                      Internships in Ahmedabad
                    </a>
                  </li>
                  <li className="mx-auto my-2">
                    <a
                     href="/matchedInternships/location_Kochi" 
                    >
                      Internships in Kochi
                    </a>
                  </li>
                  <li className="mx-auto my-2">
                    <a href="/matchedInternships/workFromHome">
                    Remote-based Internships
                    </a>
                  </li>
                  <li className="mx-auto my-2">
                    <a href="/matchedInternships/all">
                      View all Internships
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-2 footerTab mx-auto">
                <h4 className="mx-auto mb-3">Internship Listings by Categories</h4>
                <ul className="list-unstyled links">
                  <li className="mx-auto my-2">
                    <a
                     href="/matchedInternships/profile_Computer#Science"  
                    >
                      Computer Science Internships
                    </a>
                  </li>
                  <li className="mx-auto my-2">
                    <a
                         href="/matchedInternships/profile_Electronics" 
                    >
                      Electronics Internships
                    </a>
                  </li>
                  <li className="mx-auto my-2">
                    <a
                       href="/matchedInternships/profile_Mechanical" 
                    >
                      Mechanical Internships
                    </a>
                  </li>
                  <li className="mx-auto my-2">
                    <a href="/matchedInternships/profile_Civil">
                      Civil Internships
                    </a>
                  </li>
                  <li className="mx-auto my-2">
                    <a
                     href="/matchedInternships/profile_Marketing" 
                    >
                      Marketing Internships
                    </a>
                  </li>
                  <li className="mx-auto my-2">
                    <a
                     href="/matchedInternships/profile_Chemical"
                    >
                      Chemical Internships
                    </a>
                  </li>
                  <li className="mx-auto my-2">
                    <a
                      href="/matchedInternships/profile_Finance"
                    >
                      Finance Internships
                    </a>
                  </li>
                  <li className="mx-auto my-2">
                    <a
                      href="/matchedInternships/profile_Content#Writing"
                    >
                      Content Writing Internships
                    </a>
                  </li>
                  <li className="mx-auto my-2">
                    <a
                    href="/matchedInternships/profile_Web#Developers" 
                    >
                      Web Developer Internships
                    </a>
                  </li>
                  <li className="mx-auto my-2">
                    <a
                         href="/matchedInternships/profile_Sales" 
                    >
                      Sales Internships
                    </a>
                  </li>
                  <li className="mx-auto my-2">
                    <a href="/matchedInternships/all" >
                      View all Internships
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-2 footerTab mx-auto">
                <h4 className="mx-auto mb-3">Job Listings by Location</h4>
                <ul className="list-unstyled links">
                  <li className="mx-auto my-2">
                    <a href="/matchedJobs/location_Delhi">
                      Jobs in Delhi
                    </a>
                  </li>
                  <li className="mx-auto my-2">
                   <a href="/matchedJobs/location_Bangalore">
                      Jobs in Bangalore
                    </a>
                  </li>
                  <li className="mx-auto my-2">
                    <a href="/matchedJobs/location_Mumbai">
                      Jobs in Mumbai
                    </a>
                  </li>
                  <li className="mx-auto my-2">
                    <a href="/matchedJobs/location_Jaipur">
                      Jobs in Jaipur
                    </a>
                  </li>
                  <li className="mx-auto my-2">
                    <a href="/matchedJobs/location_Chennai">
                      Jobs in Chennai
                    </a>
                  </li>
                  <li className="mx-auto my-2">
                    <a href="/matchedJobs/location_Hyderabad">
                      Jobs in Hyderabad
                    </a>
                  </li>
                  <li className="mx-auto my-2">
                    <a href="/matchedJobs/location_Kolkata">
                      Jobs in Kolkata
                    </a>
                  </li>
                  <li className="mx-auto my-2">
                    <a href="/matchedJobs/location_Pune">
                      Jobs in Pune
                    </a>
                  </li>
                  <li className="mx-auto my-2">
                    <a href="/matchedJobs/location_Lucknow">
                      Jobs in Lucknow
                    </a>
                  </li>
                  <li className="mx-auto my-2">
                  <a href="/matchedJobs/location_Kochi">
                      Jobs in Kochi
                    </a>
                  </li>
                  <li className="mx-auto my-2">
                  <a href="/matchedJobs/location_Ahmedabad">
                      Jobs in Ahmedabad
                    </a>
                  </li>
                  <li className="mx-auto my-2">
                    <a href="/matchedJobs/workFromHome">
                    Remote-based Jobs
                    </a>
                  </li>
                  <li className="mx-auto my-2">
                    <a href="/matchedJobs/all" >
                      View all Jobs
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-2 footerTab mx-auto ">
                <h4 className="mx-auto mb-3">Job Listings by Categories</h4>
                <ul className="list-unstyled links">
                  <li className="mx-auto my-2">
                    <a
                      href="/matchedJobs/profile_Computer#Science"
                    >
                      Computer Science Jobs
                    </a>
                  </li>
                  <li className="mx-auto my-2">
                    <a href="/matchedJobs/profile_Electronics">
                      Electronics Jobs
                    </a>
                  </li>
                  <li className="mx-auto my-2">
                  <a href="/matchedJobs/profile_Mechanical">
                      Mechanical Jobs
                    </a>
                  </li>
                  <li className="mx-auto my-2">
                   <a href="/matchedJobs/profile_Civil">
                      Civil Jobs
                    </a>
                  </li>
                  <li className="mx-auto my-2">
                   <a href="/matchedJobs/profile_Marketing">
                      Marketing Jobs
                    </a>
                  </li>
                  <li className="mx-auto my-2">
                   <a href="/matchedJobs/profile_Chemical">
                      Chemical Jobs
                    </a>
                  </li>
                  <li className="mx-auto my-2">
                   <a href="/matchedJobs/profile_Finance">
                      Finance Jobs
                    </a>
                  </li>
                  <li className="mx-auto my-2">
                    <a href="/matchedJobs/profile_Content#Writing"
                    >
                      Content Writing Jobs
                    </a>
                  </li>
                  <li className="mx-auto my-2">
                   <a href="/matchedJobs/profile_Web#Developers">
                      Web Developer Jobs
                    </a>
                  </li>
                  <li className="mx-auto my-2">
                    <a href="/matchedJobs/profile_Sales">
                      Sales Jobs
                    </a>
                  </li>
                  <li className="mx-auto my-2">
                    <a href="/matchedJobs/all" >
                      View all Jobs
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-2 footerTab mx-auto">
                <h4 className="mx-auto mb-3">Company</h4>
                <ul className="list-unstyled links">
                <li className="mx-auto my-2">
                    <a href="/about-us">About Us</a>
                  </li>
                {/*  <li className="mx-auto my-2">
                    <a href="#">Blogs</a>
                  </li>*/}
                  {/* <li className="mx-auto my-2">
                    <a href="#">Now Recruiting</a>
                  </li> */}
                  {user?(<>
                    {userType == "Employer"?(<>
                  <li className="mx-auto my-2">
                  <a href="/support">Contact us</a>
                  </li>
                    </>):(<>
                  <li className="mx-auto my-2">
                  <a  href="/contactus">Contact us</a>
                  </li>
                    </>)}
                  </>):(<> 
                  <li className="mx-auto my-2">
                  <a href="/support">Contact us</a>
                  </li>
                  </>)}
                  {/*<li className="mx-auto my-2">
                    <a href="#">Our Services</a>
                </li>*/}
                </ul>
                <h4 className="mx-auto mt-5 mb-3">Terms & Conditions</h4>
                <ul className="list-unstyled links">
                <li className="mx-auto my-2">
                    <a href="/term-condition">Terms & Conditions</a>
                  </li>
                  <li className="mx-auto my-2">
                    <a href="/privacy">Privacy</a>
                  </li>
                  <li className="mx-auto my-2">
                    <a href="/GDPR">GDPR</a>
                  </li>
                 
                 
                  {/*<li className="mx-auto my-2">
                    <a href="/matchedJobs">
                      View all Jobs
                    </a>
                  </li>
                  <li className="mx-auto my-2">
                    <a href="/matchedInternships">
                      View all Intenships
                    </a>
                  </li>*/}
                </ul>
              </div>
            </div>
            <hr style={{height: '0.1px'}} />
           
           <div className="row">
           <div className="col-lg-4 col-md-4 col-sm-12 mt-1">
           <p className=" font-weight-bold" style={{marginTop: "20px", marginBottom: "-2px"}}>
                  @ Copyright 2025 hirekingdom
                </p>
           {/*<div className="company">
              <strong>
                <i>Powered by</i>
              </strong>
              <br></br>
             
              <img
                className="mt-3"
                src={BlackCoffer}
                alt="BlackCoffer"
                style={{ height: '45px' }}
              />
        
            </div>*/}
            </div>
          {/*    <div className="col-lg-4 col-md-4 col-sm-12 socialicon  mb-3">
                <ul
                  className="list-unstyled links"
                  style={{ display: 'inline-flex' }}
                >
                  <li className="mx-2 my-2">
                    <a href="#">
                      <i className="fa fa-instagram"></i>
                    </a>
                  </li>
                  <li className="mx-2 my-2">
                    <a href="#">
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li className="mx-2 my-2">
                    <a href="#">
                      <i className="fa fa-linkedin"></i>
                    </a>
                  </li>
                </ul>
              </div>*/}
              <div className="col-lg-4 col-md-4 col-sm-12 mt-2 mb-3"></div>
              <div className="col-lg-4 col-md-4 col-sm-12 mx-auto  mt-2 mb-3">
                
              </div>
            </div>
          </div>
        
      {/*user && userType == "Employer"? (<></>):(<>
          <div className="main-footer">
          <div className="item"
            onClick={() => navigate('/matchedJobs')}
            style={{ cursor: 'pointer' }}
            >
              <img src={View} alt="heart" style={{ width: '3.5rem' }} />
              <span>View Jobs</span>
            </div>
            <div className="item"
            onClick={() => navigate('/matchedInternships')}
            style={{ cursor: 'pointer' }}
            >
              <img src={View} alt="heart" style={{ width: '3.5rem' }} />
              <span>View Internships</span>
            </div>
          
            <div className="item"
            style={{ cursor: 'pointer'}}
            onClick={handlemyFeedClick}
            >
              <img src={Thumb} alt="heart"
              
              />
              <span>My Feed</span>
            </div>
            
            <div
            
              className="item"
              onClick={handleSavedJobClick}
              style={{ cursor: 'pointer' }}
            >
              <img src={Heart} alt="heart" />
              <span>Saved Job</span>
            </div>
          </div>
      </>)*/}
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default Footer;
