import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { logoutUser } from "../../actions/userActions";
import { useNavigate } from "react-router-dom";
import "./css/Dropdown.scss";
import { Link } from "react-router-dom";
import { logEvent } from "../../utils/Analytics";
const DropdownAdmin = ({ user }) => {
    const [isHovered, setIsHovered] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const Logout = () => {
        dispatch(logoutUser());
        navigate("/admin_login");
    };
    
    const handleAddEvent = (str) => {
      logEvent("Button", `Clicked ${str} button`);
    };
    

    return (
        <div
            className="dropdown"
            style={{
                display: "flex",
                alignItems: "center",
                position: "absolute",
                paddingTop: "25px",
                paddingBottom: "25px",
                paddingLeft: "20px",
                paddingRight: "20px",
                top: "-20px",
                right: "-40px",
                background: `${isHovered && user.role === "Admin" ? "#c8eeff" : "transparent"}`,
                fontSize: "14px",
                cursor: "pointer",
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <div
                style={{
                    textAlign: "center",
                    color: `${isHovered ? "#008BDC" : "#666"}`,
                    border: `1.25px solid ${isHovered ? "#008BDC" : "#666"}`,
                    borderRadius: "50%",
                    height: "24px",
                    width: "24px",
                    fontSize: "12px",
                    display: "inline-block",
                    paddingTop: "3px",
                    marginTop: "-0.5px",
                    marginRight: "5px",
                }}
            >
                {user.name.slice(0, 1)}
            </div>

            {isHovered ? (
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#008BDC" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                    <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                </svg>
            ) : (
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#666" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                </svg>
            )}

            {isHovered && (
                <div
                    style={{
                        position: "absolute",
                        top: "100%",
                        right: "0",
                        zIndex: 1,
                        backgroundColor: "#fff",
                        // paddingRight: '10px',
                        paddingTop: "20px",
                        paddingBottom: "20px",
                        boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
                        borderRadius: "1px",
                        minWidth: "210px",
                    }}
                >
                    {/* Dropdown content goes here */}
                    <div>
                        <h1
                            style={{
                                fontWeight: "600",
                                fontSize: "14px",
                                paddingLeft: "20px",
                            }}
                        >
                            {user.name}
                        </h1>
                        <p
                            style={{
                                color: "#666",
                                fontSize: "14px",
                                fontWeight: "400",
                                paddingLeft: "20px",
                                paddingRight: "10px",
                            }}
                        >
                            {user.email}
                        </p>
                    </div>

                    <div
                        style={{
                            height: "1px",
                            width: "100%",
                            backgroundColor: "#f2f2f2",
                        }}
                    />

                    <ul>
                        <li>
                            <Link to="/admin_change_password" onClick={() => handleAddEvent("Admin Change password")} style={{ marginTop: "10px", marginBottom: "10px" }}>
                                Change Password
                            </Link>
                        </li>
                    </ul>
                    <div
                        style={{
                            fontSize: "14px",
                            marginTop: "10px",
                            marginLeft: "20px",
                            cursor: "pointer",
                        }}
                        onClick={Logout}
                        className="hover-item"
                    >
                        Logout
                    </div>
                </div>
            )}
        </div>
    );
};

export default DropdownAdmin;
